//https://frontarm.com/james-k-nelson/passing-data-props-children/
//https://codesandbox.io/s/agitated-fast-skn58?file=/src/index.js
//https://material-ui.com/components/tree-view/
//https://github.com/jonmiles/react-bootstrap-treeview

import React, { Component  } from 'react';
import ReactDOM from 'react-dom';
import { Rnd } from "react-rnd";

export class Portal extends React.Component {
    constructor(props) {
      super(props);
      this.el = document.createElement("div");
    }
  
    componentDidMount = () => {
        document.getElementById(this.props.target).appendChild(this.el);
    };
  
    componentWillUnmount = () => {
        document.getElementById(this.props.target).removeChild(this.el);
    };
  
    render() {
      const { children } = this.props;
      return ReactDOM.createPortal(children, this.el);
    }
  }

class RialeModalPanel extends Component  {

    constructor(props)
    {   
        super(props);
      
        this.state = {width:this.props.initialWidth, 
                      height:this.props.initialHeight, 
                      x:this.props.initialX,  
                      y:this.props.initialY,
                     ready:false};
    }

    componentDidMount() {
        console.log("MODAL PANEL COMPONENT DID MOUNT");
        this.setState({ready:true});
    }

    render()
    {
        //console.log(`MODAL PANEL x:${this.state.x} y:${this.state.y} Win Width:${window.innerWidth}`);
        //console.log(`POSIX Container (MODAL PANEL) container Width:${this.state.width}  container Height:${this.state.height}`);
        //console.log(`POSIX Container (MODAL PANEL) initialWidth:${this.props.initialWidth} initialHeight:${this.props.initialHeight}`);
        
        const style = {
            //display: "flex",
            //alignItems: "center",
            //justifyContent: "center",
            border: (this.props.visible ? "solid 3px #007bff" : "solid 0px #007bff"),
            //marginTop: "10px",
            background: "#f0f0f0",
            zIndex:1000,
            left:0,
            top:0,
            position:"absolute"
          };
          
        const {visible, lockAspectRatio} = this.props;
        return(
            this.state.ready && ( 

        <Rnd    ref={c => { this.rnd = c; }} style={style}
                size={{ width: (visible ? this.state.width : 0),  
                    height: (visible ? this.state.height : 0) }}
                
                bounds={this.props.bounds}
                dragHandleClassName={'handle'}
                disableDragging = {false}
                lockAspectRatio = {lockAspectRatio}
                position={{ x: this.state.x, y: this.state.y }}
                onDragStop={(e, d) => {this.setState({ x: d.x, y: d.y }, 
                    () => this.props.onContainerRectChanged(this.state))
                                    }}

               
                //lockAspectRatioExtraHeight={50}
                onResize={(e, direction, ref, delta, position) => {
                    this.setState({
                    width: ref.offsetWidth,
                    height: ref.offsetHeight,
                    ...position,
                        }, () => this.props.onContainerRectChanged(this.state));
            }}>
      
             
                {/*
                <div ref={c => { this.containerDiv = c; }} onClick={()=>{console.log("Modal Panel container clicked")}}
                    className="handle"
                    style={{
                    backgroundColor: 'red',
                    color:'white',
                    padding: '1rem',
                    marginBottom:'1rem'
                    }}>
                    { this.props.title}
                    </div>
                */}
                 
           <div>
                {this.props.children(this.state.width,this.state.height, this.props.visible)}
                
            </div>
       </Rnd>
      
   ))
}
}


export default RialeModalPanel;