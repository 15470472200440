import { CardHeader, CardBody, Button, Input, Label, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, NavItem, TabContent, TabPane } from 'reactstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import ScrollArea from 'react-scrollbar';
import { TLAAction } from './TLAAction';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as DesignSelectors, actions as DesignActions } from '../store/slices/design'
import { v4 as uuidv4 } from 'uuid';

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: 0,
  // display: "flex",
  margin: 0,
  overflowY: "auto" as any,
  overflowX: "hidden" as any,
  height: 380
});

const getItemStyle = (isDragging: any, draggableStyle: any) => ({

  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 0,
  margin: 0,
  background: isDragging ? 'lightgreen' : 'grey',
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const TLAComponent = ({
  dragHandle,
  // tla,
  readOnly,
  tlaIndex
}: any) => {

  const dispatch = useDispatch();
  const [actionsCount, setActionsCount] = useState(2);
  const [currentTLAIndex, setCurrentTLAIndex] = useState(0);
  const [actionList, setActionList] = useState([]);

  const cardWidth = 400;
  const readOnlyGroup = readOnly ? { disabled: true } : { disabled: false }

  console.log('with dragHandle: ', dragHandle);

  const tla = useSelector(state => (state as any).design.current.tlas[tlaIndex])

  return (
    <Card className="tlaComponent" style={{ width: cardWidth }}>
      <CardBody style={{ padding: 0, height: 24, flex: 0 }}>
        <div className="bg-primary" style={{ height: 24, width: `${cardWidth - 34}px`, float: "left", paddingLeft: 10, fontSize: 12, color: "white" }} {...dragHandle}><b><i></i></b></div>
        <div className="bg-primary" style={{ height: 24, width: "32px", borderColor: "blue", float: "left", color: "white", textAlign: "center", fontSize: 14, borderWidth: 0, borderStyle: "solid", borderRight: 0 }} >
          {!readOnly &&
            <a style={{ color: 'white' }} onClick={(e) => {
              e.preventDefault();
              dispatch(DesignActions.deleteTLA({ tlaIndex: tlaIndex }));
            }} href="#">X</a>
          }
        </div>
      </CardBody>
      <CardBody style={{ marginTop: 24, paddingTop: 6, paddingLeft: 6, paddingRight: 6 }}>

        <FormGroup {...readOnlyGroup} tag="fieldset" style={{ marginBottom: 6 }}>
          <Input style={{ fontSize: 12 }} type="textarea" required name="title" id="title" placeholder="Titolo del TLA" value={tla.title} onChange={(e) => { 
            dispatch(DesignActions.setTLAMetadata({tlaIndex: tlaIndex, name: "title", value: e.target.value}));
          }} />
        </FormGroup>

        <Row>
          <Col>
            {/* {!readOnly && */}
            <Button disabled={readOnly} size="sm" onClick={(e) => {
              dispatch(DesignActions.addAction({tlaIndex: tlaIndex, value: {id: uuidv4()}}));
            }} color="secondary">Aggiungi azione</Button>
            {/* } */}
          </Col>
        </Row>

        <ScrollArea
          speed={0.8}
          className="area"
          contentClassName="content"
          contentStyle={{ width: cardWidth, backgroundColor: "white", minHeight: 380, height: actionsCount * (120 + 2 + 10) }}
          style={{ minHeight: 380, height: 380 }}
          horizontal={false}
        >

          <Droppable type="ACTION" droppableId={tla.id} ignoreContainerClipping={false}>
            {(provided: any, snapshot: any) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >

                {tla.actions != undefined && tla.actions.map((action: any, newIndex: any) => {
                  console.log(`with action: `, action, ' and index ', newIndex);
                  return (
                    <Draggable isDragDisabled={readOnly} type="ACTION" key={action.id} draggableId={action.id} index={newIndex}>
                      {(provided: any, snapshot: any) => (
                        <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}

                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <TLAAction
                              readOnly={readOnly}
                              width={cardWidth}
                              //move={(tlaName, dragIndex, hoverIndex) => this.moveAction(tlaName, dragIndex, hoverIndex)}
                              tla={tla}
                              action={action}
                              tlaIndex={tlaIndex}
                              index={newIndex}
                              dragHandle={provided.dragHandleProps}
                              parent={this}
                            />
                          </div>
                        </>
                      )}
                    </Draggable>

                  )
                })}
                {provided.placeholder}

              </div>
            )}

          </Droppable>
        </ScrollArea>
        <FormGroup {...readOnlyGroup} tag="fieldset">
          <Input type="textarea" name="notes" id="notes" placeholder="notes" value={tla.notes} onChange={(e) => {
            console.log('with value: ', e.target.value)
            dispatch(DesignActions.setTLAMetadata({tlaIndex: tlaIndex, name: "notes", value: e.target.value}));
          }} />
        </FormGroup>
      </CardBody>

    </Card>
  )
}