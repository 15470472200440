import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Container, Row,Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { SideBar } from '../components/SideBar'
import { Content } from '../components/Content';
import {ExperimentDetails } from '../components/ExperimentDetails'
import { selectors as CatalogSelectors, actions as CatalogActions } from '../store/slices/catalog'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AppointmentsViewer from '../components/appointmentsViewer'
import AppointmentEditor from '../components/appointmentsEditor'
import { Header } from '../header'
import { selectors as AuthSelectors } from '../store/slices/auth'
export const DetailExperiment = () => {

    let { experiment }: any = useParams();
    const dispatch = useDispatch();
    const currentExperiment = useSelector(CatalogSelectors.getCurrentExperiment);
    const isLogged = useSelector(AuthSelectors.isLogged)

    console.log("Current experiment:", currentExperiment);
    React.useEffect(() => {
        console.log("Carico l'esperimento con id:", experiment);
        dispatch(CatalogActions.willGetExperiment(experiment))
        
        return () => { }
      }, []);

    /*
    React.useEffect(()=>{
      console.log("current experiment:", currentExperiment);
      dispatch(CatalogActions.willLoadCurrentSharedExperiments(currentExperiment));       
    }, [currentExperiment])
    */

    const sharedExperiments = (currentExperiment!=null && 
      currentExperiment.timeline_pubbliche!= null) ? 
      currentExperiment.timeline_pubbliche : [];
    
  //  [{"id":"1234", "descrizione" : "descrizione!"}] as any;

    const linkFormatter  = (cell:any,row:any) =>{
      return <a href={`/public/${row.id}`}>{row.descrizione}</a>
    }
    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        sort: true,
        hidden: true
      }
        ,
      {
        dataField: 'descrizione',
        text: 'Descrizione',
        formatter: linkFormatter,
        sort: true
      }
    ]

    const renderContainer = () =>
    {
      return (
            <Container fluid>
            <Row>
            <Col xs="12">
            {currentExperiment!=null  && <ExperimentDetails content={currentExperiment}/>}
          </Col>
            </Row>
          { sharedExperiments!=null && sharedExperiments.length>0 &&
          (<Row>
              <Col xs="12">
                <Card className="mb-4" style={{ borderColor: "#007bff" }}>
                <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                <CardTitle tag="h5" className="text-center">Timeline pubbliche</CardTitle>
                </CardHeader>
                  <CardBody>
                  <BootstrapTable bootstrap4 keyField='id' data={ 
                      sharedExperiments
                    } 
                    columns={ columns } />
                      </CardBody>
                      </Card>
              </Col>
          </Row>)
          }

            <Row>
        <Col xs="12">
          <Card className="mb-4" style={{ borderColor: "#007bff" }}>
            <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
              <CardTitle tag="h5" className="text-center">Calendario</CardTitle>
            </CardHeader>
            <CardBody>
              <AppointmentsViewer experimentId={experiment} />
      {  AuthSelectors.isLogged && <AppointmentEditor /> }
            </CardBody>
          </Card>
        </Col>
      </Row>  
      </Container>)
    }

    return( 
        <>
        <Header className="mb-3 text-white" section="Catalogo - Dettagli Esperimento" showMenu={false} />
        { isLogged && <SideBar active="catalog"/> }

        { isLogged ? 
              <Content className="pt-3"> 
                {renderContainer()}
              </Content>
               : 
         renderContainer()

        
        }</>
        )     
}