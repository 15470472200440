import {configuration} from "../config";
import { cloneDeep } from 'lodash'; 


const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"
//const catalogExperiments = configuration[stage].catalog["esperimenti"]

export const getCatalog = async () => {
  console.log("catalog_url:", configuration[stage].catalog);
  let cJson = await fetch(configuration[stage].catalog)  
  console.log("Json del catalog:", cJson);
  let catalogAnExperimentsJson = await cJson.json();
  // catalogo ed esperimenti (json completo)
  let catalogJson = catalogAnExperimentsJson["catalog"]
  //let catalogJson = configuration[stage].catalog["catalog"]
  console.log("catalogo caricato (API):", catalogJson);

  // dizionario degli esperimenti
  const catalogExperiments = catalogAnExperimentsJson["esperimenti"];
  console.log("catalogexperiments:", catalogExperiments)
  let experimentTopics = {} as any;
  // catalogo con le liste degli esperimenti da riempire (id->obj)
  let filledCatalog = []
 
  for (let i=0; i<catalogJson.length;i++)
    {
      filledCatalog.push(cloneDeep(catalogJson[i]))
      for (let j=0;j< catalogJson[i]["esperimenti"].length;j++)
      { 
       
       // se ho un percorso....
        if (typeof(catalogJson[i]["esperimenti"][j])=="object")
        { console.log("Trovato percorso di esperimenti:", catalogJson[i]["esperimenti"][j]);
        let pathExperiments = [] as any;
        let filledExperimentsPath =cloneDeep(catalogJson[i]["esperimenti"][j]);
        const pathExperimentsId = catalogJson[i]["esperimenti"][j]["esperimenti"];
        for(let p=0;p<pathExperimentsId.length;p++)
        {
          // Aggiungo il topic corrente all'esperimento
          if (experimentTopics[pathExperimentsId[p]]==null)
          {
            experimentTopics[pathExperimentsId[p]] = [catalogJson[i]["titolo"]]
          }
          else
          {
            experimentTopics[pathExperimentsId[p]].push(catalogJson[i]["titolo"])
          }
          pathExperiments.push(catalogExperiments[pathExperimentsId[p]]);
        }
        filledExperimentsPath["esperimenti"] = pathExperiments;
        filledCatalog[i]["esperimenti"][j] = filledExperimentsPath;
        }
        // se ho un esperimento semplice
        else
        {
          filledCatalog[i]["esperimenti"][j] = catalogExperiments[catalogJson[i]["esperimenti"][j]];
          // Aggiungo il topic corrente all'esperimento
          if (experimentTopics[catalogJson[i]["esperimenti"][j]]==null)
          {
            experimentTopics[catalogJson[i]["esperimenti"][j]] = [catalogJson[i]["titolo"]]
          }
          else
          {
            experimentTopics[catalogJson[i]["esperimenti"][j]].push(catalogJson[i]["titolo"])
          }
       
       
        }
        
      }
    }
    console.log("Updated Catalog:", filledCatalog);
    console.log("Dizionario dei topics:", experimentTopics)

    // aggiungo i topic ai vari esperimenti
    const ids = Object.keys(experimentTopics);
    for (let i=0;i<ids.length;i++)
    {
      catalogExperiments[ids[i] as any]["topic"] = experimentTopics[ids[i]]
    }

    console.log("Dizionario degli esperimenti:", catalogExperiments)

    return {"catalog" : filledCatalog, "esperimenti" : catalogExperiments }
  }


/*
export const getExperiments = (organization: string) => {
  console.log("API: getExperiments cerco da JSON organization:", organization);
  const returnExperiments = []

  for (let i = 0; i < catalog.length; i++) {
    //Append topic to experiment
    for(const experiment of catalog[i]["esperimenti"]){
      if (experiment["type"]=="path")
      {
        const returnPathExperiments = []
        let returnPathExperiment =cloneDeep(experiment);
        console.log("Ho a che fare con un percorso di esperimenti:", experiment);
        for(const pathExperiment of experiment["esperimenti"])
        {
          const returnPathExperiment = cloneDeep(pathExperiment);
          (returnPathExperiment as any).topic = `${catalog[i].titolo}`; 
          returnPathExperiments.push(returnPathExperiment);
        }
    
        returnPathExperiment["esperimenti"] = returnPathExperiments;
        returnExperiments.push(returnPathExperiment);
      }
      else 
      {
        const return_experiment =  cloneDeep(experiment);
        (return_experiment as any).topic = `${catalog[i].titolo}`
        returnExperiments.push(return_experiment);
      }
     
    }

  }
  console.log("API: getExperiments list:", returnExperiments);
  return returnExperiments;
}
*/