import React, { Component } from 'react';
import moment from 'moment';
import 'moment-duration-format';

import { IconContext } from "react-icons";
import { FaPlay, FaPause, FaEdit, FaRegClock, FaListOl, FaSave } from 'react-icons/fa';
import {FiZoomIn, FiZoomOut, FiVideo, FiVideoOff } from "react-icons/fi";
import {GoScreenNormal} from "react-icons/go";
import {Badge} from 'reactstrap';
import { MdAttachment } from "react-icons/md";

//import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DateTime from 'react-datetime';
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm:ss";

class TimelinePlayerNT extends Component {


    constructor(props) {
        super(props);
        this.state = {isInvalidDate : false, originalDate: this.props.currentPositionDate};
    }

    componentDidUpdate(prevProps,prevState)
    {
        if (this.props.isPlaying!=prevProps.isPlaying)
        {
            ReactTooltip.rebuild();
        }
    }

    toggleTimeCursor = () =>
    {
        this.props.onToggleTimeCursor();
    }

    toggleAttachments = () =>
    {
        this.props.onToggleAttachments();
    }

    toggleItemsNavigator = () =>
    {
        this.props.onToggleItemsNavigator();
    }

    togglePlay = () => {
     
         if (this.props.isPlaying)
         this.props.onPause();
         else
         this.props.onPlay();
    }


    handleDateChanged = (currentDate) =>
    {   
        console.log(`NEW DATE:${currentDate}`);
        // se il valore corrente della data non rappresenta una data valida,
        // NON inoltro il nuovo valore alla timeline
        if(!moment(currentDate, DATE_TIME_FORMAT).isValid())
        {  
            this.setState({isInvalidDate:true});
            console.log("Inserita una data non valida");
            return;
        }
      else   
      {
        this.props.onDateChanged(moment(currentDate,"DD/MM/YYYY hh:mm:ss"));
        this.setState({isInvalidDate:false, originalDate: moment(currentDate,DATE_TIME_FORMAT)});
      } 
    }

    /*
    handleBlur = (currentDate) => {
        const {isInvalidDate, originalDate} = this.state;
        console.log(`Focus rilasciato dal calendar della timeline su ${currentDate} Invalid? ${isInvalidDate}`);
        console.log(`Data valida? ${moment(currentDate, DATE_FORMAT).isValid()} `);
        if (!moment(currentDate, DATE_FORMAT).isValid())
        {  
            console.log(`Ripristino la data ${this.props.currentPositionDate}`);
            //this.props.onDateChanged(moment(this.props.currentPositionDate));
            this.handleDateChanged(this.props.currentPositionDate)
        }
    }
    */

    isDayIncluded = (currentDate) =>
        {
            const {items} = this.props;
            for (let index in items)
            {
                //console.log(`current:${moment(currentDate)}`);
                //console.log(`start:${items[index].start_time.startOf("day")}`);
                //console.log(`end:${ items[index].end_time}`);
                //console.log(" ");
               // aggiungo un secondo fittizio alla data del giorno del calendario per intercettare le eventuali intersezioni con gli items
               if (moment(currentDate).startOf('day').add(1,'seconds').isBetween(
                   moment(items[index].start_time).startOf("day"), moment(items[index].end_time)))
               {
                //console.log(`Trovato item nel giorno: ${currentDate}`);
                return true;
               }
               
            }
            //console.log(`Non hp trovato alcun item nel giorno: ${currentDate}`);
            return false;
        }
   
    renderDay = ( props, currentDate, selectedDate ) =>
        {
            if (this.isDayIncluded(currentDate))
                return <td {...props}> <b>{ currentDate.date() }</b></td>;
            else
                return <td {...props}> { currentDate.date() } </td>;
        }
        
    renderMonth ( props, month, year, selectedDate){
          return <td {...props}>{ month }</td>;
        }

        renderYear( props, year, selectedDate ){
          return <td {...props}>{ year % 100 }</td>;
        }
      
    getColoredButtonStyle(enabled)
    {
        const buttonBgColor = ( enabled ? "#007bff" : "red");
        return  { margin:"5px" , backgroundColor:`${buttonBgColor}`}
    }  

    render(){
        
        const bgColor = "grey";
        const fgColor = "white";
        const buttonStyle = { margin:"5px" , backgroundColor:`${bgColor}`}
        
        //const {isInvalidDate,originalDate} = this.state;
        const validDate = this.props.currentPositionDate; //(isInvalidDate) ?  originalDate: this.props.currentPositionDate;
        const MAX_LEN = 40

        const {selectedItem,draggingItem, itemDraggingTime,t} = this.props;

        let itemInfoTitle = draggingItem== null ? "" :  draggingItem.title;
        let itemInfoDuration = selectedItem!=null ? 
        //moment.utc(moment.duration(selectedItem.duration*1000.0,'milliseconds').asMilliseconds()).format("HH:mm:ss") 
        moment.utc(moment.duration(moment(selectedItem.end_time).diff(selectedItem.start_time)).asMilliseconds()).format("HH:mm:ss") 
        : "N.A";
        let itemInfoStartTime = itemDraggingTime == null ? "" : moment(itemDraggingTime).format(DATE_TIME_FORMAT);
        if (itemInfoTitle==="") 
          {
              if (selectedItem!=null)
              {
                itemInfoTitle = selectedItem.title;
                itemInfoStartTime = moment(selectedItem.start_time).format(DATE_TIME_FORMAT);
              }
          }
        if (itemInfoTitle.length>MAX_LEN)
        itemInfoTitle = `${itemInfoTitle.substring(0,MAX_LEN)}... `;

        //const formattedDate = moment(this.props.currentPositionDate).format("DD/MM/YYYY hh:mm:ss");

        let buttonIcon = (this.props.isPlaying ? 
            (<FaPause data-place="top" data-tip={t("tl:tip_timeline_pause")}/>) :
            (<FaPlay data-place="top" data-tip={t("tl:tip_timeline_play")}/>));
        
        
        return(
        
            <div style={{flex: 1, flexDirection: 'row' , justifyContent :'flex-start', alignItems:'flex-start', flexShrink: '0'}}>
                 <ZoomPanel zoomDuration={this.props.zoomDuration}
                            onZoomChanged={this.props.onZoomChanged}
                 />
                 <IconButton 
                 style = {this.getColoredButtonStyle(this.props.isTimeCursorVisible)}
                 onClick={()=> this.toggleTimeCursor()}>
                    <IconContext.Provider value={{ color:  `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                       <FaRegClock data-place="top" data-tip={t("tl:tip_showHideTlCursor")} />
                    </IconContext.Provider>
                </IconButton>

        { this.props.canEdit &&
            <IconButton onClick={() => this.props.onToggleTimelineEditing()}  
            style = {this.getColoredButtonStyle(this.props.isEditingEnabled)}>
                <IconContext.Provider value={{color:  `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                    <FaEdit data-place="top" data-tip={t("tl:tip_enableDisableEditing")}  />
                </IconContext.Provider>
            </IconButton>
         }

                <IconButton 
                 style = {this.getColoredButtonStyle(this.props.isItemsNavigatorPanelVisible)}
                 onClick={()=> this.toggleItemsNavigator()}>
                    <IconContext.Provider value={{ color:  `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                       <FaListOl data-place="top" data-tip={t("tl:tip_showHideItemsNavigator")} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton 
                 style = {this.getColoredButtonStyle(this.props.isAttachmentsPanelVisible)}
                 onClick={()=> this.toggleAttachments()}>
                    <IconContext.Provider value={{ color:  `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                       <MdAttachment data-place="top" data-tip={t("tl:tip_showHideTlAttachments")} />
                    </IconContext.Provider>
                </IconButton>



                <IconButton style = {buttonStyle}
                onClick={()=> this.togglePlay()}>
                    <IconContext.Provider value={{ color:  `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                        { buttonIcon }
                    </IconContext.Provider>
                </IconButton>
                
              <div style={{display:'inline-block'}}>
                <DateTime 
                   inputProps={{"style":{"fontWeight" :'bold', "textAlign" : "center"}}}
                   onChange={this.handleDateChanged}
                   onFocus={() => this.props.onPause()}
                   //onBlur={this.handleBlur}
                   renderDay={ this.renderDay } 
                   renderMonth={ this.renderMonth } 
                   renderYear={ this.renderYear }
                   initialValue={ moment(validDate).format(DATE_TIME_FORMAT) }
                   defaultValue = { moment(validDate).format(DATE_TIME_FORMAT) }
                   dateFormat={DATE_FORMAT}
                   timeFormat={TIME_FORMAT}
                  />
            </div>
            <div style={{display:'inline-block', margin:'10px'}}>{itemInfoTitle!=="" &&
                 (<Badge color="primary">{itemInfoTitle}: {itemInfoStartTime} (Durata:{itemInfoDuration})</Badge>)}</div>
            <ReactTooltip/>     
            </div>
           );
    }

}

class ZoomPanelNT extends Component {


    zoomIn = () =>
    {
        const {zoomDuration} = this.props;

        let newDuration = moment.duration(zoomDuration).add(-1, this.getDurationStep(zoomDuration))
        this.props.onZoomChanged(newDuration);
    }


    zoomOut = () =>
    {
        const {zoomDuration} = this.props;

        let newDuration = moment.duration(zoomDuration).add(1, this.getDurationStep(zoomDuration))
        this.props.onZoomChanged(newDuration);
    }

    zoomReset = () => 
    {
        this.props.onZoomChanged(null);
    }

    getDurationStep = (duration) =>
     {
       if (duration.asMonths()>1)
       return "months" 
       else if  (duration.asWeeks()>1)
       return "weeks"
       else if  (duration.asDays()>1)
       return "days"
       else if (duration.asHours()>1)
       return "hours"
       else if (duration.asMinutes()>1)
       return "minutes"
       else return "seconds"
     }

    render() 
    {
        const {zoomDuration,t} = this.props;
        const bgColor = "grey";
        const fgColor = "white";
        const durationInfo = zoomDuration.asMilliseconds() < 60*60000 ?
        `${zoomDuration.format()} min.` : `${zoomDuration.format()}`
        const buttonStyle = { margin:"5px" , backgroundColor:`${bgColor}`}
        //console.log(`TL duration ${zoomDuration.format()}`);
        return(<div style={{display:'inline-block', margin:'5px'}}>
            <Badge color="primary" data-place="top" style={{margin:"10px"}}  data-tip={t("tl:tip_timelineWindowDuration")} >Zoom: {durationInfo}</Badge>
            <IconButton 
            style = {buttonStyle}
            onClick={()=> this.zoomIn()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                       <FiZoomIn data-place="top" data-tip={t("tl:tip_zoomIn")} />
                    </IconContext.Provider>
                </IconButton>

                <IconButton 
                style = {buttonStyle}
                onClick={()=> this.zoomOut()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                       <FiZoomOut data-place="top" data-tip={t("tl:tip_zoomOut")}  />
                    </IconContext.Provider>
                </IconButton>
                <IconButton 
                style = {buttonStyle}
                onClick={()=> this.zoomReset()}>
                    <IconContext.Provider value={{ color: `${fgColor}`, className: "global-class-name" , size: "0.5em"}}>
                       <GoScreenNormal data-place="top" data-tip={t("tl:tip_zoomReset")} />
                    </IconContext.Provider>
                </IconButton>
                <ReactTooltip/>  
        </div>)
    }
}

const ZoomPanel = withTranslation()(ZoomPanelNT);
const TimelinePlayer = withTranslation() (TimelinePlayerNT);
export default TimelinePlayer;