import React from 'react';
import { Header } from '../header'
import { SideBar } from '../components/SideBar'
import { Content } from '../components/Content';
import { Catalog } from '../components/Catalog'

export const CatalogPage = () => {
  return (
    <>
      <Header className="mb-0 text-white" section="Catalogo - Aree tematiche, percorsi ed esperimenti" showMenu={false} />
      <SideBar active="catalog" />
      <Content className="pt-3">
        <Catalog />
      </Content>
    </>
  )
}