import React from 'react';
import { toUpper } from 'lodash';
import ReactTooltip from "react-tooltip";

import {
  Container, Row, Col, Card, CardText, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faHome } from '@fortawesome/free-solid-svg-icons'


export const Experiment = (props: any) => {

  const { content } = props;
  console.log("esperimento:", content);

  const renderGradi = () => {
    return content.gradi.map((grado: string, index: number) => { return (<Badge key={grado} style={{ margin: '2px' }} color="primary">{grado}</Badge>) })
  };

  const renderTags = () => {
    return content.tags.map((tag: string, index: number) => { return (<Badge key={tag} style={{ margin: '2px' }} color="success"> {tag}{'    '}</Badge>) })
    };

    const renderDescrizione = () => {
      console.log("Contenuto esperimento:", content)
        if (content.descrizione.length > 300) {
            return <>
                {content.descrizione.substring(0, 300)}
                <a href={`/experiment/${content.id}`}>...</a>
            </>
        } else {
            return content.descrizione;
        }
       
    }

  return (
    <Card className="mb-3">
      <CardBody>
        <CardTitle data-tip="Esperimento" tag="h6" className="clearfix">
          
          <div className="float-left" style={{fontWeight: 'bold', color: "#007bff"}}>{content.titolo} <small>({content.durata} MIN.)</small></div>
         {content.live && 
           <div style={{ marginLeft:"10px"}} className="float-left" > <Badge color="danger">Live</Badge></div>
          }
          <div className="float-left" style={{ paddingLeft: 10, paddingTop: 2 }}>
            <a href={`/experiment/${content.id}`}><FontAwesomeIcon icon={faChevronCircleRight} style={{ fontSize: 18 }} color="#007bff" /></a>
          </div>
            </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted"><small>{toUpper(content.laboratorio)}</small>
        </CardSubtitle>
        <CardText>{renderDescrizione()}</CardText>
        <CardText>{renderGradi()}</CardText>
        <CardText>{renderTags()}</CardText>
        {/* 
         {!props.hideDetailsLink &&
          <CardLink className="pull-right" href={`/experiment/${content.id}`}>vai a Dettagli</CardLink>
        }  
        */}

      </CardBody>
      {/* <CardFooter>
        Durata: {content.durata} minuti
      </CardFooter> */}
      <ReactTooltip/>
        </Card >)

}