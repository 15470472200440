import React from 'react';

import {
  Container, Row, Col, Card, CardText, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input
} from 'reactstrap';
import { Experiment } from './Experiment';
import ReactTooltip from "react-tooltip";
import { PathExperiment } from './PathExperiment';

export const Topic = (props: any) => {
  const { content } = props;
  console.log("tematica:", content);

  const renderExperiments = () => {
    return content["esperimenti"].map((experiment: any, index: number) => {
      return (
        experiment["type"]=="path" ?
            <PathExperiment key={experiment.id} content={experiment} /> : 
            <Experiment key={experiment.id} content={experiment} />)
    })
  }
  return (
    <Card className="mb-4" style={{
      borderColor: "#007bff"
    }}>
      <CardHeader  data-tip="Area tematica" style={{
        backgroundColor: "#007bff",
        borderColor: "#007bff",
        paddingBottom: 0,
        color: 'white'

      }}>
        <CardTitle tag="h5">{content.titolo}</CardTitle>
      </CardHeader>
      <CardBody>
        {renderExperiments()}
      </CardBody>
      <ReactTooltip />
    </Card>)
}