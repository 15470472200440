 
import React, { useState } from 'react';
import {
  Button, FormGroup, Table, Modal, ModalHeader,
  ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle,
  NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon,
  InputGroupText, CardImg, Card, CardFooter, CardBody, CardHeader, CardTitle
} from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { actions as ExperimentActions} from '../store/slices/experiments'
//import { Header } from "../components/Header"
//import { Footer } from "../components/Footer"
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as UiSelectors from '../store/selectors/ui';
import { FiArrowUpLeft, FiArrowUp, FiArrowUpRight, 
      FiArrowLeft, FiHome, FiArrowRight, 
      FiArrowDownLeft, FiArrowDown, FiArrowDownRight,
      FiZoomIn, FiZoomOut, FiStopCircle,
      FiSkipBack, FiSkipForward
} from "react-icons/fi";
import ReactTooltip from "react-tooltip";

export const SyncSession: React.FC = () => {

  const dispatch = useDispatch();
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)

  const sceneCommands = ["/Scene Scena 1","/Scene Scena 1s","/Scene Scena 1d",
  "/Scene Scena 2","/Scene Scena 2s","/Scene Scena 2d"];
  let currentCommandIndex = 0;

  const getNextCommand = () =>
  {
    currentCommandIndex = (currentCommandIndex+1) % sceneCommands.length;
    return sceneCommands[currentCommandIndex];
  }

  const getPrevCommand = () =>
  {
    currentCommandIndex = (currentCommandIndex>0) ? (currentCommandIndex-1):
                                                    (sceneCommands.length-1) ;
    return sceneCommands[currentCommandIndex];
  }

  return (
    <BlockUi tag="div" loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus === true)}>
      <div className="App">
       {/*  <Header /> */}
        <div className="App-header">
          <Container>
          
        
              <Row>
                <Col>
                  <Card style={{ color: 'black' }}>
        
                    <CardBody>
                      <Row>
                        <Col>
                          <Button color="primary" onClick={()=>dispatch(ExperimentActions.willSendCommand(
                          {command:"move=upleft" , type:"cam1"}))} >
                              <FiArrowUpLeft />
                          </Button>
                        </Col>
                        
                        <Col>
                          <Button color="primary" onClick={
                            ()=>dispatch(ExperimentActions.willSendCommand({command:"move=up" , type:"cam1"}))}>
                          <FiArrowUp/>
                            </Button>
                        </Col>
                        
                       <Col>
                          <Button color="primary" onClick={
                            ()=>dispatch(ExperimentActions.willSendCommand({command:"move=upright" , type:"cam1"}))}>
                          <FiArrowUpRight />
                            </Button>
                        </Col>
                        
                        <Col>
                        <Button color="success" data-for="zoomIn" data-tip="Zoom +" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"rzoom=+200" , type:"cam1"}))}>
                          <FiZoomIn />
                          </Button>
                          <ReactTooltip id="zoomIn"/>
                          </Col>
                      </Row>

                      <Row className="mt-1">
                      
                      <Col>
                        <Button color="primary" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=left" , type:"cam1"}))}>
                          <FiArrowLeft/>
                        </Button>
                      </Col>
                      
                      <Col> 
                        <Button data-for="home" data-tip="Home" color="success"  onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=home" , type:"cam1"}))}>
                          <FiHome/>
                        </Button>
                        <ReactTooltip id="home"/>
                      </Col>
                      
                      <Col> 
                        <Button color="primary" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=right" , type:"cam1"}))} >
                          <FiArrowRight />
                        </Button>
                      </Col>
                      
                      <Col>
                        <Button data-for="stop" data-tip="Stop" color="success" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=stop" , type:"cam1"}))}>
                        <FiStopCircle/>
                        </Button>
                        <ReactTooltip id="stop"/>
                      </Col>
                      
                      </Row>

                      <Row className="mt-1">
                      <Col>
                        <Button color="primary" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=downleft" , type:"cam1"}))}>
                            <FiArrowDownLeft/>
                        </Button> 
                      </Col>
                      
                      <Col>
                        <Button color="primary" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=down" , type:"cam1"}))}>
                            <FiArrowDown/>
                        </Button>
                      </Col>
                      
                      <Col>
                        <Button color="primary" onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"move=downright" , type:"cam1"}))}>
                            <FiArrowDownRight/>
                        </Button>
                      </Col>
                      
                      <Col>
                        <Button data-for="zoomOut" data-tip="Zoom -" color="success"   onClick={
                          ()=>dispatch(ExperimentActions.willSendCommand({command:"rzoom=-200" , type:"cam1"}))}>
                              <FiZoomOut/>
                        </Button>
                        <ReactTooltip id="zoomOut"/>
                        </Col>          
                      
                      
                      </Row>
                      

                      <Row>
                      <Col style={{ "marginTop": 16 }}>
                        <Button color="success" onClick={
                        ()=>dispatch(ExperimentActions.willSendCommand({command:getPrevCommand() , 
                        type:"obs"}))}>
                        <FiSkipBack style={{"margin":"10px"}}/>Scena precedente
                        </Button>
                        </Col>  
                      
                      <Col style={{ "marginTop": 16 }}>
                        <Button color="success"  onClick={
                        ()=>dispatch(ExperimentActions.willSendCommand({command:getNextCommand() , type:"obs"}))}>
                        Prossima scena<FiSkipForward style={{"margin" :"10px"}} />
                        </Button>
                      </Col>  
                        
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
             
         
          </Container>
        </div>
      </div>
      { /* <Footer /> */}
    </BlockUi>
  )
}

 