import {useState, useEffect}  from 'react';

import { Button,Badge,  Modal, ModalHeader, ModalBody, ModalFooter, 
        Form, FormGroup, Label, Input,FormFeedback } from 'reactstrap';
import DateTime from 'react-datetime';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import {selectors as UiSelector,  actions as UiActions} from '../store/slices/ui'
import {selectors as AppointmentsSelector, actions as AppointmentActions, Appointmentstate, getAppointmentById } from '../store/slices/appointments'
import {actions as RemoteSessionsActions} from '../store/slices/appointments'
import {actions as CatalogActions, selectors as CatalogSelectors } from '../store/slices/catalog'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as UsersSelectors, actions as UsersActions } from '../store/slices/users'
const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm:ss";

const appointmentstateLabel = { 
[Appointmentstate.NOT_FOUND as string] : ["error" ,"Non trovato"],
[Appointmentstate.CREATE as string] : ["warning","Disponibile"], 
[Appointmentstate.UNBOOKED as string] : ["warning","Disponibile"], 
[Appointmentstate.BOOKED as string]: ["success","Prenotata"]};


const AppointmentEditor = (props:any) =>
{   
    const dispatch = useDispatch();
    const closeModal = () => {dispatch(UiActions.closeAppointmentEditor());};
    const isOpen = useSelector(UiSelector.isAppointmentEditorOpen);
    const initialData = useSelector(UiSelector.getInitialData);
    const userAttributes = useSelector(ProfileSelectors.getProfile);
    const attendeeAttributes = useSelector(UsersSelectors.getCurrentUserData)
    console.log("AppointmentEditor:isOpen?", isOpen);
    const [deleteAppointmentStateModal, setDeleteAppointmentStateModal] = useState(false);
    const [cancelAppointmentStateModal, setCancelAppointmentStateModal] = useState(false);
    
    const backendErrorAlertModal = useSelector(UiSelector.getBackendErrorAlertModalInfo);

    const [currentTitle, setCurrentTitle] = useState(initialData==null  ||
        initialData.title==null ? "" : initialData.title);
    
        //console.log("Complete user profile:", userAttributes);

    const [currentLabTitle, setCurrentLabTitle] = useState(initialData==null  ||
      initialData.lab==null ? "" : initialData.lab);

    const [currentNotes, setCurrentNotes] = useState(initialData==null  ||
        initialData.note==null ? "" : initialData.note);

    const [currentStartDate, setCurrentStartDate] = useState( (initialData==null  ||
        initialData.startDate==null)  ? moment.now() : moment(initialData.startDate));
    
    const [currentDuration, setCurrentDuration] = useState(initialData==null ||
      initialData.stopDate==null ? 1 : moment(initialData.stopDate).diff(initialData.startDate)/3600000);

    
    const [appointmentstate, setAppointmentstate] = useState(
      initialData==null ||initialData.state==null ? "create" : initialData.state as String);
    
 
    const  [appointmentAttendee, setAppointmentAttendee] = useState(null);

    const [invalidTitle, setInvalidTitle] =  useState(currentTitle==null || currentTitle=="");
    const [invalidLabTitle, setInvalidLabTitle] =  useState(currentLabTitle==null || currentLabTitle=="");
    const [invalidDateStart, setInvalidDateStart] = useState(false);
    const [invalidDateEnd, setInvalidDateEnd] = useState(false);

    //const currentAppointmentId = initialData!=null && initialData.state!=null? initialData.state : -1;
    //const appointmentstateSelector =   useSelector(AppointmentsSelector.getAppointmentstate(currentAppointmentId));
    //const appointmentSelectorstate = useSelector(AppointmentsSelector.getAppointmentstate(appointmentId)); 
    
    const appointments = useSelector(AppointmentsSelector.getAppointments);

    const organizationExperiments = useSelector(CatalogSelectors.getCurrentOrganizationExperiments);
    const experiments = useSelector(CatalogSelectors.getExperiments);
    
    const currentExperimentForOrganization = initialData==null ? null : 
    experiments && experiments[initialData.title];

    const canEdit = userAttributes!=null && userAttributes.groups!= null &&
                    userAttributes.groups.includes("editor") &&
                    (currentExperimentForOrganization==null || 
                      userAttributes.groups.includes(`organization_${currentExperimentForOrganization["organization"]}`));
                    
    
    useEffect(()=>{
      console.log("Carico esperimenti di competenza dell'editor", userAttributes);
      if (userAttributes==null || userAttributes.groups==null) return;
      const organizationGroups = userAttributes.groups.filter((e:string,i:number) => {return e.startsWith("organization_")});
      const organizations = organizationGroups.map((e:string,i:number)=> e.substring("organization_".length))
      // per il momento passo solo la prima organization che trovo...
      // 
      if (organizations.length<1) return;
      console.log("Ho trovato le  organization:", organizations);
      dispatch(CatalogActions.willLoadOrganizationExperiments(organizations[0]));
    }, [userAttributes]);
   
  
  

    const renderExperimentOptions = () =>
    {
      console.log("organizationExperiments:", organizationExperiments);
      return organizationExperiments.map(
        (e:any,i:number) => <option key={e["id"]} value={e["id"]}>{e["titolo"]}</option>);
    }

     

    useEffect(()=>{
       console.log("DATI UTENTE: Richiamato effect di attendeeAttributes appState:",appointmentstate==Appointmentstate.BOOKED);
       console.log("DATI UTENTE: initial data:",initialData);
       console.log("DATI UTENTE: attendeeAttributes:",attendeeAttributes);
       if (appointmentstate==Appointmentstate.BOOKED && attendeeAttributes!=null && 
        initialData!=null && initialData.attendee!="" && 
        attendeeAttributes.id==initialData.attendee)
      {
        console.log("Individuato Attendee Data:", attendeeAttributes);
        const info:any = 
        `${attendeeAttributes.given_name} ${attendeeAttributes.family_name}`;
        setAppointmentAttendee({...attendeeAttributes, info});
      }
      else {
        setAppointmentAttendee(null);
      }
    },
    [attendeeAttributes, initialData, appointmentstate]
    );


    useEffect(
      () => { console.log("MODIFICATO LO STATO DI UN APPUNTAMENTO con initialData:", initialData);
        if (initialData!=null && initialData.id!=null)
        {
          const appointment = getAppointmentById(appointments as any, initialData.id as any);
          if (appointment!=null)
          {
           
            //setAppointmentAttendee(appointment.attendee);
            setAppointmentstate(appointment.state);
            console.log(`MODIFICATO LO STATO DI UN APPUNTAMENTO User SubID ${userAttributes.sub} ha aperto App di:${appointment.owner}:`,);
          }
          
        }
    } , 
      [appointments]
    )

    
    //console.log("Profilo:", userAttributes);
    useEffect(
      () => {
        console.log("INITIAL DATA CHANGED!", initialData);
        if (initialData!=null )
        {
          dispatch(UsersActions.willGetUser(initialData.attendee))
          setCurrentTitle(initialData.title);
          setCurrentLabTitle(initialData.lab);
          setCurrentNotes(initialData.note==null? "" : initialData.note);
          setCurrentStartDate(moment(initialData.startDate));
          setAppointmentstate(initialData.state==null ? Appointmentstate.CREATE : initialData.state);
          //setAppointmentAttendee(initialData.attendee);
          console.log(`Appointment owner: ${initialData.owner}`);
          setInvalidTitle(initialData.title=="" || initialData.title==null );
          setInvalidLabTitle(initialData.lab=="" || initialData.lab==null );
          setCurrentDuration(moment(initialData.stopDate).diff(initialData.startDate)/3600000);
        }
      },
      [initialData]
    )

  
    const saveAppointment = () =>
    {
      
      const appointmentId = initialData!=null && initialData.id !=null ? 
                            initialData.id : null;
      
   
      
      const endDate = moment(currentStartDate).add(currentDuration,"hours").toDate();
      console.log(`Appointment id: ${appointmentId} duration: ${currentDuration} end:${endDate}`);
      const appointment = { "id": appointmentId,
                             "state" : appointmentstate,
                             "attendee" : (appointmentstate==Appointmentstate.BOOKED) ? 
                                           (canEdit ? initialData.attendee : userAttributes.sub) : null,
                             "title" : currentTitle,
                             "lab" : currentLabTitle,
                             "startDate" : moment(currentStartDate).toDate(), // toDate() necessario per evitare la eccezione di rendering nel calendar
                             "stopDate" :  endDate,
                             "allDay" : false,
                             "note" :  currentNotes,
                             "organization" : initialData!=null ? initialData.organization : ""
                        }

        console.log("Sto salvando il seguente appointment:", appointment);
         // Salvo nel db
         if (appointmentId==null)
          dispatch(RemoteSessionsActions.willCreateAppointment(appointment))
        else
          dispatch(RemoteSessionsActions.willUpdateAppointment(appointment))
                                        
    }

    const confirmAppointment = () => {

      const appointment = getAppointmentById(appointments as any, initialData.id as any);
      if (appointment!=null)  {
        dispatch(AppointmentActions.willBookAppointment(appointment));
      }
    }

    const requestCancelAppointment = () =>
    {
       setCancelAppointmentStateModal(true);
    }

    const closeCancelAppointmentModal = () =>
    {
      setCancelAppointmentStateModal(false);
    }


    const cancelAppointment = () => {

      closeCancelAppointmentModal();

      const appointment = getAppointmentById(appointments as any, initialData.id as any);
      if (appointment!=null)  {
        console.log("SAGA sto passando da editor app:", appointment);
        dispatch(AppointmentActions.willUnbookAppointment(appointment));
      }; 
    }


    const requestDeleteAppointment = () =>
    {
      if (canEdit)
       setDeleteAppointmentStateModal(true);
    }

    const closeDeleteAppointmentModal = () =>
    {
      setDeleteAppointmentStateModal(false);
    }

    const deleteAppointment = () =>
    {
      console.log("Richiesta rimozione app con id:" + initialData.id);
      closeDeleteAppointmentModal();
      dispatch(AppointmentActions.willDeleteAppointment(initialData));    
    }

    

    const handleDateStartChange = (newDate:any) =>
    {
      console.log(`selezionata DateStart: ${newDate}`);
      try{
        const newDateStart =  moment(newDate,DATE_TIME_FORMAT);
        if (newDateStart.isValid())
        {
            setInvalidDateStart(false);
            setCurrentStartDate(newDate);
        }
        else 
        {
          setInvalidDateStart(true);
        }
        
      } catch (error){
         console.log(`Invalid DateStart: ${newDate} -> ${error}`);
         setInvalidDateStart(true);
      }
      
    }

    const handleDuration = (event:any) => {
      console.log(`Selezionata durata di ${event.target.value}`);
      setCurrentDuration(parseInt(event.target.value));
    }

    const handleNotesChange = (event:any) =>
    {
        setCurrentNotes(event.target.value);
    }

    
    

    useEffect(()=>{
      console.log("Nuovo valore di current title", currentTitle);
    }, [currentTitle]);


    useEffect(()=>{
      console.log("Imposto il corretto Lab all'esperimento di default currentLabTitle:",currentLabTitle );
      console.log("Imposto il corretto Lab all'esperimento di default initial data:",initialData );
      if (organizationExperiments!=null && (initialData==null ||  initialData.id==null))
      {
        if (organizationExperiments.length>0)
        updateExperimentData(organizationExperiments[0]["id"]);
      }
      return () => { }
     },[organizationExperiments, initialData]);

     const updateExperimentData = (experimentId:any) =>
    {  
        const selectedExperiment =  experiments[experimentId];
        console.log("imposto l'esperimento:",selectedExperiment["titolo"])
        setCurrentTitle(selectedExperiment["id"]);
        setInvalidTitle(selectedExperiment["id"]=="");
        setCurrentLabTitle(selectedExperiment["laboratorio"]);
        setInvalidLabTitle(selectedExperiment["laboratorio"]=="");
        const experimentDurationInHours =  parseInt(selectedExperiment["durata"])/60;
        setCurrentDuration(experimentDurationInHours);
         
    }
       
    const handleExperimentChange = (event:any) =>
    {  console.log("Selezionato esperimento con id:", event.target.value)
    updateExperimentData(event.target.value);
    }



    const handleLabTitleChange = (event:any) =>
    {
        setCurrentLabTitle(event.target.value);
        setInvalidLabTitle(event.target.value=="");
    }


    const isValidForm = () =>{
      return !invalidTitle && !invalidLabTitle && !invalidDateStart  && !invalidDateEnd
    }
   
    const headerInfo = (initialData==null || initialData.id==null) ?
    "Nuova prenotazione" : `Modifica prenotazione` // n.${initialData.id}`
    

    const renderBookingButtons = () => {

      const cancelAppointmentButtonVisile = canEdit || 
      (appointmentAttendee!=null && (appointmentAttendee as any).id==userAttributes.sub);
      console.log("cancelAppointmentButtonVisile ?", cancelAppointmentButtonVisile);
      console.log("cancelAppointmentButtonVisile Att", appointmentAttendee);
        return(
        (!canEdit &&
        appointmentstate!=Appointmentstate.BOOKED && 
        <Button color="primary" onClick={confirmAppointment}>Prenota</Button>)
        || 
        ( cancelAppointmentButtonVisile &&
        appointmentstate==Appointmentstate.BOOKED && 
        <Button color="primary" onClick={requestCancelAppointment}>Disdici</Button>)
        )
    }
    
    const getCurrentExperimentName = () =>
    {
      const currentEsperiment = experiments && experiments[currentTitle];
      if (currentEsperiment!=null) return  currentEsperiment["titolo"]
      else return "N.A"
    }

    console.log("AppointmentEditor:isOpen:", isOpen);
   return  ( <Modal isOpen={isOpen}>
            <ModalHeader>{headerInfo}
            </ModalHeader>
          
            <ModalBody>

          <Modal isOpen={deleteAppointmentStateModal}>
            <ModalHeader>Rimozione dell'evento</ModalHeader>
            <ModalBody>Confermare la rimozione dell'appuntamento? (Eventuali prenotazioni saranno annullate)</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={deleteAppointment}>Si</Button>{' '}
              <Button color="secondary" onClick={closeDeleteAppointmentModal}>No</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={cancelAppointmentStateModal}>
            <ModalHeader>Cancellazione della prenotazione</ModalHeader>
            <ModalBody>Confermare la cancellazione dell'appuntamento?</ModalBody>
            <ModalFooter>
              <Button color="primary"   onClick={cancelAppointment}>Si</Button>{' '}
              <Button color="secondary" onClick={closeCancelAppointmentModal}>No</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={backendErrorAlertModal[0]}>
            <ModalHeader>Errore nel salvataggio dei dati</ModalHeader>
            <ModalBody><b>Dettagli dell'errore:</b>
            <p>{backendErrorAlertModal[1]}</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" 
              onClick={() => dispatch(UiActions.closeBackendErrorAlertModal())}>Ok</Button>{' '}
            </ModalFooter>
          </Modal>


              {canEdit && appointmentAttendee!=null &&  ( <Label>
                <b>Utente prenotato: </b>{(appointmentAttendee as any).info}
                    </Label>)}
            <Form>

            <FormGroup>
              <Label for="txtTitle">
                <b>Esperimento</b></Label>
          { canEdit ?
              <Input id="selectTitle" 
                    disabled={!canEdit} 
                    value={`${currentTitle}`}
                    onChange={handleExperimentChange}
                    type="select">
                     
                 {renderExperimentOptions()}
              </Input> 

              :  <Input id="selectTitle" 
               readOnly
               value={getCurrentExperimentName()}
            
              type="text">
               
        </Input> 
              
              }
                    
                 <FormFeedback>Campo obbligatorio</FormFeedback>
              </FormGroup>

            <FormGroup>
                <Label for="txtLab">
                    <b>Laboratorio</b>
                    </Label>
                     <Input id="txtLab" readOnly={true} type="text" 
                     value={currentLabTitle} onChange={handleLabTitleChange}/>
              
              </FormGroup>

            

              <FormGroup>
                  <Label for="textareaNotes"><b>Note</b></Label>
                  <Input readOnly={!canEdit} type="textarea" name="text" 
                  value={currentNotes} 
                  id="textareaNotes"  onChange={handleNotesChange} />
              </FormGroup>
                


                <FormGroup>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
                <div><b>Inizio</b></div>
                <div><b>Durata</b></div>
              </div>
             
            <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: 20 }}>
              <div>
                 <FormGroup>
                    <DateTime inputProps={{ disabled:!canEdit}} 
                        onChange={handleDateStartChange}
                        dateFormat={DATE_FORMAT}
                        timeFormat={TIME_FORMAT}
                        initialValue={ moment(currentStartDate).format(DATE_TIME_FORMAT) }
                        />
                  </FormGroup>
              </div>
              <div>

              <Input id="selectDuration" 
                    
                    disabled={true} 
                    value={`${currentDuration}`}
                    onChange={handleDuration}
                    type="select">
                <option value="1">60 minuti</option>
                <option value="2">120 minuti</option>
              </Input> 

              </div>
              
            </div>
                </FormGroup>
            </Form>
            <h5>Stato: <Badge color={appointmentstateLabel[appointmentstate as string][0]}>{appointmentstateLabel[appointmentstate as string][1]}</Badge> </h5>
            </ModalBody>
            <ModalFooter>
              {renderBookingButtons()}
              
              
            { canEdit && 
              <>
              <Button  disabled={initialData==null || initialData.id==null} 
              color="danger" onClick={requestDeleteAppointment}>Elimina</Button>
              <Button disabled={!isValidForm()} color="primary" onClick={saveAppointment}>Conferma</Button>{' '}
              </>
            }
              <Button color="secondary" onClick={closeModal}>{canEdit ? "Annulla" : "Chiudi"}</Button>
            </ModalFooter>
           
    </Modal>);
}

export default AppointmentEditor;