import React, { Component, useState } from 'react';
import {
  Badge, Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText, CustomInput, Tooltip } from 'reactstrap';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, CardLink } from 'reactstrap';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import ScrollArea from 'react-scrollbar';
import { MdAccessAlarms, MdPeople, MdAddBox, MdPerson } from 'react-icons/md';
import { IoMdDocument, IoIosContact, IoIosClose, IoIosCheckmark } from 'react-icons/io';
import { IconCheckbox } from "react-icon-checkbox";
import { findDOMNode } from 'react-dom'
import update from 'immutability-helper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { convertActionColor, convertActionType } from './TLAActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as DesignSelectors, actions as DesignActions } from '../store/slices/design'

var _ = require('lodash');



const styles = {
  container: (base: any) => ({
    ...base,
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
  }),
  control: (base: any) => ({
    ...base,
    fontSize: 12
  }),
  menuList: (base: any) => ({
    ...base,
    fontSize: 12
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: 20,
    minHeight: 20
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 1000
  })
};

export const TLAAction = ({
  width,
  dragHandle,
  index,
  tlaIndex,
  // action,
  readOnly
}: any) => {

  const dispatch = useDispatch();
  const [tooltipStudentsOpen, setTooltipStudentsOpen] = useState(false);
  const [tooltipTeacherOpen, setTooltipTeacherOpen] = useState(false);
  const [tooltipMinutesOpen, setTooltipMinutesOpen] = useState(false);

  const tooltipToggle = (key: any) => {

  }
  const readOnlyGroup = readOnly ? { disabled: true } : { disabled: false }
  const action = useSelector(state => (state as any).design.current.tlas[tlaIndex].actions[index])

  const onSelectChange = (value: any) => {
    dispatch(DesignActions.setActionMetadata({ tlaIndex: tlaIndex, actionIndex: index, name: "actionType", value: value.value }));
  }


  return (
    <div style={{ position: "relative" }}>
      <Card body inversed="true" className="learningTypes" style={{
        width: width - 6 - 6,
        //height: 102, dz
        // backgroundColor: this.props.learningType.color,
        backgroundColor: convertActionColor(action.actionType),
        padding: 0,
        paddingRight: 0,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 1,
        border: 0,
        borderRadius: 0,
        borderBottomRightRadius: "0.2rem",
        borderTopRightRadius: "0.2rem",
        borderWidth: 1,
        borderStyle: "solid",
        // borderColor: this.props.learningType.color,
        borderColor: convertActionColor(action.actionType)
        // marginLeft: 0,
        // marginRight: 0,
        // marginTop: 0,
        // marginBottom: "10 !important"
      }}>
        <div>
          <div style={{ color: "white", float: "left", width: 12, fontWeight: "800", textAlign: "center", height: "100%", fontSize: 12, paddingTop: 42 }} {...dragHandle}>{index + 1}</div>
          <div style={{ width: width - 32, float: "left" }}>
            <Form style={{ backgroundColor: "white", padding: 2, height: "100%", width: width - 32, float: "left", display: "inline" }}>
              <FormGroup {...readOnlyGroup} tag="fieldset">

                <Row form={true} style={{ margin: 0, padding: 0, backgroundColor: "#f0f3f5" }}>
                  <Col md={6} style={{ margin: 0, padding: 0 }}>
                    <Select
                      closeMenuOnSelect={true}
                      components={makeAnimated()}
                      styles={styles}
                      placeholder="Tipo di azione"
                      defaultValue={[]}
                      onChange={onSelectChange}
                      menuPortalTarget={document.body}
                      menuPosition={'absolute'}
                      portalPlacement={"auto"}
                      //delimiter={false}
                      isMulti={false}
                      isDisabled={readOnly}
                      options={[
                        { value: "rwl", label: "Leggi/Guarda/Ascolta" },
                        { value: "col", label: "Collabora" },
                        { value: "dis", label: "Discuti" },
                        { value: "inv", label: "Investiga" },
                        { value: "pra", label: "Pratica" },
                        { value: "pro", label: "Produci" }
                      ]}
                      value={convertActionType(action.actionType)}
                    />
                  </Col>
                  <Col md={2} style={{ margin: 0, padding: 0 }}>
                    <InputGroup>
                      <InputGroupAddon className="noMarginSpan" addonType="prepend"><MdAccessAlarms id={'minutes-' + action.id} /></InputGroupAddon>
                      <Input type="number" className="noMarginInput" min="0" placeholder="0" value={action.minutes} onChange={(e) => {
                        dispatch(DesignActions.setActionMetadata({ tlaIndex: tlaIndex, actionIndex: index, name: "minutes", value: e.target.value }));
                      }} />
                    </InputGroup>
                  </Col>
                  <Col md={2} style={{ margin: 0, padding: 0 }}>
                    <InputGroup>
                      <InputGroupAddon className="noMarginSpan" addonType="prepend"><MdPeople id={'students-' + action.id} /></InputGroupAddon>
                      <Input type="number" className="noMarginInput" min="0" placeholder="0" value={action.students} onChange={(e) => {
                        dispatch(DesignActions.setActionMetadata({ tlaIndex: tlaIndex, actionIndex: index, name: "students", value: e.target.value }));
                      }} />
                    </InputGroup>
                  </Col>
                  <Col md={2} style={{ margin: 0, padding: 0, paddingTop: 0, position: "relative" }} >
                    <MdPerson style={{ float: 'left', marginTop: 10, marginLeft: 4 }} id={'teacherPresence-' + action.id} />
                    <div style={{ float: 'left', marginLeft: 4, width: 24, height: 32, marginTop: 0, borderRadius: "0.25em", backgroundColor: 'white', position: "relative", border: 1, borderStyle: "solid", borderColor: "#e4e7ea" }} >
                      <div style={{ marginTop: 4, position: "relative" }}>
                        <IconCheckbox style={{ marginLeft: 20, marginTop: 4 }} checked={action.teacherPresence}

                          checkedIcon={<IoIosCheckmark size={20} color="#7AAEEA" />}
                          uncheckedIcon={<IoIosClose size={20} color="#F8807F" />}
                          iconContainerStyle={{ margin: 0, padding: 0, height: 20, position: "absolute", top: 0, left: 0 }}
                          onClick={() => {
                            console.log('in onChange for teacherPresence')
                            if (!readOnly) {
                              /*this.onTeacherPresenceChange*/
                              dispatch(DesignActions.setActionMetadata({ tlaIndex: tlaIndex, actionIndex: index, name: "teacherPresence", value: !action.teacherPresence }));
                            }
                          }} />
                      </div>
                      <Tooltip placement="right" isOpen={tooltipMinutesOpen} target={'minutes-' + action.id} toggle={() => tooltipToggle("tooltipMinutesOpen")}>Durata Attività</Tooltip>
                      <Tooltip placement="right" isOpen={tooltipStudentsOpen} target={'students-' + action.id} toggle={() => tooltipToggle("tooltipStudentsOpen")}>Dimensione gruppo di lavoro</Tooltip>
                      <Tooltip placement="right" isOpen={tooltipTeacherOpen} target={'teacherPresence-' + action.id} toggle={() => tooltipToggle("tooltipTeacherOpen")}>
                        Presenza docente: {action.teacherPresence ? "SI" : "NO"}
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
                <FormGroup style={{ padding: 0, margin: 0, paddingTop: 6 }}>
                  <Input style={{ fontSize: 12 }} type="textarea" name="description" id="description" placeholder="Description" value={action.description} onChange={(e) => {
                    dispatch(DesignActions.setActionMetadata({ tlaIndex: tlaIndex, actionIndex: index, name: "description", value: e.target.value }));
                  }} />
                </FormGroup>
              </FormGroup>
            </Form>
          </div>
        </div>
      </Card>
      {!readOnly &&
        <div style={{ zIndex: 10, position: 'absolute', right: 14, top: 0, width: 14, height: 24, backgroundColor: convertActionColor(action.actionType) }}><a style={{ color: "white", marginLeft: 2 }} onClick={(e) => {
          e.preventDefault();
          dispatch(DesignActions.deleteAction({ tlaIndex: tlaIndex, actionIndex: index }));
        }} href="#">X</a></div>
      }
    </div>
  )
}