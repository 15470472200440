import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { actions as DesignActions } from '../slices/design'
import * as DesignApi from '../../api/design'
import { actions as NotificationActions } from '../slices/notification'

export function* sagas() {
  console.log('inside profile saga')
  yield takeLatest(DesignActions.willListDesign.type, willListDesign)
  yield takeLatest(DesignActions.willGetDesign.type, willGetDesign)

}

function* willListDesign(action: any) {
  console.log('in willListDesign function');
  try {
    const result = yield call(DesignApi.listDesign);
    console.log('with result: ', result)
    yield put(DesignActions.didListDesignSuccess(result.data.listDesign))
  } catch (error) {
    console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willGetDesign(action: any) {
  try {
    const result = yield call(DesignApi.getDesign, action.payload);
    console.log('with result: ', result)
    yield put(DesignActions.didGetDesignSuccess(result.data.getDesign))
  } catch (error) {
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}