import { useState, useEffect } from 'react'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Calendar,Views, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import {selectors as AppointmentsSelector, actions as AppointmentActions, getAppointmentById,
  Appointmentstate } from '../store/slices/appointments'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { actions as UiActions } from '../store/slices/ui';
import { actions as CatalogActions, selectors as CatalogSelectors } from '../store/slices/catalog'
import {Container,Row,Label, Badge} from 'reactstrap';

//const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
//const DragAndDropCalendar = withDragAndDrop<any,any>(Calendar)
const localizer = momentLocalizer(moment);

const BUSY_COLOR ="#dc3545";
const BOOKED_COLOR = "#fd7e14";
const UNBOOKED_COLOR = "#007bff";


const AppointmentsViewer = (props: any) =>
{

  useEffect(()=>{
    console.log("Sono nel costruttore: Carico TUTTI gli esperimenti dal catalogo");
    dispatch(CatalogActions.willLoadExperiments());
  }, [])

  useEffect(()=>{
    console.log("Carico gli esperimenti dal catalogo");
    const {experimentId} = props;

    dispatch(CatalogActions.willLoadExperiments());
    if (experimentId!=null)
    {
      dispatch( AppointmentActions.willLoadAppointmentsByTitle(experimentId));
    }
                                                    
    else
    {
      console.log("Esperimento non specificato..carico tutti gli appuntamenti");
      dispatch( AppointmentActions.willLoadAppointments(
        {"start" : "1900-01-20T10:00:00.000Z","end" : "2050-01-20T10:00:00.000Z"}
      ));
    }
    

  }, [props.experimentId])

  const appointments = useSelector(AppointmentsSelector.getAppointments);
  const experiments = useSelector(CatalogSelectors.getExperiments);

  const dispatch = useDispatch();

  const userAttributes = useSelector(ProfileSelectors.getProfile);
  
  const canEdit = userAttributes!=null && userAttributes.groups!= null &&
  userAttributes.groups.includes("editor");
  console.log("User attributes da viewer:", userAttributes);
  console.log("User attributes canedit:", canEdit);

  const addAppointment = (event:any) => {
    event.state = 0;
    const newAppointment = {"startDate": event.start , "stopDate" : event.end}
    console.log("(addAppointment) Passo il payload :", newAppointment );
    console.log("Valore di UiActions:",UiActions)
    dispatch(UiActions.openAppointmentEditor(newAppointment ));
 }

 const editAppointment = (event: any) =>
 {
   const appointment = getAppointmentById(appointments, event.id);
   console.log("(editAppointment) Passo il payload :", appointment);
   dispatch(UiActions.openAppointmentEditor(appointment));  
 }

 useEffect(()=>{
   console.log("valore corrente di experiments:", experiments);
 }, [experiments]);
 

  const renderTitle = (ev:any) => {
    console.log("Calendar Event:", ev);
    const eventId = ev["title"];
    const experiment = experiments && experiments[eventId];
    console.log("richiamato render title con experiments:", experiments);
    
    if (experiment!=null)
    { console.log("rendo ", experiment["titolo"]);
      return experiment["titolo"];}

    else
    return ev["title"];
    
  }

  const handleEvent = (
    event: any,
    start: any,
    end: any,
    isSelected: any
) => {
 
  const currentAppointment = getAppointmentById(appointments, event.id);
  console.log("Appuntamento corrente:", currentAppointment);
  const eventColor = (currentAppointment!=null && 
    currentAppointment.state==Appointmentstate.BOOKED) ? 
  (currentAppointment.attendee==userAttributes.sub ? BOOKED_COLOR :BUSY_COLOR) : UNBOOKED_COLOR;
  //console.log(`Event color:${eventColor} state:${currentAppointment.state}`);
  return { style: {color:"white" , backgroundColor : eventColor} }
}
  console.log("Appointments da render", appointments);
  return(
    <Container fluid>
     
    <Calendar
      selectable={canEdit}
      popup={true}
      localizer={localizer}
      events={appointments}
      startAccessor="startDate"
      endAccessor="stopDate"
      titleAccessor={(ev) => renderTitle(ev)}
      tooltipAccessor={(ev) => renderTitle(ev)}
      onSelectEvent={(event) => editAppointment(event)}
      onSelectSlot={ (event) => addAppointment(event) }
      style={{ height: 500 }}
      eventPropGetter={handleEvent}
    
      //views={{ month: true, week: MyWeek }}
    />
     <Row style={{display: 'flex', justifyContent:'flex-end'}}>
        <Badge  style={{ margin:'5px', padding:'5px' , color:'white' , backgroundColor: BUSY_COLOR}}>Occupato</Badge>
        <Badge  style={{ margin:'5px', padding:'5px' , color:'white' , backgroundColor:BOOKED_COLOR}}>Prenotato</Badge>
        <Badge  style={{ margin:'5px', padding:'5px' , color:'white' , backgroundColor:UNBOOKED_COLOR}}>Disponibile</Badge>
      </Row>
    </Container>
    )
}

export default AppointmentsViewer;