import { API, graphqlOperation } from "aws-amplify";
import { loader } from "graphql.macro";

export const addExperiment = async (
  title: String,
  description: String,
  experimentStartTime: any,
  experimentStopTime: any,
  sections: any
) => {
  const query = loader("../graphql/addExperiment.gql");
  console.log("addExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        title,
        description,
        experimentStartTime,
        experimentStopTime,
        sections,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in addExperiment Query", error);
    throw error;
  }
};

export const updateExperiment = async (
  owner: any,
  experiment: any,
  title: String,
  description: String,
  experimentStartTime: any,
  experimentStopTime: any,
  sections: any
) => {
  const query = loader("../graphql/updateExperiment.gql");
  console.log("updateExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
        title,
        description,
        experimentStartTime,
        experimentStopTime,
        sections,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in updateExperiment Query", error);
    throw error;
  }
};

export const cloneExperiment = async (
  owner: any,
  experiment: any,
  title: String,
  description: String
) => {
  const query = loader("../graphql/cloneExperiment.gql");
  console.log("cloneExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
        title,
        description,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in cloneExperiment Query", error);
    throw error;
  }
};

export const clonePublicExperiment = async (
  owner: any,
  experiment: any,
  title: String,
  description: String
) => {
  const query = loader("../graphql/clonePublicExperiment.gql");
  console.log("clonePublicExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
        title,
        description,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in clonePublicExperiment Query", error);
    throw error;
  }
};

export const publishExperiment = async (owner: any, experiment: any) => {
  const query = loader("../graphql/publishExperiment.gql");
  console.log("publishExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in publishExperiment Query", error);
    throw error;
  }
};

export const proposePublicExperiment = async (owner: any, experiment: any) => {
  const query = loader("../graphql/proposePublicExperiment.gql");
  console.log("proposePublic query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in proposePublicExperiment Query", error);
    throw error;
  }
};

export const restoreExperiment = async (owner: any, experiment: any) => {
  const query = loader("../graphql/restoreExperiment.gql");
  console.log("restoreExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in restoreExperiment Query", error);
    throw error;
  }
};

export const sharePublicExperiment = async (owner: any, experiment: any) => {
  const query = loader("../graphql/sharePublicExperiment.gql");
  console.log("sharePublicExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in sharePublicExperiment Query", error);
    throw error;
  }
};

export const deleteExperiment = async (owner: any, experiment: any) => {
  const query = loader("../graphql/deleteExperiment.gql");
  console.log("deleteExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        owner,
        experiment,
      },
    });
    return result;
  } catch (error) {
    console.log("Error in deleteExperiment Query", error);
    throw error;
  }
};

export const listExperiments = async (owner: any) => {
  const query = loader("../graphql/listExperiments.gql");
  console.log("listExperiments query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { owner },
    });
    const experiments = result["data"]["listExperiments"];
    console.log("listExperiments result:", experiments);
    return experiments;
  } catch (error) {
    console.log("Error in listExperiments Query", error);
    throw error;
  }
};

export const listPublicExperiments = async (experiment:any) => {
  const query = loader("../graphql/listPublicExperiments.gql");
  console.log("listPublicExperiments query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { experiment },
    });
    const experiments = result["data"]["listPublicExperiments"];
    console.log("listPublicExperiments result:", experiments);
    return experiments;
  } catch (error) {
    console.log("Error in listPublicExperiments Query", error);
    throw error;
  }
};


export const getExperiment = async (owner: any, experiment: any) => {
  const query = loader("../graphql/getExperiment.gql");
  console.log("getExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {owner , experiment},
    });
    const res_experiment  = result["data"]["getExperiment"];
    console.log("getExperiment result:", res_experiment );
    return res_experiment;
  } catch (error) {
    console.log("Error in getExperiment Query", error);
    throw error;
  }
};

export const getPublicExperiment = async (experiment: any) => {
  const query = loader("../graphql/getPublicExperiment.gql");
  console.log("getPublicExperiment query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {experiment},
    });
    const res_experiment = result["data"]["getPublicExperiment"];
    console.log("getPublicExperiment result:", res_experiment );
    return res_experiment ;
  } catch (error) {
    console.log("Error in getExperiment Query", error);
    throw error;
  }
};


export const getUploadUrl = async (
  filename: String,
  expires: Number,
  contentType: String
) => {
 
  const query = loader("../graphql/getUploadUrl.gql");
  console.log("getUploadUrl query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        filename,
        expires,
        contentType
      },
    });
    return result;
  
  } catch (error) {
    console.log("Error in getUploadUrl Query", error);
    throw error;
  }
};


export const sendCommand = async (command: String, type: String) => {
  const query = loader("../graphql/sendCommand.gql");
  console.log("sendCommand. query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        type,
        command
      },
    });
    return result;
  } catch (error) {
    console.log("Error in sendCommand Query", error);
    throw error;
  }
};

