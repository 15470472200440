import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'
import { Button } from 'reactstrap';
import * as queryString from 'query-string'
import * as AuthApi from '../../api/auth'
import * as RegistrationApi from '../../api/registration'
import { actions as AuthActions, selectors as AuthSelector } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import { actions as UIActions } from '../slices/ui'
import { actions as ProfileActions, selectors as ProfileSelector } from '../slices/profile'
import { actions as AppointmentActions } from '../slices/appointments'

import { push } from 'connected-react-router'
import {select} from 'redux-saga/effects';

export function* sagas() {
  yield takeLatest(AuthActions.willLoginUser.type, willLoginUser)
  yield takeLatest(AuthActions.willLogoutUser.type, willLogoutUser)
  yield takeLatest(AuthActions.willRegisterUser.type, willRegisterUser)
  yield takeLatest(AuthActions.willSignupUser.type, willSignupUser)
  yield takeLatest(AuthActions.willConfirmUser.type, willConfirmUser)
  yield takeLatest(AuthActions.willForgotPasswordRequest.type, willForgotPasswordRequest)
  yield takeLatest(AuthActions.willForgotPasswordConfirm.type, willForgotPasswordConfirm)
  yield takeLatest(AuthActions.willResendSignup.type, willResendSignup)
  yield call(checkAuthentication);
  console.log('in auth saga');
}

function* checkAuthentication() {

  console.log("Richiamata checkAuthentication");
  yield call(AuthApi.configure);

  const result = yield call(AuthApi.isAuthenticated);
  
  console.log('in check auth onLoad: ', result);
  if (result) {
    const user = yield call(AuthApi.getAuthenticatedUser);
    console.log('in check auth onLoad Authenticated user: ', user);
    
    // verificare prima se l'utente ha già fatto la registrazione
    yield updateRegistrationState()

    yield put(AuthActions.didLoginUserSuccess(user));
  } else {
    yield put(AuthActions.didLoginUserFails({}));
  }
}
function* willConfirmUser(action: any) {
  console.log("in willConfirmUser with ", action)
  yield put(NotificationActions.willShowNotification({ message: "Confirming username " + action.payload.username, type: "info" }))

  try {
    yield put(UIActions.startActivityRunning("confirm"));
    localStorage.removeItem('username')
    localStorage.removeItem('emailConfirm')

    const result = yield call(AuthApi.confirm, action.payload.username, action.payload.code)
    console.log("willConfirmUser success result ", result)
    yield put(AuthActions.didConfirmUserSuccess(result));

    yield put(NotificationActions.willShowNotification({ message: result, type: "success" }));
    action.payload.history.push('/login')
    yield put(UIActions.stopActivityRunning("confirm"));
  } catch (error) {
    yield put(AuthActions.didConfirmUserFails(error));
    console.log("willConfirmUser fails error ", error)
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    yield put(UIActions.stopActivityRunning("confirm"));
  }
}




function* updateRegistrationState() {
      
      // Leggo la versione corrente della privacy
      const privacy:any = yield call(RegistrationApi.getCurrentPrivacyVersion);
      console.log("current privacy:", privacy.data.getCurrentPrivacyVersion.version);
      yield put(AuthActions.setCurrentPrivacyVersion(privacy.data.getCurrentPrivacyVersion));

      
      let userProfileData = {}

      // provo a leggere la versione corrente della privacy sottoscritta dall'utente in fase di registrazione
      // n.b: in caso di assenza di campo viene automaticamente catturata la eccezione
      try {


        // carico il profilo corrente della privacy dell'utente nello store, in modo da recuperare 
      // i campi correnti per la eventuale successiva modifica (a prescindere che la versione sottoscritta
      // sia quella correntemente in uso)
      const userRegistrationProfile = yield call(RegistrationApi.getUserProfile);
      console.log("user registration profile:", userRegistrationProfile);
      
      userProfileData = (userRegistrationProfile!=null && userRegistrationProfile.data!=null) ?
                userRegistrationProfile.data.getUserProfile : {}

        const userPrivacyAcceptance = yield call(RegistrationApi.getPrivacyAcceptance)
        console.log("redux debug userProfile restituito da RegistrationApi.getPrivacyAcceptance:", userPrivacyAcceptance);
        
     
        // leggo da API e carico lo stato corrente della accettazione della newsletter sullo store
        const newsLetterAcceptanceValue =yield call(RegistrationApi.getNewsletterAcceptance);
        console.log("Valore corrente newsLetterAcceptance:", newsLetterAcceptanceValue);
         
        const newsletterAcceptance = newsLetterAcceptanceValue.data.getNewsletterAcceptance.accepted;
        yield put(AuthActions.setNewsletterSubscribe(newsletterAcceptance));
        
        const privacyVersion = userPrivacyAcceptance.data.getPrivacyAcceptance.version;
        // verifico che la versione della normativa della privacy dell'utente corrisponda a quella corrente
        if (privacyVersion==privacy.data.getCurrentPrivacyVersion.version)
        {
          console.log(`Privacy acceptance version match:${privacyVersion} == current: ${privacy.data.getCurrentPrivacyVersion.version}`);
          // solo se sono passati tutti i controlli, l'utente viene impostato a "registered"
          yield put(AuthActions.didRegisterUserSuccess(userProfileData));
        }
        else throw `Error:Privacy acceptance version did non match user:${privacyVersion} != current: ${privacy.data.getCurrentPrivacyVersion}`
       
        return true;
        } catch(error) {
          // in caso di eccezione viene impostato lo stato di registrazione a False  
          console.log("redux debug inoltrato error da updateRegistrationState (isRegistered->false):", error);
            yield put(AuthActions.didRegisterUserFails({error: error , registrationProfile: (userProfileData) }));
            yield put(AuthActions.setNewsletterSubscribe(true));
            return false;
          }
    }

    
function* willLoginUser(action: any) {
  console.log('in willLoginUser with ', action)
  yield put(UIActions.startActivityRunning("login"));
  try {
    const result = yield call(AuthApi.login, action.payload.email, action.payload.password)
    console.log("Login result: ", result)
    
    let isUserRegistered:boolean = yield updateRegistrationState();
    
    // loggo l'utente dopo aver verificato se ha già effettuato la registrazione oppure no
    yield put(AuthActions.didLoginUserSuccess({ result: result, history: action.payload.history }));

    if (!isUserRegistered)
    { console.log("Utente non registrato...reindirizzo a registration_form");
      yield put(push("/registration_form"));
    }
    else {
      if (action.payload.location!=null)
        {
          const params = queryString.parse(action.payload.location.search);
          console.log("Parametri del login:", params);
          if (params["public_resource"]!=null)
          yield put(push(`/public/${params["public_resource"]}`));
          else yield put(push("/"))

        }
        else
        yield put(push("/"))
    }
    // Ricarico tutti gli appointments a login avvenuto
    //yield put(AppointmentActions.willLoadAppointments({"start" : "1900-01-20T10:00:00.000Z",
    //                                                   "end" : "2050-01-20T10:00:00.000Z"}));
    
  } catch (error) {
    yield put(AuthActions.didLoginUserFails(error));

    if (error.code == "UserNotConfirmedException") {
      console.log('in UserNotConfirmedException');
      const message = <>User not Confirmed - <Button color="link" href="/signup/confirm">Resend confirmation Email</Button></>
      localStorage.setItem('username', action.payload.email)
      localStorage.setItem('emailConfirm', "RESEND_SIGNUP_USER")
      yield put(NotificationActions.willShowNotification({ message: message, type: "danger", delay: 10000 }));
    } else {
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  }
  yield put(UIActions.stopActivityRunning("login"));
}

function* willRegisterUser(action: any) {
   
  yield put(UIActions.startActivityRunning("register"));
  console.log("SAGA willRegisterUser payload:", action.payload);
  let user = yield select(ProfileSelector.getProfile);
  console.log("userProfile:", user);
  const {school,
    schoolType,
    schoolLevel,
    schoolMatter,
    subscribeEmail,
    privacy} = action.payload;

    try{
  // 
  if (privacy==true)
  {
    console.log("Privacy accettata");
    const result = yield call(RegistrationApi.acceptPrivacy);
  }
  else throw("Privacy Policy not accepted by user!");
  
  // 1) salvataggio dello user profile
  const userRegistrationProfile = yield call(RegistrationApi.setUserProfile, school,
                          schoolType,
                          schoolLevel,
                          schoolMatter,
                          privacy)
      console.log("Registration result:", userRegistrationProfile.data.setUserProfile);
      
      // 2) salvataggio dello stato di sottoscrizione alla newsletter
      const newsLetterAcceptanceRepsonse = yield call(RegistrationApi.setNewsletterAcceptance, subscribeEmail);
      console.log("newsLetterAcceptanceRepsonse result:", newsLetterAcceptanceRepsonse);
      
      // 3) salvatggio dello stato sullo store
      yield put(AuthActions.setNewsletterSubscribe(subscribeEmail));
      
      // notifico il successo della registrazione solo se le precedenti operazioni sono andate a buon fine
      yield put(AuthActions.didRegisterUserSuccess(userRegistrationProfile.data.setUserProfile));
      yield put(UIActions.stopActivityRunning("register"));
      yield put(push("/"));
  } catch(error) {
      console.log("Error in Profile registration:", error);
      yield put(UIActions.stopActivityRunning("register"));
      yield put(AuthActions.didRegisterUserFails({ registeredError: error}));
      yield put(push("/"));
  }
        
} 


function* willLogoutUser(action: any) {
  try {
    const result = yield call(AuthApi.logout)
    yield put(AuthActions.didLogoutUser(result));
    // action.payload.history.push("/")
    yield put(push("/"))
  } catch (error) {
    yield put(AuthActions.didLoginUserFails(error));
  }
}

function* willSignupUser(action: any) {
  console.log('in willSignupUser with ', action)
  try {
    yield put(UIActions.startActivityRunning("signup"));
    localStorage.setItem('username', action.payload.email)
    localStorage.setItem('emailConfirm', "SIGNUP_USER")
    const result = yield call(AuthApi.signup, action.payload.email, action.payload.password, action.payload.given_name, action.payload.family_name)
    yield put(AuthActions.didSignupUserSuccess(result));
    //Redirect to Confirm
    action.payload.history.push('/signup/confirm')
    yield put(UIActions.stopActivityRunning("signup"));
  } catch (error) {
    yield put(AuthActions.didSignupUserFails(error));
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    yield delay(1000);
    yield put(UIActions.stopActivityRunning("signup"));
  }
}

function* willForgotPasswordRequest(action: any) {
  console.log("in willForgotPasswordRequest with ", action)
  yield put(UIActions.startActivityRunning("requestNewPassword"));
  try {
    localStorage.setItem('username', action.payload.email)
    localStorage.setItem('emailConfirm', "PASSWORD_RESET")
    const result = yield call(AuthApi.forgotPasswordRequest, action.payload.email)
    yield put(AuthActions.didForgotPasswordRequestSuccess(result))
    yield put(NotificationActions.willShowNotification({ message: "New password requested", type: "success" }));
    action.payload.history.push("/signup/confirm/")
  } catch (error) {
    yield put(AuthActions.didForgotPasswordRequestFails(error));
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("requestNewPassword"));
}

function* willForgotPasswordConfirm(action: any) {
  console.log("in willForgotPasswordConfirm with ", action)
  yield put(UIActions.startActivityRunning("confirmNewPassword"));
  try {
    localStorage.removeItem('username')
    localStorage.removeItem('emailConfirm')
    const result = yield call(AuthApi.forgotPasswordConfirm, action.payload.email, action.payload.code, action.payload.password)
    yield put(AuthActions.didForgotPasswordConfirmSuccess(result))
    yield put(NotificationActions.willShowNotification({ message: "New password confirmed", type: "success" }));
    action.payload.history.push('/login')
  } catch (error) {
    yield put(AuthActions.didForgotPasswordConfirmFails(error));
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("confirmNewPassword"));
}

function* willResendSignup(action: any) {
  console.log("in willResendSignupConfirm with ", action)
  try {
    yield put(UIActions.startActivityRunning("resendSignupConfirm"));
    localStorage.setItem('username', action.payload.email)
    localStorage.setItem('emailConfirm', "SIGNUP_USER")
    const result = yield call(AuthApi.resendSignuUpCode, action.payload.email)
    //Redirect to Confirm
    action.payload.history.push('/signup/confirm')
  } catch (error) {
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("resendSignupConfirm"));
}