import React from 'react';
import AppointmentsViewer from '../components/appointmentsViewer';
import { Catalog } from '../components/Catalog'
import { Header } from '../header'
import {
  Row, Col, Card, CardBody, 
  CardHeader, CardTitle
} from 'reactstrap';

export const HomePage = () => {

  return (
    <>
      <Header className="mb-3 text-white" section="Catalogo Percorsi ed Esperimenti" showMenu={false} />
      <Catalog />
      <Row className="m-0">
        <Col xs="12">
          <Card className="mb-4" style={{ borderColor: "#007bff" }}>
            <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
              <CardTitle tag="h5" className="text-center">Calendario</CardTitle>
            </CardHeader>
            <CardBody>
              <AppointmentsViewer />
              {/* <AppointmentEditor /> */}
            </CardBody>
          </Card>
        </Col>
      </Row>

    </>
  )
}