import { call, put, takeLatest } from 'redux-saga/effects'
import { actions as AppointmentActions } from '../slices/appointments'
import { actions as UiActions } from '../slices/ui'
import {createRemoteSession, updateRemoteSession, deleteRemoteSession, 
        listRemoteSessions, listRemoteSessionsByTitle, getResourcesForSession, bookRemoteSession, unbookRemoteSession} 
        from '../../api/sessions'
import moment from 'moment'
import { push } from 'connected-react-router'

export function* sagas() {
    yield takeLatest(AppointmentActions.willCreateAppointment.type, willCreateAppointment);
    yield takeLatest(AppointmentActions.willUpdateAppointment.type, willUpdateAppointment);
    yield takeLatest(AppointmentActions.willLoadAppointments.type, willLoadAppointments);
    yield takeLatest(AppointmentActions.willLoadAppointmentsByTitle.type, willLoadAppointmentsByTitle);
    yield takeLatest(AppointmentActions.willDeleteAppointment.type, willDeleteAppointment);
    yield takeLatest(AppointmentActions.willBookAppointment.type, willBookAppointment);
    yield takeLatest(AppointmentActions.willUnbookAppointment.type, willUnbookAppointment);
    yield takeLatest(AppointmentActions.willLoadResourcesForSession.type, willLoadResourcesForSession);
    //yield put(AppointmentActions.willLoadAppointmentsByTitle("101"));
                                                    
    //yield put(AppointmentActions.willLoadAppointments({"start" : "1900-01-20T10:00:00.000Z",
    //                                                   "end" : "2050-01-20T10:00:00.000Z"}));
                                                      
    
}
 
function* willCreateAppointment(action: any) {
    console.log("SAGA: willCreateAppointment", action.payload);
     
    const appointment = action.payload;
     
     console.log("SAGA graphql:Salvo nel db");
     try{
        const result = yield call(createRemoteSession,appointment.lab, 
            appointment.note,
            moment(appointment.startDate).toISOString(),
            appointment.title,
            moment(appointment.stopDate).toISOString());
            const newAppId = result["data"]["createRemoteSession"]["id"]
            console.log("SAGA graphql:  new appointment with id:", newAppId);
            console.log("SAGA updating redux");
            appointment.id = newAppId;
            appointment.organization = result["data"]["createRemoteSession"]["organization"];
            yield put(AppointmentActions.editAppointment(appointment)); 
            console.log("SAGA closing modal");
            yield put(UiActions.closeAppointmentEditor()); 

     } catch (error) {
        console.log("SAGA: willCreateAppointment fails error ", error)
        const msg = "Error in willCreateAppointment:" + error["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
      }
}

function* willLoadAppointments(action: any) {
    const intervalRange = action.payload;

    console.log("SAGA: willLoadAppointments", action.payload);
    try{
        yield put(AppointmentActions.setAppointmentsLoaded(false)); 
        const appointments = yield call(listRemoteSessions,
                                    moment(intervalRange.start).toISOString(),
                                    moment(intervalRange.end).toISOString());
            const cappointments = getConvertedApointments(appointments)
            console.log("SAGA graphql: listRemoteSessions calling didAppointmentsloaded with ", cappointments);
            yield put(AppointmentActions.didAppointmentsloaded(cappointments)); 
           
     } catch (error) {
        console.log("SAGA: willLoadAppointments fails error ", error)
      }
}

function* willLoadAppointmentsByTitle(action: any) {
    const title = action.payload;

    console.log("SAGA: willLoadAppointmentsByTitle", action.payload);
    try{
        const appointments = yield call(listRemoteSessionsByTitle,
                                    title
                                    );
            const cappointments = getConvertedApointments(appointments)
            console.log("SAGA graphql: listRemoteSessionsByTitle calling didAppointmentsloaded with ", cappointments);
            yield put(AppointmentActions.didAppointmentsloaded(cappointments)); 
           
     } catch (error) {
        console.log("SAGA: willLoadAppointmentsByTitle fails error ", error)
      }
}

function* willLoadResourcesForSession(action: any) {
    const session = action.payload;

    console.log("SAGA: willLoadResourcesForSession", action.payload);
    try{
        const resources = yield call(getResourcesForSession,
                                    session
                                    );
           
            console.log("SAGA graphql: getResourcesForSession with ", resources);
            const payload = {"id": session, "items" : resources}
            yield put(AppointmentActions.didSessionResourcesLoaded(payload)); 
           
     } catch (error) {
        console.log("SAGA: willLoadResourcesForSession fails error ", error)
      }
}

const getConvertedApointments = (appointments:Array<any>) =>
{
    let cappointments  = [] as any;
    for (let i=0;i<appointments.length;i++)
    {
        let event = {...appointments[i] }
        event.startDate = moment(event.startDate).toDate();
        event.stopDate = moment(event.stopDate).toDate();
        cappointments.push(event as any);
    } 
   return cappointments;
}


function* willUpdateAppointment(action: any) {
    const appointment = action.payload;
    console.log("SAGA: willUpdateAppointment", action.payload);
    try{
        const result = yield call(updateRemoteSession,
                                    appointment.id, 
                                    appointment.lab, 
                                    appointment.note,
                                    moment(appointment.startDate).toISOString(),
                                    appointment.title,
                                    moment(appointment.stopDate).toISOString());
            
            console.log("SAGA graphql:  updated appointment with id:", appointment.id);
            appointment.organization = result["data"]["updateRemoteSession"]["organization"];
            console.log("SAGA updating redux willUpdateAppointment res:", result);
             
            yield put(AppointmentActions.editAppointment(appointment)); 
            console.log("SAGA closing modal");
            yield put(UiActions.closeAppointmentEditor()); 

     } catch (error) {
         
        console.log("SAGA: willUpdateAppointment fails error ", error)
        const msg = "Error in willUpdateAppointment:" + error["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
      }
}

function* willDeleteAppointment(action: any) {
    const appointment = action.payload;
    console.log("SAGA: willDeleteAppointment", action.payload);
    try{
        const result = yield call(deleteRemoteSession,
                                    appointment.id, 
                                   );
            console.log("SAGA graphql:  delete appointment with id:", appointment.id);
          
            yield put(AppointmentActions.deleteAppointment(appointment)); 
            console.log("SAGA closing modal");
            yield put(UiActions.closeAppointmentEditor()); 

     } catch (error) {
        console.log("SAGA: willDeleteAppointment fails error ", error)
        const msg = "Error in willDeleteAppointment:" + error["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
      }
}

function* willBookAppointment(action: any) {
    const appointment = action.payload;
    console.log("SAGA: willBookAppointment Organization:", appointment);
    try{
        
        const result = yield call(bookRemoteSession,
                                    appointment.organization,
                                     appointment.id 
                                   );
    const bookedAppointment = result["data"]["bookRemoteSession"];
            console.log("SAGA graphql:  book appointment result:", bookedAppointment);
            const  cappointment= {...bookedAppointment}
            cappointment.startDate = moment(cappointment.startDate).toDate();
            cappointment.stopDate = moment(cappointment.stopDate).toDate();
            yield put(AppointmentActions.editAppointment(cappointment)); 
        return result;
     } catch (error) {
        console.log("SAGA: willBookAppointment fails error ", error)
        const msg = "Error in willBookAppointment:" + error["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
            }
      }
 

function* willUnbookAppointment(action: any) {
    const appointment = action.payload;
    console.log("SAGA: willUnbookAppointment", action.payload);
    try{
        const result = yield call(unbookRemoteSession,
                                    appointment.organization,
                                    appointment.id 
                                   );
            const unbookedAppointment = result["data"]["unbookRemoteSession"];
            console.log("SAGA graphql:  unbooked appointment:", unbookedAppointment );
            const  cappointment= {...unbookedAppointment}
            cappointment.startDate = moment(cappointment.startDate).toDate();
            cappointment.stopDate = moment(cappointment.stopDate).toDate();
            yield put(AppointmentActions.editAppointment(cappointment)); 
           
     } catch (error) {
       
        console.log("SAGA: willUnbookAppointment fails error ", error)
        const msg = "Error in willUnBookAppointment:" + error["errors"][0].message;
        yield put(UiActions.openBackendErrorAlertModal(msg));
      }
}
