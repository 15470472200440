import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { actions as ProfileActions } from '../slices/profile'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import * as AuthApi from '../../api/auth'

export function* sagas() {
  console.log('inside profile saga')
  yield takeLatest(ProfileActions.willRetrieveProfileData.type, willRetrieveProfileData)
  yield takeLatest(AuthActions.didLoginUserSuccess.type, willRetrieveProfileData)

}

function* willRetrieveProfileData(action: any) {
  console.log('in willGetProfileData function');
  try {
    const user = yield call(AuthApi.getAuthenticatedUser);
    console.log('with user in willRetrieveProfileData: ', user)
    console.log("User groups", user.signInUserSession.accessToken.payload["cognito:groups"]);
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    user.attributes["groups"] = (groups==null) ? [] : groups;
    yield put(ProfileActions.didRetrieveProfileData(user.attributes))
  } catch (error) {
    console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

}