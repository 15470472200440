// import Autosuggest from 'react-autosuggest';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { selectors as CatalogSelectors } from "../store/slices/catalog";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import MiniSearch from "minisearch";
import { filter } from "lodash";
// import Select from 'react-select'
// import CreatableSelect from 'react-select/creatable';
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { actions as CatalogActions } from "../store/slices/catalog";

// const theme = {
//   container: {
//     float: 'left',
//     width: 'calc(100% - 50px)',
//   },
//   input: {
//     width: '100%',
//     height: 50,
//     borderTopRightRadius: 0,
//     borderBottomRightRadius: 0,
//     borderStyle: 'solid',
//     borderWidth: 1,
//     borderColor: "#007bff",
//     borderTopLeftRadius: '4px',
//     borderBottomLeftRadius: '4px',
//     paddingLeft: 10,
//     paddingRight: 10

//   },
//   suggestionsContainerOpen: {
//     position: 'absolute',
//     zIndex: 1000,
//     width: 'calc(100% - 30px)',
//     backgroundColor: 'white',
//     border: '1px solid #007bff',
//     borderBottomRightRadius: 6,
//     borderBottomLeftRadius: 6,
//     borderTopWidth: 0
//   },
//   suggestion: {
//     fontSize: 14,
//     paddingTop: 7,
//     paddingBottom: 7,
//     listStyleType: 'none',
//     paddingLeft: 0,
//     marginLeft: 0

//   }
// }

export const SearchBar = () => {
  //Get all experiments for index
  const experiments = useSelector(CatalogSelectors.getExperiments);
  // const [suggestions, setSuggestions] = React.useState(experiments);
  const [value, setValue] = React.useState("");
  const [searchEngine, setSearchEngine] = React.useState(null as any);
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log("in constructor");
    const tokenize = (x: any) => x.split(/\W+/);

    setSearchEngine(
      new MiniSearch({
        fields: ["topic", "titolo", "esperimenti", "descrizione", "laboratorio", "ricercatori" , "durata", "frequenza_mensile",
                  "opportunita_culturale", "periodo_erogazione",  "gradi", "tags","fasi"], // fields to index for full-text search
        searchOptions: {combineWith:'AND'},
        // Custom extractField function that can deal with arrays
        extractField: (document, fieldName) => {
          
          if (Array.isArray(document[fieldName])) {
            //console.log("Trovato_array:",fieldName, document[fieldName]);
            
            //const result = document[fieldName].join(" ");

            const result = document[fieldName].map((obj:any,index:number) => {
              if (typeof(obj)=='object') return Object.values(obj).join(' ')
              else return obj
            }) 
           // console.log("Trovato_array_res:", result);
            return result
          } 
          else {
            //console.log("Trovato campo per field name:>>",fieldName, document[fieldName]);
            return document[fieldName];
          }
        },
        storeFields: ["titolo", "id", "topic"], // fields to return with search results
      })
    );
    return () => {};
  }, []);

  
  React.useEffect(() => {
    console.log("with experiments: ", Object.values(experiments));
    if (searchEngine != null) {
      // Index all documents
      searchEngine.addAll(Object.values(experiments));

      let results = searchEngine.search("onde");
      console.log("with results: ", results);
    }

    return () => {};
  }, [experiments, searchEngine]);
 


  // const inputProps = {
  //   placeholder: "Ricerca esperimenti e percorsi...",
  //   value: value,
  //   onChange: (event: any, data: any) => {
  //     console.log('in onChange with ', event, data);
  //     setValue(data.newValue);
  //   }
  // }

  const onChange = (e: any) => {
    console.log("in on change with ", e.target.value);
    const value = e.target.value;
    setValue(value);
    if (value !== "") {
      console.log("in onSUggestionFethRequest", value);
      const results = searchEngine.search(value, { prefix: true });
      console.log("with search result: ", value, results);
      const scoredResult = filter(results, (result: any) => {
        console.log("check score: ", result, result.score > 0);
        return result.score > 0 ? true : false;
      });
      // setSuggestions(result);
      console.log("MINISEARCH result: ", results);
      console.log("scored result: ", scoredResult);
      dispatch(CatalogActions.setFilteredIds(results));
    } else {
      // dispatch(CatalogActions.setFilteredIds(null));
      dispatch(CatalogActions.clearFilter());
    }
  };

  // function renderSuggestion(suggestion: any) {
  //   return <div><a href="#" onClick={(e: any) => {
  //     e.preventDefault();
  //     console.log('click on suggestion')
  //   }}>{toUpper(suggestion.titolo)}</a></div>;
  // }

  // function onSuggestionSelected(event: any, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any) {
  //   event.preventDefault();
  //   console.log('in select with suggestion', suggestion, suggestionValue, method);
  // }

  return (
    <div
      style={{
        width: "100%",
        border: "0px solid #000",
        height: 50,
        marginBottom: 20,
      }}
    >
      {/* <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={fetchRequest}
        onSuggestionsClearRequested={() => { 
          console.log('onSuggestionsClearRequested')
        }}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={(suggestion: any) => (suggestions as any).titolo}
        renderSuggestion={renderSuggestion}
        alwaysRenderSuggestions={true}
        inputProps={inputProps}
        theme={theme}
      />
      <div className="float-left" style={{
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderRightColor: "#007bff",
        borderTopColor: "#007bff",
        borderBottomColor: "#007bff",
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',

        backgroundColor: '#007bff',
        borderLeftWidth: 0,
        height: 50,
        width: 50,
        textAlign: 'center',
        paddingTop: 13,
        paddingBottom: 13
      }}> */}
      <InputGroup>
        <Input
          value={value}
          onChange={onChange}
          placeholder="Ricerca esperimenti e percorsi..."
        />
        <InputGroupAddon addonType="append">
          {value == "" ? (
            <Button style={{ width: 50 }} color="primary">
              <FontAwesomeIcon
                icon={faSearch}
                style={{ fontSize: 20 }}
                color="white"
              />
            </Button>
          ) : (
            <Button
              style={{ width: 50 }}
              onClick={() => {
                dispatch(CatalogActions.clearFilter());
                setValue("");
              }}
              color="primary"
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontSize: 20 }}
                color="white"
              />
            </Button>
          )}
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};
