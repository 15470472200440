import React from 'react';
import { toUpper } from 'lodash';
import {
  Container, Row, Col, Card, CardText, CardBody,
  CardHeader,CardTitle, CardSubtitle, Badge } from 'reactstrap';
import {
  Timeline,
  Events,
  TextEvent,
} from '@merc/react-timeline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'
import {theme} from './ExperimentPhasesTheme';
import {Link} from 'react-router-dom'

export const ExperimentDetails = (props: any) => {

  const FaseCard =( {children}:any ) => (
    <div>
      <span>{children} </span>
    </div>
  );

  const { content } = props;
  console.log("esperimento:", content);

  const renderRicercatori = () => {
    return content.ricercatori.map((ricercatore: string, index: number) =>
    { return (<Badge key={ricercatore} style={{ margin: '2px' }} color="primary">{ricercatore}</Badge>) })
  };

  const renderGradi = () => {
    return content.gradi.map((grado: string, index: number) => { return (<Badge key={grado} style={{ margin: '2px' }} color="primary">{grado}</Badge>) })
  };

  const renderTags = () => {
    return content.tags.map((tag: string, index: number) => { return (<Badge key={tag} style={{ margin: '2px' }} color="success"> {tag}{'    '}</Badge>) })
  };

  const renderFasi = () => {
    return content.fasi.map((fase: string, index: number) => { 
      return (<TextEvent key={index} date={""} text={fase} card={FaseCard} />)
  })};

  
  const renderSchedaEsperimento = () => {
    return content.url_scheda!=null && 
   <CardText><a target="_blank" href={content.url_scheda}>
     Scarica la scheda completa dell'esperimento</a>
     </CardText>
  }

  return(<Container fluid>
<Row>
        <Col xs="12">
        <Card className="mb-4" style={{
      borderColor: "#007bff"
    }}>
      <CardHeader style={{
        backgroundColor: "#007bff",
        borderColor: "#007bff",
        paddingBottom: 0,
        color: 'white'

      }}>
        <CardTitle tag="h5">{content.titolo} -  <small>{content.laboratorio}</small> 
        {content.live && 
           <div style={{ marginLeft:"10px"}} className="pull-right" > <Badge color="danger">Live</Badge></div>
          }
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xs="6">
              <CardSubtitle ><b>Descrizione</b></CardSubtitle>
              <CardText>{content.descrizione}</CardText>
              
              {renderSchedaEsperimento()}

          <CardSubtitle><b>Destinatari</b></CardSubtitle>
              {renderGradi()}
              <Row>
                <span>&nbsp;</span>
              </Row>
           
          <CardSubtitle><b>Ricercatori</b></CardSubtitle>
              {renderRicercatori()}
              <Row>
                <span>&nbsp;</span>
              </Row>
              
               
              <CardSubtitle ><b>Opportunità culturale del Laboratorio</b></CardSubtitle>
             
                <CardText>{content.opportunita_culturale}</CardText>

              
                
      </Col>
    
      <Col sx="6">
        <Row>
        <Col sx="6">
            <CardSubtitle ><b>Fasi dell'esperimento</b></CardSubtitle>
            <Timeline theme={theme}>
              <Events>
              {renderFasi()}
              </Events>
            </Timeline>
        </Col>

        <Col sx="6">
        <CardSubtitle ><b>Periodo di erogazione</b></CardSubtitle>
          <Badge color="info"> <small>{content.periodo_erogazione[0]} - {content.periodo_erogazione[1]}</small></Badge>
          <Row>
                <span>&nbsp;</span>
            </Row>
        <CardSubtitle ><b>Frequenza</b></CardSubtitle>
          <Badge color="info"> <small>{content.frequenza_mensile}</small></Badge>

          <Row>
                <span>&nbsp;</span>
            </Row>
        <CardSubtitle ><b>Durata</b></CardSubtitle>
          <Badge color="info"> <small>{content.durata} minuti</small></Badge>
          <Row>
                <span>&nbsp;</span>
            </Row>
          <Row>
          {renderTags()}
          </Row>
         
        </Col>
        
        </Row>
      
    
    </Col>
       
       
         
        </Row>
        <Row>
                <span>&nbsp;</span>
            </Row>
        {/* 
        <Row  style={{display: 'flex', justifyContent:'flex-end'}}>
        <div>
            <a target="_blank" href={`/timeline/f7ab5ebb-946e-4257-ad5f-fb4a2dfcc538`}>Vai alla Timeline principale dell'esperimento <FontAwesomeIcon icon={faChevronCircleRight} style={{ fontSize: 18 }} color="#007bff" /></a>
          </div>
         
        </Row>
        */}
      </CardBody>
    </Card>
    </Col>    
</Row>
  </Container>)
}