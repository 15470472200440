import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { CardText, Spinner } from 'reactstrap';
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {FiUpload} from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import {configuration} from "../../config"
import { useDispatch, useSelector } from 'react-redux';
import {selectors as AppointmentsSelector, actions as AppointmentActions,
    Appointmentstate } from '../../store/slices/appointments'
import { selectors as CatalogSelector} from '../../store/slices/catalog'
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import moment from 'moment';

const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export const VideoSessionPicker = (props:any) => {
    const {onFileUrlSelected } = props;
    const dispatch = useDispatch();
    const userAttributes = useSelector(ProfileSelectors.getProfile);
    const appointments = useSelector(AppointmentsSelector.getAppointments);
    const experiments = useSelector(CatalogSelector.getExperiments)
    const sessionResources = useSelector(AppointmentsSelector.getSessionResources)
    const areAppointmentsLoaded = useSelector(AppointmentsSelector.areAppointmentsLoaded)

    const [treeItems,setTreeItems] = React.useState(null);
   
    /* 
     name: "resources/sessions/d9b0fa7f-2df5-4a49-8788-5c92cd1b322c/sample2.mkv"
        session: "d9b0fa7f-2df5-4a49-8788-5c92cd1b322c"
        size: 573066
        updateDate: "2021-05-11T09:06:43.000Z"
    */

    // Carico le sessioni passate dell'utente all'avvio
     React.useEffect(() =>{
        
            dispatch( AppointmentActions.willLoadAppointments(
              {"start" : "1900-01-20T10:00:00.000Z","end" : "2050-01-20T10:00:00.000Z"}
            ));
      
           //dispatch( AppointmentActions.willLoadResourcesForSession("d9b0fa7f-2df5-4a49-8788-5c92cd1b322c"));
            console.log("dashboard: called willLoadAppointments");
          }, []);

          const lastAppointments = appointments.filter((app:any, i:number) => {

            
            return app.state==Appointmentstate.BOOKED && 
                    // filtro nel caso siano state omessi degli esperimenti dal catalogo corrente
                    experiments!=null &&  experiments[app["title"]]!=null && 
                   app.attendee==userAttributes.sub && !moment(app["stopDate"]).isAfter(moment.now())
          }).filter((app:any, i:number)=>{ return (experiments[app["title"]]as any)!=null }).map((app:any, i:number) => {
            console.log("Last app id:",app["id"]);
           // dispatch( AppointmentActions.willLoadResourcesForSession(app["id"]));
            return {"id": app["id"], "startDate" : `${app["startDate"]}`, "stopDate": `${app["stopDate"]}`,
            "experiment_id" : app["title"],
            "title" : (experiments[app["title"]] as any)["titolo"],
             "lab": app["lab"]}});
        
            
        // Una volta caricate le sessioni mando il dispatch per caricare le risorse di ciascuna sessione
        React.useEffect(() =>{
           console.log("Trovati appuntamenti passati:", lastAppointments);
            console.log("Appuntamenti caricati?:", areAppointmentsLoaded, " L:",lastAppointments.length);
        }, [lastAppointments]);
        

        const videoSessions = lastAppointments.map(
            (app:any) => {
                //console.log("Trovata sessione id:", app);
              if (sessionResources==null || sessionResources[app["id"]]==null) 
              dispatch( AppointmentActions.willLoadResourcesForSession(app["id"]));
    
                return { "id" : app["id"], 
            "title": `${moment(app["startDate"]).format("DD/MM/YYYY")} - ${app["title"]}` 
            
            , "items": [
               
            ]
        }});

        // Una volta fatto il dispatch per le risorse mi posso creare le videoSession per la TreeView
        React.useEffect(() =>{
            console.log("RICHIAMATO USEEFFECT SU sessionResources");

            for (const videoSession of videoSessions)
            {   
                videoSession.items = (sessionResources[videoSession["id"]]!=null ? 
                sessionResources[videoSession["id"]].map((obj:any) => { return {"id" : obj["name"], 
                "title" : (obj["name"] as string).split("/").slice(-1).pop(), 
                
                
                "url" :   `${configuration[stage]["baseUrl"]}/${obj["name"]}`}})
                
                : [])
            }
             
           // console.log("UseEffect Session resources->", sessionResources);

        setTreeItems(videoSessions);
        }, [sessionResources]);



    //console.log("VideoSessionPicker: Last appointments:", lastAppointments);
    const fakeVideoSessions: any = [
        {"title" : "Sessione del 01/01/2020" , "items" : [
            {id:"1234", "title" : "Registrazione 1", "url" : "http://resource1.mp4"},
            {id:"5678", "title" : "Registrazione 2", "url" : "http://resource2.mp4"},
        ]},
        {"title" : "Sessione del 02/01/2020" , "items" : [
            {id:"1234", "title" : "Registrazione 10", "url" : "http://resource10.mp4"},
            {id:"5678", "title" : "Registrazione 20", "url" : "http://resource20.mp4"},
        ]}
    ]

    const renderVideoSessionItems = (session:any) =>
    {
    
        return session.items.map((item:any, index:any) =>{
            return <TreeItem key={index} nodeId={`c_${item["id"]}`} label={ <span style={{
                display: 'flex',
                justifyContent : 'space-between',
                marginTop: "10px",
                alignItems: 'stretch'}}>
   <b> {item.title} </b>

   
<IconButton data-tip={"Aggiunta video"}  
style = {{ flex:"flexEnd", marginRight:"10px" , verticalAlign: 'top' }}

onClick={()=> {
    onFileUrlSelected(session.items[index].url);
}}>
   <IconContext.Provider  
   value={{ color: `black`,size: "0.5em"}}>
      <FiUpload data-place="top" 

      />
    
   </IconContext.Provider>
</IconButton>
<ReactTooltip/>

</span>}>
            </TreeItem>
        }) 

    }

    return (
        (treeItems!=null && areAppointmentsLoaded && lastAppointments.length>0)   ? 
        <TreeView
            
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
              
                     {  
                         (treeItems as any).map((session:any, index:number)=>{
                            const count = session.items.length;
                             return <TreeItem key={index} nodeId={`r__${index}`}label={
                                <span style={{   padding:'5px',
                                                 display: 'flex',
                                                 justifyContent : 'space-between',
                                                 marginTop: "0px",
                                                 alignItems: 'stretch'}}>
                                    <b> {session["title"]} ({count})</b>
                                    </span>
                         }>
                             { renderVideoSessionItems(session)}

                         </TreeItem>
                        })
                    }
                        
          
          </TreeView> : 
           
          ( (areAppointmentsLoaded && lastAppointments.length===0) ? 
                                (<div style={{
                                    display: 'flex',
                                    alignItems: 'center',   
                                    justifyContent: 'center',
                                }}>
                                   <CardText>Non è stata trovata alcuna sessione remota associata a questo utente.</CardText> 
                            </div>)

                             :
                          
                            (<div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Spinner color="primary" />    
                            </div>)
                                    )
                                    )
}



 