import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Progress, ButtonDropdown, 
  CardTitle, CardHeader, CardBody, ModalHeader, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, 
  FormGroup, Label, Input,  NavItem, Nav, NavLink, 
  Container, Col, Row,  Card, ModalFooter } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as UiActions from '../store/actions/ui';
import { FaSave, FaUpload, FaTrashAlt } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import 'react-block-ui/style.css';
import moment from 'moment';
import RialeTimelineViewer from '../components/RialeTimelineViewer';
import {tracks} from '../components/Timeline/FakeItems';
import { RouteConst } from '../consts/common';
import classnames from 'classnames';
import { BsPlusCircle } from "react-icons/bs";
import { FiEdit } from 'react-icons/fi'
import { useLocation, useParams, Link } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// import * as ContentSelectors from '../store/selectors/content';
import * as UiSelectors from '../store/selectors/ui';

import { selectors as ProfileSelectors } from '../store/slices/profile'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'

import {

  StyledInlineErrorMessage,
  StyledButton
} from "../styles/styles";

import { useTranslation } from 'react-i18next';
import { Header } from '../header';
import { Content } from '../components/Content';
import { IconButton } from '@material-ui/core';
const defTimelineStart = moment().startOf("day").add(10, "hours")
const defTimelineDuration = null;

export const TextAreaField: React.FC = (props) => {
  return (
    <Field component="textarea" rows={3} {...props} />
  )
}
const ExperimentSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, 'Titolo troppo corto')
    .max(50, 'Titolo troppo lungo')
    .required('Titolo obbligatorio'),
  description: Yup.string()
   // .required('Descrizione obbligatoria')
});


export const TimelineDetail: React.FC = () => {
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  
  const [currentPublicExperimentLink, setCurrentPublicExperimentLink] = useState(null) as any;

  let {id} = useParams() as any;
  const currentExperiment = useSelector(ExperimentsSelector.getExperiment(id));
  console.log(`Caricato experiment con id ${id}:`, currentExperiment);




  let saveButtonStatus = useSelector(UiSelectors.getSaveButtonStatus)
  let publicExperiments = useSelector(ExperimentsSelector.getPublicExperiments)

  // sezioni dell'esperimento (corrispondo ai vari tab)
  const sections = useSelector(ExperimentsSelector.getExperimentSections(id));
  console.log("sezioni:", sections && sections[0] && sections[0].items);
  React.useEffect(() => {
    console.log("TimelineDetail!");
    dispatch(ExperimentsActions.willLoadExperiments());
  }, [])

  React.useEffect(() => {
    console.log("TimelineDetail!");
    dispatch(ExperimentsActions.willLoadPublicExperiments(id));
  }, [id])

  React.useEffect(() => {
    console.log("Public experiment useEffect");
    if (id==null) 
    {
      setCurrentPublicExperimentLink(null);
    }
    else
    {
      const myPublicExperiments = publicExperiments[id];
      const lastPublicExpID = myPublicExperiments!=null && myPublicExperiments.slice(-1)[0];
      if (lastPublicExpID)
      {
        //const publicLinkUrl = `${RouteConst.Public}/${id}_${lastPublicExpID.experiment}` as any;
        const publicLinkUrl = `${RouteConst.Public}/${lastPublicExpID.experiment}` as any;
        console.log("Impostazione url pubblico:", publicLinkUrl);
        setCurrentPublicExperimentLink(publicLinkUrl);
      }
     
      else
      setCurrentPublicExperimentLink(null);
    }
 
  }, [publicExperiments, id])


  React.useEffect(() => {
    console.log("Save button status:", saveButtonStatus)
    if (saveButtonStatus.text == "TO SAVE") {
      window.onbeforeunload = () => ""
    } else {
      window.onbeforeunload = null
    }
    return (() => {
      window.onbeforeunload = null
    })
  }, [saveButtonStatus])

  const dispatch = useDispatch();
  const timelineStart = null; //il default lo sceglie il componente sulla base del contenuto degli item// defTimelineStart
  const timelineDuration = defTimelineDuration;
  
  const handleItemChanged = (item: { title: any; }, event: any) => {
    console.log(`Intercettato evento su item ${item.title} di tipo ${event}`, item);
    dispatch(ExperimentsActions.didUpdateItem(
      {id, event, item, sectionIndex: parseInt(activeTab)} 
      ));

    dispatch(UiActions.saveButtonAction("TO SAVE"))
  }

  


  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);

  const [modalDelete, setModalDelete] = React.useState(false);
  const toggleModalDelete = () => setModalDelete(!modalDelete);
  const [modalDeleteAll, setModalDeleteAll] = React.useState(false);
  const toggleModalDeleteAll = () => {
    setModalDeleteAll(!modalDeleteAll);
    //window.close();
  }

  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalParams, setModalParams] = React.useState<any>({})
  const toggleModalEdit = (value: any) => {
    setModalEdit(!modalEdit);
    setModalParams(value)
    console.log('in modal edit', value)
  };

  const [activeTab, setActiveTab] = useState('0');
  console.log('changing tab....', activeTab)
  console.log('sezione corrente:', sections
  //!=null && sections[activeTab] 
  //&& sections[activeTab]["title"]
  );

  const toggleNav = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

 
  const confirmToggleNav = (tab: React.SetStateAction<string>) => {
    if (activeTab == tab) return;
    toggleNav(tab);
    /*
    if (saveButtonStatus.text != "TO SAVE") toggleNav(tab);
    else
      confirmAlert({
        title: `${sections[activeTab].title}`,
        message: `${t('uscita_senza_salvare')}`,
        buttons: [
          {
            label: `${t("tl:yes")}`,
            onClick: () => {
              toggleNav(tab);
            }
          },
          {
            label: 'No',
            onClick: () => {
            }
          }
        ]
      });

      */
  }

  
  let ownerID = ''
  const userID = useSelector(ProfileSelectors.getProfile).sub
  let canEditExperiment = true //ownerID == userID

  const dropDownExperiments = sections.map((value: any, i: number) => {
    return (
      <React.Fragment key={i}>
      <NavItem key={i} data-for={`tab_${i}`} data-tip={`${value.description}`}>
        <NavLink
          className={classnames({ active: activeTab === `${i}` })}
          onClick={() => { 
                            confirmToggleNav(`${i}`); 
                          }}
        >
          {value.title}{'   '}
          <>
            {(activeTab == `${i}` && canEditExperiment) ? (
              <FiEdit color='blue' onClick={() => { toggleModalEdit(value) }}></FiEdit>
            ) : (<></>)}
          </>
        </NavLink>
      </NavItem>
      <ReactTooltip id={`tab_${i}`}/>
      </React.Fragment>
    )
  })

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  console.log("Valore di sections:", sections);
  return (
    <>
      <Header className="mb-0 text-white" section="Timeline" showMenu={false} />
      <Content fluid={true} className="pt-3" >
        <Row className="m-0" style={{ height: 'calc(100% - 22px)' }}>
          <Col xs="12" style={{ height: '100%' }}>
            <Card className="mb-4" style={{ height: '100%', borderColor: "#007bff" }}>

              <CardHeader style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                paddingBottom: 0,
                color: 'white'

              }}>
                <CardTitle tag="h5">{currentExperiment!=null ?
                currentExperiment["title"] : "Caricamento in corso..."}

             
     { canEditExperiment  &&
                <>  
                <IconButton className="pull-right" disabled={saveButtonStatus.text!=="TO SAVE"} 
                data-place="top" data-for="saveExperiment" data-tip="salva le modifiche"
                onClick={() => { 
                  dispatch(ExperimentsActions.willUpdateExperiment(currentExperiment)) }}>

                <IconContext.Provider value={{color: `${saveButtonStatus.text==="TO SAVE" ? 'yellow' : 'green'}`}}>
                    <FaSave  size={'0.9em'} />
                </IconContext.Provider>
                </IconButton>
     
                <IconButton className="pull-right"  
                data-for="publishExperiment"  data-tip="pubblica l'esperimento"
                 
                 onClick={() => { 
                   console.log("dispatch willPublishExperiment su ID:", id);
                   dispatch(ExperimentsActions.willPublishExperiment({experiment:id})) }}
                 >
                <IconContext.Provider value={{color: `white`}}>
                    <FaUpload  size={'0.8em'}  />
                </IconContext.Provider>
                </IconButton>

                <ButtonDropdown direction="left" isOpen={dropdownOpen} toggle={toggleDropdown}
                className="pull-right">
                <DropdownToggle style={{backgroundColor: "#007bff"}} caret>
                <FaTrashAlt color='white' size={'1.2em'}/>
                    </DropdownToggle>
                    <DropdownMenu>
                      {
                        sections.length>1 && 
                        <DropdownItem onClick={toggleModalDelete}>Elimina la sezione corrente</DropdownItem>
                      }
                    
                    <DropdownItem onClick={() => { toggleModalDeleteAll() }}>Elimina l'intero esperimento</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
                <ReactTooltip id="saveExperiment"/>   
                <ReactTooltip id="publishExperiment"/>  
              </>}

                </CardTitle>
              </CardHeader>
              <CardBody>
                {/* <div className="App"> */}
                <div>
                  <Nav tabs>
                    {dropDownExperiments}
                    { canEditExperiment ? (
                      <NavItem>
                        <NavLink style={{ cursor: 'pointer' }}
                          onClick={toggleModal}
                        >
                          <BsPlusCircle color='blue' size="1.5em" />
                        </NavLink>
                      </NavItem>
                    ) : (<></>)}
                  </Nav>
                  <div></div>
                </div>

                {(sections && sections[activeTab]!=null) ? (
                  <div>
                    <Row>
                      <Col>
                        <Card body className="text-center">
                          {/* 
                          <CardHeader tag="h4">{sections[activeTab].title}:{sections[activeTab].description}</CardHeader>
                        */}
                        </Card>
                      
                      </Col>
                      {canEditExperiment ? (
                        <Col>
                          
                          {(currentPublicExperimentLink!=null) ? (
                            <Button color="light" target="_blank" style={{ float: "right" }} >
                              <Link to={currentPublicExperimentLink} target="_blank">{t('vai_a_risorsa_pubblica')}</Link></Button>
                          ) : (<></>)}
                        </Col>
                      ) : (<></>)}
                    </Row>
                    
                { sections!= null && sections.length>0 && sections[activeTab]!=null &&     
               
                    <RialeTimelineViewer activeTab={activeTab} 
                      items={sections[activeTab].items}
                      tracks={tracks}
                      startDateTime={timelineStart}
                      // duration={timelineDuration}
                      onItemChanged={handleItemChanged}
                      title={sections[activeTab].title}
                      canEdit={canEditExperiment}
                    />
                    
                  }

                  </div>
                ) : (<></>)}

                <Modal isOpen={modalDelete} toggle={toggleModalDelete} >
                  <ModalHeader toggle={toggleModalDelete}>{t('elimina_esperimento')}</ModalHeader>
                  <ModalBody>{t('confermare_elimina_esperimento')}</ModalBody>
                  <ModalFooter>
                    <Button color="danger" style={{ float: "right" }} 
                    onClick={() => { 
                      //dispatch(ExperimentsActions.willDeleteExperiment({experiment:id }));  
                      dispatch(ExperimentsActions.didDeleteSection({"id": id,"sectionIndex" : parseInt(activeTab)}));
                      dispatch(UiActions.saveButtonAction("TO SAVE"));
                      toggleNav("0");
                      //dispatch(ExperimentsActions.willUpdateExperiment(currentExperiment));
                      setModalDelete(!modalDelete); }}>
                        
                        {t('elimina')}
                      
                      </Button>{' '}
                    
                    <Button color="info" onClick={toggleModalDelete} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modalDeleteAll} toggle={toggleModalDeleteAll} >
                    <ModalHeader toggle={toggleModalDeleteAll}>{t('elimina_gruppo_esperimenti')}</ModalHeader>
                    <ModalBody>{t('confermare_elimina_gruppo_esperimenti')}</ModalBody>
                    <ModalFooter>
                      <Button color="danger" style={{ float: "right" }} onClick={() => { 
                        dispatch(ExperimentsActions.willDeleteExperiment({experiment:id }));  
                        setModalDeleteAll(!modalDeleteAll); 
                        dispatch(push(`/timeline/`));
                        }}>{t('elimina')}</Button>{' '}
                      <Button color="info" onClick={toggleModalDeleteAll} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                    </ModalFooter>
                  </Modal>




                <Modal isOpen={modal} toggle={toggleModal} >
                  <ModalHeader toggle={toggleModal}>{t('aggiungi_esperimento')}</ModalHeader>
                  <ModalBody>
                    <Formik
                      initialValues={{
                        title: '',
                        description: ''
                      }}
                      validationSchema={ExperimentSchema}
                      validateOnBlur={true}
                      onSubmit={values => {
                        console.log("exp values: ", values);

                        const section = {"title": values.title, "description" : values.description, 
                        "items":[]}
                        dispatch(ExperimentsActions.didAddSection(
                          {section, sectionIndex:activeTab, id}
                        ));
                        setModal(!modal);
                        dispatch(UiActions.saveButtonAction("TO SAVE"))
                      }}
                    >
                      {({ errors, touched, setFieldValue, values }) => (
                        <Container>
                          <Form name="create_experiment" method="post">
                            <FormGroup row>
                              <Label sm={3}>{t('titolo')}</Label>
                              <Col sm={9}>
                                <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                                {errors.title && touched.title ?
                                  <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label sm={3}>{t('descrizione')}</Label>
                              <Col sm={9}>
                                <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                {errors.description && touched.description ?
                                  <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col sm={9}>
                                <StyledButton block color="primary" type="submit" >{t('conferma_aggiunta_esperimento')}</StyledButton>{' '}
                              </Col>
                              <Col sm={3}>
                                <Button color="secondary" onClick={toggleModal}>{t('cancel')}</Button>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Container>
                      )}
                    </Formik>
                  </ModalBody>
                </Modal>


                <Modal isOpen={modalEdit} toggle={toggleModalEdit} >
                  <ModalHeader toggle={toggleModalEdit}>{t('modifica_esperimento')}</ModalHeader>
                  <ModalBody>
                    <Formik
                      initialValues={{
                        title: modalParams.title,
                        description: modalParams.description
                      }}
                      validationSchema={ExperimentSchema}
                      validateOnBlur={true}
                      onSubmit={values => {
                        console.log("section update values: ", values);
                        dispatch(ExperimentsActions.didUpdateSection({"id": id, "title" :values.title, 
                        "description" :values.description, "sectionIndex" : parseInt(activeTab)}))
                        dispatch(UiActions.saveButtonAction("TO SAVE"))
                        setModalEdit(!modalEdit);
                      }}
                    >
                      {({ errors, touched, setFieldValue, values }) => (
                        <Container>
                          <Form name="edit_experiment" method="post">
                            <FormGroup row>
                              <Label sm={3}>{t('titolo')}</Label>
                              <Col sm={9}>
                                <Input name="title" type="text" tag={Field} />
                                {errors.title && touched.title ?
                                  <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label sm={3}>{t('descrizione')}</Label>
                              <Col sm={9}>
                                <Input name="description" type="textarea" tag={TextAreaField} />
                                {errors.description && touched.description ?
                                  <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col sm={9}>
                                <StyledButton block color="primary" type="submit" >{t('conferma_modifica_esperimento')}</StyledButton>{' '}
                              </Col>
                              <Col sm={3}>
                                <Button color="secondary" onClick={toggleModalEdit}>{t('cancel')}</Button>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Container>
                      )}
                    </Formik>
                  </ModalBody>
                </Modal>
                <div>
                  {/* <Footer /> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Content>
      {/* </div> */}
      {/*  </BlockUi> */}
    </>

  );
}

export const UploadingModal = () => {
  const uploading = useSelector(UiSelectors.getUploadingState);
  const update_progress = useSelector(UiSelectors.getUpdateProgress);
  const [modal, setModalUploading] = React.useState(false);
  const uploadingToggle = () => setModalUploading(!uploading);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  console.log('uploading statusss ', uploading)
  return (
    <Modal isOpen={uploading} toggle={uploadingToggle}  >
      <ModalHeader>{t('upload_in_corso')}</ModalHeader>
      <ModalBody>
        <div>
          <div className="text-center"><h2>{Math.trunc(update_progress)}%</h2></div>
          <Progress bar color="info" value={Math.trunc(update_progress)} style={{ width: '70%' }} />
        </div>
      </ModalBody>
    </Modal>

  );
}
export default UploadingModal