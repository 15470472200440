export const getContainers = (state: any) => {
  return state.containers.containers;
};

export const getSyncSessions = (state: any) => {
  return state.containers.sync_sessions;
};

export const getExperiments = (state: any) => {
  return state.containers.experiments;
};

export const getPublicExperiments = (state: any) => {
  console.log("selector di public experiment", state.containers.public_container[0])
  return state.containers.public_container[0];
}
export const getPublicLinks = (state: any) => {
  return state.containers.public_container_links;
}

export const getContainer = (id:any) =>  (state: any) => {
  return  state.containers.containers.filter((el:any, index:number) =>{
    return el["container"]===id
  });
}