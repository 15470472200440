import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class TestVideo extends Component {
    render () {
        return (
            
        <div className='player-wrapper'>
            <h1>Test Player</h1>
            <ReactPlayer
            className='react-player fixed-bottom'
            url= 'videos/session_HD1.mkv'
            width='100%'
            height='100%'
            controls = {true}

            />
        </div>
        )
    }
}

export default TestVideo;