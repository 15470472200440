import { all, call, takeLatest, put, select, take } from "redux-saga/effects";
import {
  actions as ExperimentsActions,
  selectors as ExperimentsSelector,
} from "../slices/experiments";
import * as ExperimentsApi from "../../api/experiments";
import * as UiActions from "../actions/ui";
import { tracks, items } from "../../components/Timeline/FakeItems";
import {configuration} from "../../config"
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { buffers, eventChannel, END } from 'redux-saga';
//import * as FormData from 'form-data';
const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"

export function* sagas() {
  yield takeLatest(ExperimentsActions.willLoadExperiments.type, willLoadExperiments);
  yield takeLatest(ExperimentsActions.willLoadPublicExperiments.type, willLoadPublicExperiments);
  yield takeLatest( ExperimentsActions.willAddExperiment.type, willAddExperiment);
  yield takeLatest( ExperimentsActions.willUpdateExperiment.type, willUpdateExperiment);
  yield takeLatest( ExperimentsActions.willDeleteExperiment.type,willDeleteExperiment);
  yield takeLatest( ExperimentsActions.willCloneExperiment.type,willCloneExperiment);
  yield takeLatest( ExperimentsActions.willClonePublicExperiment.type,willClonePublicExperiment);
  yield takeLatest( ExperimentsActions.willPublishExperiment.type,willPublishExperiment);
  yield takeLatest( ExperimentsActions.willGetExperiment.type,willGetExperiment);
  yield takeLatest( ExperimentsActions.willGetPublicExperiment.type,willGetPublicExperiment);
  yield takeLatest( ExperimentsActions.willUploadFile.type,willUploadFile);
  yield takeLatest( ExperimentsActions.willSendCommand.type,willSendCommand);
}

function* willAddExperiment(action: any) {
  const data = action.payload;
  console.log("In willAddExperiment!");
  yield put(UiActions.loading(true));
 
  //data.sections[0].items = items; // test
  console.log("sezioni willAdd orig:", data.sections);

  for (let i=0;i<data.sections.length;i++ )
  {
    data.sections[i] = JSON.stringify(data.sections[i]);
  }
  console.log("sezioni willAdd orig2:", data.sections);
  try {
    let response = yield call(
      ExperimentsApi.addExperiment,
      data.title,
      data.description,
      data.experimentStartTime,
      data.experimentStopTime,
      // JSON.stringify(testSections) // 
      data.sections
    );
    console.log("add experiment result", response);
    yield put(ExperimentsActions.willLoadExperiments());
    yield put(UiActions.saveButtonAction("NO CHANGES"));
  } catch (e) {
    console.log("Amplify addExperiment error", e);
  }
  yield put(UiActions.loading(false));
}

function* willUpdateExperiment(action: any) {
  const data = action.payload;
  console.log("In willUpdateExperiment data:", data);
  yield put(UiActions.loading(true));
  const sections = data.sections == null ? null : data.sections.map((section:any) => JSON.stringify(section));
  console.log("In willUpdateExperiment sections:", sections);
  try {
    let response = yield call(
      ExperimentsApi.updateExperiment,
      data.owner,
      data.experiment,
      data.title,
      data.description,
      data.experimentStartTime,
      data.experimentStopTime,
      sections
    );
    console.log("willUpdateExperiment result", response);
    yield put(ExperimentsActions.willLoadExperiments());
    yield put(UiActions.saveButtonAction("NO CHANGES"));
  } catch (e) {
    console.log("willUpdateExperiment( error", e);
  }
  yield put(UiActions.loading(false));
}

function* willDeleteExperiment(action: any) {
  const data = action.payload;
  console.log("In willDeleteExperiment (data)", data);
  yield put(UiActions.loading(true));
  try {
    let response = yield call(
      ExperimentsApi.deleteExperiment,
      data.owner,
      data.experiment
    );
    console.log("willDeleteExperiment result", response);
    yield put(ExperimentsActions.willLoadExperiments());
    yield put(UiActions.saveButtonAction("NO CHANGES"));
  } catch (e) {
    console.log("willDeleteExperiment error", e);
  }
  yield put(UiActions.loading(false));
}

function* willCloneExperiment(action: any) {
  const data = action.payload;
  console.log("In willCloneExperiment (data)", data);
  yield put(UiActions.loading(true));
  try {
    let response = yield call(
      ExperimentsApi.cloneExperiment,
      data.owner,
      data.experiment,
      data.title,
      data.description,
    );
    console.log("willCloneExperiment result", response);
    yield put(ExperimentsActions.willLoadExperiments());
  } catch (e) {
    console.log("willCloneExperiment error", e);
  }
  yield put(UiActions.loading(false));
}

function* willClonePublicExperiment(action: any) {
  const data = action.payload;
  console.log("In willClonePublicExperiment (data)", data);
  yield put(UiActions.loading(true));
  try {
    let response = yield call(
      ExperimentsApi.clonePublicExperiment,
      data.owner,
      data.experiment,
      data.title,
      data.description,
    );
    console.log("willClonePublicExperiment result", response);
     
    yield put(ExperimentsActions.willLoadExperiments());
  } catch (e) {
    console.log("willClonePublicExperiment error", e);
  }
  yield put(UiActions.loading(false));
}


function* willLoadExperiments(action: any) {
  const owner = action.payload;
  const experiments = yield call(ExperimentsApi.listExperiments, owner);
  const validExperiments = experiments.filter((experiment:any) => {return experiment.status!=="DELETED"} );
  console.log("Lista degli esperimenti non cancellati trovati:", validExperiments);
  yield put(ExperimentsActions.setExperiments(validExperiments));
}

function* willLoadPublicExperiments(action: any) {
  const experiment = action.payload;
  console.log("In willLoadPublicExperiments on experiment:",experiment);
  const publicExperiments = yield call(ExperimentsApi.listPublicExperiments, experiment);
  
  console.log("In willLoadPublicExperiments result:",publicExperiments);
  yield put(ExperimentsActions.setPublicExperiments({id:experiment, experiments: publicExperiments}));
}

function* willPublishExperiment(action: any) {
  const data = action.payload;
  console.log("In willPublishExperiment!");
  yield put(UiActions.loading(true));

  try {
    let response = yield call(
      ExperimentsApi.publishExperiment,
      data.owner,
      data.experiment
    );
    console.log("willPublishExperiment result", response);
    console.log("willPublishExperiment data.experiment", data.experiment);
    yield call(willLoadPublicExperiments,{payload:data.experiment})

  } catch (e) {
    console.log("willPublishExperiment error", e);
  }
  yield put(UiActions.loading(false));
}

function* willGetExperiment(action: any) {
  const data = action.payload;
  console.log("In willGetExperiment (data)", data);
  yield put(UiActions.loading(true));
  try {
    let response = yield call(
      ExperimentsApi.getExperiment,
      data.owner,
      data.experiment,
    );
    console.log("willGetExperiment result", response);
    yield put(ExperimentsActions.setCurrentExperiment(response));
  } catch (e) {
    console.log("willGetExperiment error", e);
    yield put(ExperimentsActions.setCurrentExperiment(null));
  }
  yield put(UiActions.loading(false));
}

function* willGetPublicExperiment(action: any) {
  const experiment = action.payload;
  console.log("In willGetPublicExperiment (id):", experiment);

  const publicExperimentUrl = `${configuration[stage]["publicExperimentEndpoint"]}${experiment}`;
  console.log("In willGetPublicExperiment (publicExperimentUrl):", publicExperimentUrl);
  yield put(UiActions.loading(true));
  try {

    const response = yield call(() =>
    fetch(publicExperimentUrl)
      .then(response => response.json())
      .then(myJson => myJson)
  );
    console.log("In willGetPublicExperiment (response):" , response);
    /*
    let response = yield call(
      ExperimentsApi.getPublicExperiment,
      experiment,
    );
    */
    console.log("willGetPublicExperiment result", response);
    yield put(ExperimentsActions.setCurrentPublicExperiment(response));
  } catch (e) {
    console.log("willGetPublicExperiment error", e);
    yield put(ExperimentsActions.setCurrentPublicExperiment(null));
  }
  yield put(UiActions.loading(false));
 
}


export function* willUploadFileOld(action:any) {
  const data = action.payload;
  console.log("getUploadUrl payload", data);
  const contentType = data.type
  console.log("getUploadUrl contentType:", contentType);

  yield put(UiActions.loading(true));
  yield put(UiActions.startUpload());
  try{
    const filename = uuidv4() + '/' + data.name 
    console.log("getUploadUrl filename:", filename);

    Object.defineProperty(data, 'name', {
      value: filename,
      writable: true
    });
  
    console.log("getUploadUrl payload after", data);

    let response = yield call(
      ExperimentsApi.getUploadUrl,
      filename as String,
      null as any,
      contentType as String
    );

    console.log("getUploadUrl response:", response);
    const uploadUrl = response["data"]["getUploadUrl"];
    console.log("getUploadUrl url:", uploadUrl);
    
    
   
    const uploadResult = yield call(() =>
    {
      let config = {
        onUploadProgress:  (progressEvent: any) => {
          // Do whatever you want with the native progress event
          console.log("getUploadUrl progressEvent:", progressEvent);
        },
       headers: {
          'Content-Type': contentType
        },
      };
     axios.put(uploadUrl,data, config as any)
      .then(response => console.log("getUploadUrl done:",response))
      .catch(error => console.log("getUploadUrl error:",error))
    }
    
    );

  
  } catch(e) {

    console.log("getUploadUrl error:", e);
  }

}


export function* willUploadFile(action: any) {
  yield put(UiActions.loading(true));
  yield put(UiActions.startUpload());

  const data = action.payload;
  console.log("getUploadUrl payload", data);
  const contentType = data.type

  const filename = uuidv4() + '/' + data.name 
    console.log("getUploadUrl filename:", filename);

    Object.defineProperty(data, 'name', {
      value: filename,
      writable: true
    });

    // GETUPLOADURL
    let response = yield call(
      ExperimentsApi.getUploadUrl,
      filename as String,
      null as any,
      contentType as String
    );

    console.log("getUploadUrl response:", response);
    const uploadUrl = response["data"]["getUploadUrl"];
    console.log("getUploadUrl url:", uploadUrl);

    // End of GETUPLOADURL

  let channel = yield call(async () => {
    try {
      return eventChannel(emitter  => {

        // file uploader

        let config = {
          onUploadProgress:  (progress: any) => {
            // Do whatever you want with the native progress event
            console.log("getUploadUrl progressEvent:", progress);
            emitter({ progress: progress.total > 0 ? progress.loaded / progress.total : -1 })
          },
         headers: {
            'Content-Type': contentType
          },
        };
       axios.put(uploadUrl,data, config as any)
        .then(response => 
          {console.log("getUploadUrl done:",response);
          emitter({ result: response, progress: 1 });
          emitter(END);
        })

        .catch( error => {console.log("getUploadUrl error:",error);
        emitter({ err: new Error('Upload failed') });
        emitter(END);
      }
        
        )

        return () => {
          //what to do here
        }
        // end of file uploader
       
      }, buffers.sliding(2))

      // console.log(`upload result 1 : ${data.url.name} , ${JSON.stringify(channel)}`)
      // const url= 'https://740820033840-idea-riale-backend-dev-contents-bucket.s3-eu-west-1.amazonaws.com/'+'private/'+localStorage.getItem('aws.cognito.identity-id.'+awsConfig.amplifyConfigure.Auth.identityPoolId)?.replace(/:/,'%3A')+'/'+data.url.name.replace(/ /g,'+')
      // return url
    }
    catch (e) {
      console.log('Amplify upload saga error', e)
    }
  })
  yield put(UiActions.loading(false));
  console.log("upload result: ", channel)
  while (true) {
    const { progress, err, result } = yield take(channel);
    console.log("getUploadUrl cycle progress:", progress);
    console.log("getUploadUrl cycle error:", err);
    console.log("getUploadUrl cycle result:", result);
    if (err) {
      console.log('UPLOAD_ERROR')
      // yield put(IdeaActions.setAppError(IdeaTypes.AppError.UPLOAD_ERROR, prev, {}));
      yield put(ExperimentsActions.setUploadedResorceUrl(null));
      return;
    }
    if (result) {
      const resourceUrl = `${configuration[stage]["resourcesBaseUrl"]}${filename}`
      console.log('getUploadUrl final resource url:', resourceUrl)
      yield put(UiActions.uploadSuccess())
      yield put(ExperimentsActions.setUploadedResorceUrl(resourceUrl));
      return channel;
    }
    yield put(UiActions.uploadUpdateProgress(progress * 100));

  }
}

export function* willSendCommand(action: any) {

  const data = action.payload;
  console.log("sendCommand payload:" , data);

  try {
    let response = yield call(
      ExperimentsApi.sendCommand,
      data.command,
      data.type
    );
    console.log("sendCommand result", response);
  } catch (e) {
    console.log("Amplify sendCommand error", e);
  }
}


