import { createSlice, PayloadAction, createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";

export const currentSlice = createSlice({
  name: 'ui_catalog',
  initialState: {
    activitiesRunning: {} as any,
    isAppointmentEditorOpen : false,
    initialData : null,
    isBackendErrorAlertModalOpen : [false as boolean,"" as string ]
  },
  reducers: {
    startActivityRunning: (state, action: PayloadAction<string>) => void (state.activitiesRunning[action.payload] = true),
    stopActivityRunning: (state, action: PayloadAction<string>) => void (delete state.activitiesRunning[action.payload]),
    openAppointmentEditor: (state: any, action: PayloadAction<any>) =>  
    void( state.initialData = action.payload, state.isAppointmentEditorOpen = true as any),
    closeAppointmentEditor:(state: any, action: PayloadAction<any>) =>  
    void(state.isAppointmentEditorOpen = false as any, state.initialData = null),

    openBackendErrorAlertModal: (state: any, action: PayloadAction<any>) =>  
    void( state.isBackendErrorAlertModalOpen = [true, action.payload]),
    closeBackendErrorAlertModal: (state: any, action: PayloadAction<any>) =>  
    void(state.isBackendErrorAlertModalOpen = [false, ""]),
  }
})

// Extract the action creators object and the reducer
export const { actions, reducer }: any = currentSlice
export const { startActivityRunning, stopActivityRunning } = actions

const activitiesRunningSelector = (state: any) => state.ui_catalog.activitiesRunning;
export const selectors = {
  activityRunningSelector: createDraftSafeSelector(
    (state: any) => state.ui_catalog.activitiesRunning,
    (_: any, currentActivity: string) => currentActivity,
    (activitiesRunning: any, currentActivity: any) => activitiesRunning[currentActivity] != undefined ? activitiesRunning[currentActivity] : false
  ),
  isAppointmentEditorOpen: (state: any) => state.ui_catalog.isAppointmentEditorOpen,
  getInitialData: (state: any) => state.ui_catalog.initialData,
  getBackendErrorAlertModalInfo: (state:any) => state.ui_catalog.isBackendErrorAlertModalOpen
}

