import React, { useState } from 'react'
import { Button, Progress, ButtonDropdown, 
  CardTitle, CardHeader, CardBody, ModalHeader, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem, 
  FormGroup, Label, Input,  NavItem, Nav, NavLink, 
  Container, Col, Row,  Card, ModalFooter } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { useParams} from 'react-router-dom'
import moment from 'moment';
import { Header } from '../header';
import 'react-block-ui/style.css';
import { push } from 'connected-react-router';
import RialeTimelineViewer from '../components/RialeTimelineViewer';
import {tracks} from '../components/Timeline/FakeItems';
import classnames from 'classnames';
import ReactTooltip from "react-tooltip";

import * as UiSelectors from '../store/selectors/ui';
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import { selectors as AuthSelectors } from '../store/slices/auth'

import { Content } from '../components/Content';

import { useTranslation } from 'react-i18next';
const defTimelineStart = moment().startOf("day").add(10,"hours")
const defTimelineDuration = null;


export const PublicExperiment: React.FC = () => {
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)
  const isLogged = useSelector(AuthSelectors.isLogged)


  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
 
  // ---------------------------------------------
   
  let { id } = useParams() as any;
  console.log("params:", id);
  const experiment = useSelector(ExperimentsSelector.getCurrentPublicExperiment);
  console.log("Valore di current experiment:", experiment);
  // ---------------------------------------------
  const dispatch = useDispatch();
 
  
  React.useEffect(() => {
    console.log("PublicExperiment Page on id:", id);
    dispatch(ExperimentsActions.willGetPublicExperiment(id));
  }, [])
 
  const timelineStart= null
  const timelineDuration= defTimelineDuration

  
  const [activeTab, setActiveTab] = useState('0');
  

  const toggleNav = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

const dropDownExperiments= experiment &&  experiment.sections.map((value: any, i:number) => {
  return (
    <React.Fragment key={i}>
    <NavItem key={i} data-for={`tab_${i}`} data-tip={`${value.description}`}>
      <NavLink
            className={classnames({ active: activeTab === `${i}` })}
            onClick={() => { toggleNav(`${i}`); }}
          >
            {value.title}{'   '}
      </NavLink>
    </NavItem>
    <ReactTooltip id={`tab_${i}`}/>
    </React.Fragment>
  )})

  return (
    <>
    <div className="App">
      <Header className="mb-0 text-white" section="Timeline" showMenu={false} />
      <Content fluid={true} className="pt-3">
       <Row className="m-0" style={{ height: 'calc(100% - 22px)' }}>
       <Col xs="12" style={{ height: '100%' }}>
       <Card className="mb-4" style={{ height: '100%', borderColor: "#007bff" }}>
       <CardHeader style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                paddingBottom: 0,
                color: 'white'

              }}>
                <CardTitle  tag="h5"><span  style={{marginBottom:10}} className="pull-left"> { experiment==null ? "Caricamento in corso..." : experiment.title}</span>
                
               { experiment!=null && experiment.clonable &&
                <Button className="pull-right"  style={{ height: 34, marginRight: 12, marginBottom: 10, borderWidth: 1, borderColor: 'white', borderStyle: 'solid', borderRadius: 4 }} color="primary" onClick={() => 
                  { 
                    if (isLogged) { 
                    dispatch(ExperimentsActions.willClonePublicExperiment(
                {experiment:id, owner: experiment.owner,
                title : `Copia di ${experiment.title}`, description: `${experiment.description}`})) 
                dispatch(push(`/timeline/`));
              }
              else{
                dispatch(push(`/login/?public_resource=${id}`));
              }


              }
                }>{t('clona').toUpperCase()}
                </Button>
              }
                   </CardTitle>
                </CardHeader>
                <CardBody>

               
      <div>
      <Nav tabs>
          {dropDownExperiments}
      </Nav>
    </div>
   
    {(activeTab && experiment && experiment.sections[activeTab]) ? (
      
      <div>
        <Row>
          <Col>
          {/* 
          <Card body className="text-center">
          <CardHeader tag="h4">{sections[activeTab].title}: {sections[activeTab].description}</CardHeader>
          </Card>
          */}
          </Col>
        </Row>
       { experiment!=null && 
      <RialeTimelineViewer items={experiment.sections[activeTab].items} 
                                    tracks={tracks} 
                                    startDateTime={timelineStart}
                                    // duration={timelineDuration}
                                    title={experiment.sections[activeTab].title}
                                    canEdit={false}
                                    activeTab={activeTab}
                                    />
      }
                                    </div>
    ):(<></>)}
      <div>
        
      {/* <Footer /> */}
    </div>
           </CardBody>
         </Card>
         </Col>
       </Row>
    </Content>
    </div>
   </>
  );
}