import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datetime/css/react-datetime.css";
import App from './App';
import reportWebVitals from './reportWebVitals';

// css from riale-frontend
import 'rc-slider/assets/index.css'
import './components/Timeline/react-datetime.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/Timeline/react-image-lightbox.css';  
import './components/react-video-sync.css'; 
import './components/react-player.css';
import './components/resizable_modal.css';
//  ---------------------

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// seutp i18n ---------------
import i18n from './i18n';


var lng:any = (window as any).navigator['userLanguage']|| (window as any).navigator.language;
if(lng !== 'it-IT'){
    i18n.changeLanguage('en-US');
}else{
    i18n.changeLanguage(lng);
}
// ---------------------

// i comandi 'console.log' vengono globalmente disabilitati in caso di
// modalità della applicazione in produzione (e non in sviluppo)
if (process.env.NODE_ENV !== "development")
    console.log = () => {};

    
ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
