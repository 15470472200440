import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {FaEdit, FaPlusCircle} from "react-icons/fa";
import {IoIosCloseCircleOutline } from "react-icons/io"
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import moment from 'moment';


const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: 'var(--tree-view-color)',
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
     
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    
    group: {
      marginLeft: 0,
      '& $content': {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'left',
      padding: theme.spacing(0.5, 0),
    },
     
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'left',
      padding: theme.spacing(0.5, 0),
    },
     
  }));
  


const getTrackTreeView = (props,items, track) => {
       
        items.sort((a,b)=> { return (moment(a.start_time).isBefore(moment(b.start_time)) ? -1 : 1)});

      return items.map((item,index) => {return (item.track!=track.id ? null :
        <TreeItem nodeId={`${item.id}`} 
        onLabelClick={()=> props.onItemSelected(moment(item.start_time,"DD/MM/YYYY hh:mm:ss"))}
        label={
        <span style={{
            display: 'flex',
            marginTop:"10px",
            alignItems: 'space-between',
            flexDirection: "row",
            justifyContent:"space-between"}}>

                {`${moment(item.start_time).format("DD/MM/YYYY HH:mm:ss")} - ${item.title}`} 
                { props.canEdit &&
                <IconButton 
               
                style = { { flex:"flexEnd", marginRight:"10px" , verticalAlign: 'center' }}
                onClick={()=> props.onItemEditRequest(item.id,null, moment(item.start_time,"DD/MM/YYYY hh:mm:ss"))}>
                    <IconContext.Provider style={{verticalAlign: 'middle'}} 
                    value={{ color: `black`,size: "0.5em"}}>
                       <FaEdit data-place="top" data-tip={props.t('tl:Modifica')} 
                       data-for="changeItemTooltip"  />
                    </IconContext.Provider>
                </IconButton>
        }
        
        <ReactTooltip id="changeItemTooltip"/>
         
            </span>
            }  
            
            key={index}/> 
        )})
}


function RialeItemsNavigatorNT(props) {
  //const classes = useTreeItemStyles();
  const {title, tracks,items, height, visible, currentPositionDate, onClosePanel,t} = props;

  return (
    visible &&
    (
    <div> 
      <h4>
        <span
            className="handle"
            style={{
            cursor:"move",
            color:"#007bff",
            width:"100%",
            display: 'flex',
            justifyContent : 'center',
            }}>
            <b style={{marginLeft:'auto', marginTop: '10px'}}>{t("tl:index_of")} {title}</b>
            <IconButton 
                style = {{  
                verticalAlign: 'top' , marginLeft:'auto'}}
                 
                onClick={()=> {onClosePanel()}}>
                    <IconContext.Provider style={{verticalAlign: 'top'}} 
                    value={{ color: "#007bff",size: "1.0em"}}>
                       <IoIosCloseCircleOutline data-place="top" 
                       data-for="itemNavigatorTooltip"
                       data-tip={ t("tl:close_panel")}  
                       />
                    </IconContext.Provider>
                </IconButton>
            </span>

            </h4>

            

            <div  style={{'overflowY': 'auto', 'height': height-65}}>
        <TreeView
            
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
    {
        
       tracks.map((track,index) => {
        const count = items.filter((item) => item.track===track.id).length;
        return (
            <TreeItem nodeId={`${track.id}`} label={
                <span style={{
                                 display: 'flex',
                                 justifyContent : 'space-between',
                                 marginTop: "10px",
                                 alignItems: 'stretch'}}>
                    <b> {track.title} ({count}) </b>

                    { props.canEdit &&
                <IconButton 
                style = {{ flex:"flexEnd", marginRight:"10px" , verticalAlign: 'top' }}
                 
                onClick={()=> props.onItemCreateRequest(track.id, currentPositionDate, null)}>
                    <IconContext.Provider style={{verticalAlign: 'top'}} 
                    value={{ color: `black`,size: "0.5em"}}>
                       <FaPlusCircle data-place="top" 
                       data-for="itemNavigatorTooltip"
                       data-tip={ t("tl:Aggiunta_item", {itemType:track.type}) }  
                       />
                    </IconContext.Provider>
                </IconButton>
                    }
        
                </span>
                } key={index}>
              { getTrackTreeView(props,items,track)}
            </TreeItem>
       )
       })    

    }
   </TreeView>             
    </div>
     <ReactTooltip id="itemNavigatorTooltip"/> 
    </div>
  )
  );
}


const RialeItemsNavigator = withTranslation()(RialeItemsNavigatorNT);
export default RialeItemsNavigator;