export const configuration: any = {

  production: {
    publicExperimentEndpoint: "https://jlbcew97h4.execute-api.eu-west-1.amazonaws.com/production/public/",
    baseUrl: "https://riale.ideab3.it",
    resourcesBaseUrl: "https://riale.ideab3.it/resources/",
    catalog:"https://riale.ideab3.it/resources/catalog.json",
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_Rl7JUZZQf', //'eu-west-1_rmCpoAsRb',
      userPoolWebClientId: '6g864i59ik5oj5ie79mhspe6rg', //'10mml6g6mru35qk67tkskb8tl0',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    },
    'aws_appsync_graphqlEndpoint': 'https://uszydtqjffeu7ojjcumq3n4ddu.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  } ,
  beta: {
    publicExperimentEndpoint: "https://8wk96e8ote.execute-api.eu-west-1.amazonaws.com/beta/public/",
    baseUrl: "https://beta.riale.ideab3.it",
    resourcesBaseUrl: `https://beta.riale.ideab3.it/resources/`,
    catalog : "https://beta.riale.ideab3.it/resources/catalog.json",

    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_2mBdtDJgw', //'eu-west-1_rmCpoAsRb',
      userPoolWebClientId: '1ki4foelgc573rt0k7jdf6bupf', //'10mml6g6mru35qk67tkskb8tl0',
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false
    },
    'aws_appsync_graphqlEndpoint': 'https://bjblsepngve75bvyp7b7tet674.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  } ,
  
  dev: {
    publicExperimentEndpoint: "https://i1wgp6dy3k.execute-api.eu-west-1.amazonaws.com/dev/public/",
    baseUrl: "https://dev.calendar.riale.ideab3.it",
    resourcesBaseUrl: "https://dev.calendar.riale.ideab3.it/resources/",
    catalog:"https://dev.calendar.riale.ideab3.it/resources/catalog.json",
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_5V9fbg3OY', //'eu-west-1_rmCpoAsRb',

      userPoolWebClientId: '747aot0sldjrv9uj7r1gt69pjr', //'10mml6g6mru35qk67tkskb8tl0',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false

    },
    'aws_appsync_graphqlEndpoint': 'https://2yz4fvqinzgjrmnaa6gds33p7i.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  }
}