import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap'
import * as AuthApi from './api/auth'
import { selectors as AuthSelectors } from './store/slices/auth'
import { actions as AuthActions } from './store/slices/auth'
import { selectors as ProfileSelectors } from './store/slices/profile'
import { Link } from "react-router-dom";
export const Header = ({ className, section, showMenu = false }: any) => {

  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const dispatch = useDispatch()

  const _section = section != undefined ? section : "Scheduler"
  console.log("Dati dell'utente:", userAttributes);
  return (
    <Navbar className={className} color="primary" light expand="md">
      <NavbarBrand className="text-white font-weight-bold" href="/">RIALE - {_section}</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        {showMenu ? (
          <Nav className="mr-auto" navbar>
            {isAuthenticated &&
              <>
                <NavItem>
                  <NavLink className="text-white" href="/dashboard/">Dashboard</NavLink>
                </NavItem>
              </>
            }
          </Nav>
        ) : (
            <Nav className="mr-auto" navbar>
            </Nav>
          )}

        <Nav navbar>

          {isAuthenticated ? (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="text-white" nav caret>{userAttributes.given_name} {userAttributes.family_name}</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem> 
                <NavLink className={"text-primary"} 
                style={{ color: 'white', textDecoration: 'none'}} 
                tag={Link} to={"/dashboard"}>
                  Dashboard
                </NavLink>
                </DropdownItem> 
                <DropdownItem>
                 <NavLink className={"text-primary"} 
                style={{ color: 'white', textDecoration: 'none' }} 
                tag={Link} to={"/edit_profile"}>
                  Modifica profilo
                </NavLink>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  dispatch(AuthActions.willLogoutUser());
                }}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
              <NavItem>
                <NavLink className="text-white" href="/login/">Login</NavLink>
              </NavItem>
            )
          }
        </Nav>

      </Collapse>
    </Navbar>
  )
}