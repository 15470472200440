import React from 'react';

import {
  Container, Row, Col, Card, CardText, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input
} from 'reactstrap';


 
// import catalog from '../catalog/catalog.json'
import { SearchBar } from './SearchBar';
import { push } from 'connected-react-router';
import { Topic } from './Topic';
import { selectors as CatalogSelectors } from '../store/slices/catalog'
import { useDispatch, useSelector } from "react-redux";
import { actions as CatalogActions } from "../store/slices/catalog";
/* 
N aree tematiche (titolo) 
  N esperimenti o Percorsi (sequenza ordinata di esperimenti)
  ID, tags, titolo, descrizione (max 2 righe), 
  nome laboratorio <- organizzazione (sottotitolo), 
  gradi scolastici [primaria...università], opportunita' culturale Laboratorio,  
  fasi dell'esperimento, 
  tempo (60n min.) , frequenza mensile, ricercatori, 
  periodo di erogazione, link a timeline master,  link a dettagli 
  (con calendario con i prossimi appuntamenti di quell'esperimento, visibile a tutti)
*/

export const Catalog = () => {
  const dispatch = useDispatch();
  const catalog: any = useSelector(CatalogSelectors.getFilteredCatalog(null));
  const filteredIds: any = useSelector(CatalogSelectors.getFilteredIds);
  console.log('with catalog: ', catalog)
  const [leftTopics, setLeftTopics] = React.useState([]);
  const [rightTopics, setRightTopics] = React.useState([]);

  const getTopicLength = (topic:any) => {
    let res = 0;
    for (let i=0;i<topic.esperimenti.length;i++)
    {
      if (topic.esperimenti[i]["type"]!="path")
       {res = res +1}
       else {
         res = res + topic.esperimenti[i]["esperimenti"].length
       }
    }
    return res;
  }

 
 
  const renderCatalog = () =>
  {
    console.log('on useEffect catalog:', catalog);
      const _leftTopics: any = [];
      const _rightTopics: any = [];

      let leftTopicsCount = 0;
      let rightTopicsCount = 0;

      for (const topic of catalog) {
        console.log("Topic esperimenti:", topic.esperimenti);
        if (leftTopicsCount <= rightTopicsCount) {
          _leftTopics.push(topic);
          leftTopicsCount += getTopicLength(topic);
        } else {
          _rightTopics.push(topic);
          rightTopicsCount += getTopicLength(topic);

        }
      }
      setLeftTopics(_leftTopics);
      setRightTopics(_rightTopics);
  }

  React.useEffect(() => {
    console.log("FilteredIds nullo -> catalog:", catalog);
    if (filteredIds==null)
    renderCatalog();
  } ,[catalog]);

  //Order topics in cols
  React.useEffect(() => {

    console.log('filteredIds: ', filteredIds)
    if (catalog != undefined) {
        renderCatalog();
      }

    return () => { }
  }, [filteredIds])

  return (
    <Container fluid>
      <Row style={{ height: 70 }}>
        <Col className="d-flex justify-content-center" >
         <SearchBar/>
        </Col>
      </Row>
      <Row>
        <Col xs="6">
          <div >
            {leftTopics.map((topic: any) => <Topic data-tip="Area tematica" key={topic.id} content={topic} />)}
         
          </div>

        </Col>
        <Col xs="6">
          <div>
            {rightTopics.map((topic: any) => <Topic  key={topic.id} content={topic} />)}
          </div>
       
        </Col>
      </Row>
    
    </Container>
  )
}