import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';


export const listDesign = async () => {

  const query = loader('../graphql/listDesign.gql');
  console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query });
    return result

  } catch (error) {
    throw error
  }
}

export const getDesign = async (id: string) => {

  const query = loader('../graphql/getDesign.gql');
  console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: {id: id} });
    return result

  } catch (error) {
    throw error
  }
}