import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import events from '../../demo_events'

const getAppointmentIndexById = (appointments: any, id: any) =>
{
  if (id==null) return -1;

  //console.log(`Ricerco ${id} su:`, appointments);
  return appointments.findIndex( (appEl: { id: any; }) => appEl.id===id);
}

export enum Appointmentstate {NOT_FOUND="not found", 
                              CREATE="create",
                              BOOKED="booked", 
                              UNBOOKED="unbooked"};


export const getAppointmentById = (appointments: any, id: any) =>
{
  //console.log(`Ricerco ${id} su:`, appointments);
  const index = getAppointmentIndexById(appointments,id);
  if (index<0) return null;
  else return appointments[index];
}


export const appointmentsSlice = createSlice({
    name: 'appointments',
    initialState: {
      events : [],
      sessionResources: {},
      eventsLoaded : false
    }
  ,
    reducers: {
      willLoadAppointments: (state, action: PayloadAction<any>) => state,
      willLoadAppointmentsByTitle: (state, action: PayloadAction<any>) => state,
      willCreateAppointment: (state, action: PayloadAction<any>) => state,
      willUpdateAppointment: (state, action: PayloadAction<any>) => state,
      willDeleteAppointment: (state, action: PayloadAction<any>) => state,
      willConfirmAppointment: (state, action: PayloadAction<any>) => state,
      willBookAppointment: (state, action: PayloadAction<any>) => state,
      willUnbookAppointment: (state, action: PayloadAction<any>) => state,
      willLoadResourcesForSession: (state, action: PayloadAction<any>) => state,

      confirmAppointment: (state: any, action: PayloadAction<any>) => 
      {
        
        const index = getAppointmentIndexById(state.events, action.payload.appointment.id);
        console.log(`confirmAppointment called su app ->${action.payload.appointment.id} index:${index}`, action.payload);
        if (index>=0)
         {
           console.log(`Aggiorno lo stato di appointment n.${action.payload.appointment.id}`);
           (state.events[index] as any).state = Appointmentstate.BOOKED;
           (state.events[index] as any).attendee = action.payload.attendee;
          }
      },

      setAppointmentsLoaded: (state: any, action: PayloadAction<any>) =>
      {
        state.eventsLoaded = action.payload;
      },

      cancelAppointment: (state: any, action: PayloadAction<any>) => 
      {
        
        const index = getAppointmentIndexById(state.events, action.payload.id);
        console.log(`cancelAppointment called su app ->${action.payload.id} index:${index}`, action.payload);
        if (index>=0)
         {
           console.log(`Aggiorno lo stato di appointment n.${action.payload.id}`);
           (state.events[index] as any).state = Appointmentstate.UNBOOKED;
           (state.events[index] as any).attendee = "";
          }
      },

      editAppointment: (state: any, action: PayloadAction<any>) =>  
        {
          const appointment = action.payload;
          console.log(`Sto cercando l'appuntamento n.${appointment.id}`);
          console.log("Lista corrente:", state.events[0]);
          let index = getAppointmentIndexById(state.events,appointment.id);
          //state.events.findIndex( (appEl: { id: any; }) => appEl.id===appointment.id);

          if (index>=0)
          {
            state.events[index] = appointment;
            console.log(`Ho trovato l'appuntamento con id=${appointment.id}`);
          }
          
          else {
            console.log("Appuntamento nuovo da creare", action.payload);
            state.events = [
              ...state.events,
              action.payload
            ];
          }
         
        } , 

        didAppointmentsloaded:(state: any, action: PayloadAction<any>) =>  
        {
          state.events = action.payload;
          state.eventsLoaded = true;
        },

        didSessionResourcesLoaded:(state: any, action: PayloadAction<any>) =>  
        {
          
          const result = action.payload;
          console.log("didSessionResourceloaded payload:", result);
          const sessionId = result["id"];
          const videoItems =  result["items"];
          state.sessionResources[sessionId]= videoItems
        },

      deleteAppointment:(state: any, action: PayloadAction<any>) =>  
      {
        console.log(`Richiesta cancellazione appuntamenti n.${action.payload.id}`);
        state.events = state.events.filter((event: { id: any; })=> event.id!=action.payload.id);
    
      }
      
    }
  })
  
  export const { actions, reducer }: any = appointmentsSlice
 
  export const selectors = {
    getAppointments: (state: any) => {
      //console.log("Richiamato getAppointments selector");
      return state.appointments.events},
    
    getSessionResources: (state: any) => {
        return state.appointments.sessionResources},
    
    areAppointmentsLoaded: (state: any) => {
        return state.appointments.eventsLoaded},


    getNextValidId: (state: any) => Math.floor(Math.random()*1000) + 1,

    getAppointmentstate : (id :any) =>  (state: any) => 
    {
       
      const appointment = state.appointments.events.filter((el: any)=> el.id===id );
      const result = (appointment==null || appointment.state ==null)  ? Appointmentstate.NOT_FOUND.valueOf() : appointment.state;
      console.log(`Stato dell'evento ${id}:${result}`);
      return result;
    }
  }
  