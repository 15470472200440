import * as React from 'react';
import {useState, useEffect}  from 'react';

import {
  Card, CardBody,
  CardTitle, CardSubtitle, Button,
  Container, FormGroup, Input, Label, FormFeedback,
  Col, Row
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from "react-router-dom";
 
import { actions as AuthActions, selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { ActivityButton } from '../components/ActivityButton'
import { gradi, classiConcorso } from "./constants"

import ReactMarkdown from 'react-markdown'
 
const RegistrationSchema = Yup.object().shape({
  school: Yup.string()
    .min(2, 'Campo troppo corto!')
    .max(50, 'Campo troppo lungo!')
    .required('Campo obbligatorio'),

  school_address: Yup.string()
  .min(2, 'Campo troppo corto!')
  .max(50, 'Campo troppo lungo!')
  .required('Campo obbligatorio'),

   subject: Yup.string()
    .min(2, 'Campo troppo corto!')
    .max(50, 'Campo troppo lungo!')
    .required('Campo obbligatorio'),

    grade: Yup.string()
    .min(2, 'Campo troppo corto!')
    .max(50, 'Campo troppo lungo!')
    .required('Campo obbligatorio'),
    
});


export const RegistrationForm = (props:any) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const registrationError = useSelector(AuthSelectors.getRegisteredError);
  const isLogged = useSelector(AuthSelectors.isLogged)
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const currentPrivacyVersion = useSelector(AuthSelectors.getCurrentPrivacyVersion)
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)
  const newsletterSubscribe = useSelector(AuthSelectors.getNewsletterSubscribe)
  const [privacyContentMD, setPrivacyContentMD] = useState("Informativa sulla privacy in caricamento...");


  const renderGradeOptions = () =>
  {
    console.log("gradi scolastici:", gradi);
    return gradi.map(
      (e:any,i:number) => <option key={e["code"]} value={e["code"]}>{e["description"]}</option>);
  }

  const renderClassiConcorsoOptions = () =>
  {
    return classiConcorso.map(
      (e:any,i:number) => <option key={e["code"]} value={e["code"]}>{`${e["code"]} - ${e["description"]}`}</option>);
  }
 
  React.useEffect(() => {
   
    const readPrivacy = async () =>
          {
            fetch(currentPrivacyVersion.url
              )
            .then(response => {
              return response.text()
            })
            .then(text => {
               setPrivacyContentMD(text)
              })
            };
      
    readPrivacy();
    
    return () => { }

  }, [currentPrivacyVersion])


  React.useEffect(() => {
    console.log('effect registrationError value changed: ', registrationError);
    return () => { }
  }, [registrationError])

  const formTitle:string = (props.edit ?  "Modifica Profilo" : "Registrazione Utente" )
  const confirmButtonMsg =  (props.edit ?  "Conferma le modifiche" : "Completa la registrazione" )

  return ( !isLogged ? <Redirect to="/login" /> :
    <Container>
      <Card className="mt-3 mt-lg-10">
          <CardBody>
            <CardTitle tag="h5" className="text-center">{formTitle} ({userAttributes.email})</CardTitle>
            { !props.edit &&
            <CardSubtitle tag="h6" className="mb-2 text-muted text-center">Gentile {userAttributes.given_name}, ti preghiamo di inserire i seguenti dati per completare la fase di registrazione</CardSubtitle>
            }
            <Formik
              initialValues={{
                school: currentRegistrationProfile ? currentRegistrationProfile.school : "",
                school_address: currentRegistrationProfile ? currentRegistrationProfile.schoolType : "",
                subject : currentRegistrationProfile ? currentRegistrationProfile.schoolMatter : "",
                grade: currentRegistrationProfile ? currentRegistrationProfile.schoolLevel : "",
                privacyAccepted: currentRegistrationProfile && currentRegistrationProfile.privacy && props.edit,
                newsLetterRejected: !newsletterSubscribe
              }}
              validationSchema={RegistrationSchema}
              validateOnBlur={true}
              onSubmit={values => {
                console.log('in onsubmit with: ', values)
                dispatch(AuthActions.willRegisterUser({ 
                          school: values.school, 
                          schoolType: values.school_address, 
                          schoolMatter: values.subject, 
                          schoolLevel: values.grade,
                          privacy: values.privacyAccepted,
                          // la sottoscrizione è la negazione del rejected
                          subscribeEmail: !values.newsLetterRejected
                          }));
              }}
            >
              {({ errors, touched, setFieldValue,handleChange,  values }) => (
                <Form>
                  <FormGroup>
                    <b><Label for="school">Nome dell'Istituto scolastico</Label></b>
                    <Input invalid={errors.school && touched.school ? true : false} type="text" name="school" id="school" 
                    placeholder="Inserire il nome del proprio Istituto scolastico (Es: Istituto Alessandro Volta)" autoComplete="school" tag={Field} />
                    {errors.school && touched.school ? (
                      <FormFeedback>{errors.school}</FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <b><Label for="school_address">Tipologia di Istituto</Label></b>
                    <Input invalid={errors.school_address && touched.school_address ? true : false} type="text" name="school_address" id="school_address"
                     placeholder="Inserire la tipologia del proprio Istituto scolastico (Es:Liceo scientifico delle scienze applicate)" 
                    autoComplete="current-school_address" tag={Field} />
                    {errors.school_address && touched.school_address ? (
                      <FormFeedback>{errors.school_address}</FormFeedback>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <b><Label for="subject">Materia insegnata</Label></b>
                    <Input  
                    type="select" name="subject" id="subject"
                     value={values.subject}
                    autoComplete="current-subject" tag={Field} onChange={handleChange}
                        component={"select"}>
                      {renderClassiConcorsoOptions()}
                      </Input>
                   
                  </FormGroup>

                  <FormGroup>
                    <b><Label for="grade">Grado scolastico</Label></b>
                    <Input  
                    type="select" name="grade" id="grade"
                     value={values.grade}
                    autoComplete="current-grade" tag={Field} onChange={handleChange}
                        component={"select"}>
      
                      {renderGradeOptions()}
                      </Input>
                  </FormGroup>

                  <FormGroup>
                  <Label>
                      {
                    <Input type="checkbox" name="newsLetterRejected" id="newsLetterRejected"
                     placeholder="Scegliere se accettare la sottoscrizione alle newsletter di Riale" 
                    autoComplete="current-newsLetterRejected" tag={Field} />
                      }
                        Non desidero sottoscrivere le newsletter con le novità sull'offerta formativa di RIALE. Sarà mia cura verificare periodicamente la presenza di nuovi contenuti formativi sulla piattaforma.                      </Label>
                  </FormGroup>
               { !props.edit &&
                  (<FormGroup>
                      <b><Label for="privacyContent">Informativa sulla privacy</Label></b>
                                                <div style={
                              {
                              border: '1px solid black'
                              }
                            } id="privacyContent">
                            <ReactMarkdown children={privacyContentMD}/>
                      </div>
                    </FormGroup>
                      )
                      }

                  {  !props.edit && 
                  <FormGroup>
                  <Label>
                      {
                    <Input type="checkbox" name="privacyAccepted" id="privacyAccepted"
                     placeholder="Leggere e accettare la normativa sulla privacy" 
                    autoComplete="current-privacyAccepted" tag={Field} />
                      }
                        Ho letto l'informativa sulla privacy e ne accetto le condizioni
                      </Label>
                  </FormGroup>
                }

                  <ActivityButton  disabled={errors.school || errors.school_address || !values.privacyAccepted} type="submit" name="register" color="primary" block>{confirmButtonMsg}</ActivityButton>
                  {!props.edit ?
                  <Button name="logout" color="primary" block 
                  onClick={()=>{ dispatch(AuthActions.willLogoutUser());}
                  
                  }>Logout</Button>
                  :

                  <Button name="cancel" color="primary" block 
                  onClick={()=>{ history.push("/");}
                  }>Annulla</Button>

                 }
                </Form>
              )}
            </Formik>
          </CardBody>
      </Card>
    </Container>
  )
}