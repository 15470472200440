import React, { useEffect } from 'react';
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import { Header } from '../header'
import { SideBar } from '../components/SideBar'
import { Content } from '../components/Content';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import {selectors as AppointmentsSelector, actions as AppointmentActions,
  Appointmentstate } from '../store/slices/appointments'
  
import { selectors as CatalogSelector} from '../store/slices/catalog'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import moment from 'moment';
import {
  Card, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'


export const DashboardPage = () => {

  const dispatch = useDispatch();
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const appointments = useSelector(AppointmentsSelector.getAppointments);
  const experiments = useSelector(CatalogSelector.getExperiments)


  React.useEffect(() =>{

    dispatch( AppointmentActions.willLoadAppointments(
      {"start" : "1900-01-20T10:00:00.000Z","end" : "2050-01-20T10:00:00.000Z"}
    ));

    console.log("dashboard: called willLoadAppointments");
  }, []);

  console.log("dashboard appointments:", appointments);

  const startStopFormatter = (cell:any,row:any) =>
{
  //return moment(cell).format('MMMM Do YYYY, h:mm:ss a');
  //return moment(cell).format('YYYY/MM//DD - hh:mm A');
  return moment(cell).format('YYYY/MM//DD - hh:mm A');
}

 const experimentDetailsLinkFormatter = (cell:any,row:any) =>
 {
   console.log("Passo la riga:", row);
   console.log("Passo la cella:", cell);
   return (
  <a href={`/experiment/${row.experiment_id}`}><FontAwesomeIcon icon={faChevronCircleRight} style={{ fontSize: 18 }} color="#007bff" /></a>
   )
 }

 const ptzPanelLinkFormatter  = (cell:any,row:any) =>{
   console.log("Cella:", cell);
   return <FontAwesomeIcon icon={faChevronCircleRight} 

   onClick= { (ev) => {
    console.log("CLICK!!!");
    const win = window.open('/live/12345000','winname','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=250');
    //const win = window.open('/live/123456','Controllo PTZ', "resizable,height=600,width=670");
   
    win!.focus();
  }}

   style={{ fontSize: 18 }} color="#007bff" 
   /> 

}
 

  const columns = (next:boolean) => [

    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'experiment_id',
      text: 'experiment_id',
      hidden: true
    },
    {
      dataField: 'startDate',
      text: 'Inizio',
      headerStyle: () => {
        return { width: '18%'};
      },
      formatter : startStopFormatter,
      sort: true
    },
  {
    dataField: 'stopDate',
    text: 'Fine',
    headerStyle: () => {
      return { width: '18%'};
    },
    formatter : startStopFormatter,
    sort: true
  }, 

  {
    dataField: 'title',
    text: 'Titolo',
    sort: true
  }, 

  {
    dataField: 'lab',
    text: 'Laboratorio',
    sort: true
  },

  {
    dataField: 'liveLink',
    text: '',
    formatter: (next ? ptzPanelLinkFormatter : experimentDetailsLinkFormatter),
    headerStyle: () => {
      return { width: '5%'};
    },
  }

];

const paginationOptions = { 
  sizePerPageList: [ {
    text: '3th', value: 3
  }
],pageStartIndex: 1}


  console.log("dashboard appointments:", appointments);

  const nextAppointments = appointments.filter((app:any, i:number) => {
    console.log("Cerco esperimento del catalogo con id:", app);
    return app.state==Appointmentstate.BOOKED && 
            // filtro nel caso siano state omessi degli esperimenti dal catalogo corrente
          experiments!=null &&  experiments[app["title"]]!=null && 
           app.attendee==userAttributes.sub && moment(app["stopDate"]).isAfter(moment.now())
  }).map((app:any, i:number) => {
    return {"id": app["id"], "startDate" : `${app["startDate"]}`, "stopDate": `${app["stopDate"]}`,
    "title" : (experiments[app["title"]] as any)["titolo"],
    "experiment_id" : app["title"],
     "lab": app["lab"]}});

  const lastAppointments = appointments.filter((app:any, i:number) => {
      return app.state==Appointmentstate.BOOKED && 
              // filtro nel caso siano state omessi degli esperimenti dal catalogo corrente
              experiments!=null &&  experiments[app["title"]]!=null && 
             app.attendee==userAttributes.sub && !moment(app["stopDate"]).isAfter(moment.now())
    }).filter((app:any, i:number)=>{ return (experiments[app["title"]]as any)!=null }).map((app:any, i:number) => {
      console.log("Last app id:",app["id"]);
      return {"id": app["id"], "startDate" : `${app["startDate"]}`, "stopDate": `${app["stopDate"]}`,
      "experiment_id" : app["title"],
      "title" : (experiments[app["title"]] as any)["titolo"],
       "lab": app["lab"]}});
  
  //const products = [{name:"stefano"}, {name:"marco"}];
  console.log("dashboard myappointments:", nextAppointments);

  return (
    <>
      <Header className="mb-0 text-white" section="Dashboard" showMenu={false} />
      <SideBar active="dashboard"/>
      <Content>
        <Card className="m-4" style={{
          borderColor: "#007bff",
          height: 'calc(50% - 30px)'
        }}>
          <CardHeader data-tip="Area tematica" style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            paddingBottom: 0,
            color: 'white'

          }}>
            <CardTitle tag="h5">Esperimenti Live</CardTitle>
          </CardHeader>
          <CardBody>
            
          <BootstrapTable bootstrap4 keyField='id' data={ 
            nextAppointments
           } 
          columns={ columns(true) } pagination={ paginationFactory(paginationOptions)}/>
            
          </CardBody>
        </Card>

        <Card className="m-4" style={{
          borderColor: "#007bff",
          height: 'calc(50% - 40px)'
        }}>
          <CardHeader data-tip="Area tematica" style={{
            backgroundColor: "#007bff",
            borderColor: "#007bff",
            paddingBottom: 0,
            color: 'white'

          }}>
            <CardTitle tag="h5">Ultime Attività</CardTitle>
          </CardHeader>
          <CardBody>
            
          <BootstrapTable bootstrap4 keyField='id' data={ 
            lastAppointments
           } 
          columns={ columns(false) } pagination={ paginationFactory(paginationOptions)}/>
          
          </CardBody>
        </Card>        
      </Content>


    </>
  )
}