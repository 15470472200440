import { Formik, Form, Field, ErrorMessage, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import { CardHeader, CardBody, Button, Input, Label, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, NavItem, TabContent, TabPane } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ScrollArea from 'react-scrollbar';
import { useState } from 'react';
import { TLAComponent } from './TLAComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { selectors as DesignSelectors, actions as DesignActions } from '../store/slices/design'


const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : '#f5f5f5',
  padding: 0,
  display: "flex",
  margin: 0,
});

const getItemStyle = (isDragging: any, draggableStyle: any) => ({

  userSelect: 'none',
  padding: 0,
  margin: 0,
  background: isDragging ? 'lightgreen' : '#f5f5f5',
  ...draggableStyle,
});

export const TLAList = ({ readOnly }: any) => {

  // const currentDesign = useSelector(currentDesignSelectors.getCurrentDesign)
  const currentDesign = useSelector(DesignSelectors.getCurrentDesign);
  const dispatch = useDispatch();
  console.log('with currentDesign: ', currentDesign);

  const onDragEnd = (result: any) => {
    console.log('on drag end with: ', result)

    if (result.type == "ACTION") {
      dispatch(DesignActions.moveActionComponent({
        source: result.source.index,
        destination: result.destination.index,
        tlaSource: result.source.droppableId,
        tlaDestination: result.destination.droppableId
      }));
    } else if (result.type == "TLA") {
      dispatch(DesignActions.moveTLAComponent({ source: result.source.index, destination: result.destination.index }));
    }
  }

  return (
    <div>

      <Card fluid>
        <CardHeader>
          <div style={{ float: "left" }}>TLA</div>
          <div style={{ float: "right" }}>{!readOnly && <Button size="md" color="primary"><FontAwesomeIcon icon={faPlus} onClick={() => {
            console.log('in addTLA');
            dispatch(DesignActions.addTLA({value: {id: uuidv4(), actions: []}}));
          }} /></Button>}</div>
        </CardHeader>
        <CardBody className="m-0 p-0">

          <DragDropContext onDragEnd={(result: any) => onDragEnd(result)}>
            <ScrollArea
              speed={0.8}
              className="area"
              contentClassName="content"
              contentStyle={{ width: currentDesign.tlas.length * 466 + 40, padding: 6, backgroundColor: "#f5f5f5" }}
              style={{ height: 630, backgroundColor: "#f5f5f5" }}
              horizontal={true}
            >
              <Droppable type="TLA" droppableId="1" direction="horizontal">
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    { currentDesign.tlas.map((tla: any, index: any) => (
                      <Draggable isDragDisabled={readOnly} type="TLA" key={tla.id} draggableId={tla.id} index={index}>
                        {(provided: any, snapshot: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}

                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <TLAComponent readOnly={readOnly} key={tla.id} tlaIndex={index} tla={tla} dragHandle={provided.dragHandleProps} />
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </ScrollArea>
          </DragDropContext>
        </CardBody>
      </Card>

    </div>
  )
}