import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';

export const createRemoteSession = async (lab: String,
                                        note: String, 
                                        startDate:any,
                                        title: String, 
                                        stopDate: any) => {

    const query = loader('../graphql/createRemoteSession.gql');
    console.log('createRemoteSession query: ', query)
    try {
      const result: any = await API.graphql({ query: query, 
        variables: {lab,note,startDate, title, stopDate} });
      return result
  
    } catch (error) {
        console.log("Error in createRemoteSession Query", error)
      throw error
    }
  }

export const updateRemoteSession = async (id: String, 
                                          lab: String,
                                          note: String, 
                                          startDate:any,
                                          title: String, 
                                          stopDate: any) => {

const query = loader('../graphql/updateRemoteSession.gql');
console.log('updateRemoteSession query: ', query)
try {
const result: any = await API.graphql({ query: query, 
variables: {id, lab,note,startDate, title, stopDate} });
return result

} catch (error) {
console.log("Error in updateRemoteSession Query", error)
throw error
}
}

export const deleteRemoteSession = async (id: String) => {

    const query = loader('../graphql/deleteRemoteSession.gql');
    console.log('deleteRemoteSession query: ', query)
    try {
    const result: any = await API.graphql({ query: query, 
    variables: {id} });
    return result

    } catch (error) {
    console.log("Error in deleteRemoteSession Query", error)
    throw error
    }
  }

export const listRemoteSessions = async (
  startDate:any,
  stopDate: any) => {

const query = loader('../graphql/listRemoteSessions.gql');
console.log('listRemoteSessions query: ', query)
try {
const result: any = await API.graphql({ query: query, 
variables: {startDate, stopDate} });
const appointments = result["data"]["listRemoteSessions"];
console.log("listRemoteSessions result:", appointments);
return appointments

} catch (error) {
console.log("Error in listRemoteSession Query", error)
throw error
}
}

export const listRemoteSessionsByTitle = async (
  title:any,
  ) => {

const query = loader('../graphql/listRemoteSessionsByTitle.gql');
console.log('listRemoteSessionsByTitle query: ', query)
try {
const result: any = await API.graphql({ query: query, 
variables: {title} });
const appointments = result["data"]["listRemoteSessionsByTitle"];
console.log("listRemoteSessionsByTitle result:", appointments);
return appointments

} catch (error) {
console.log("Error in listRemoteSessionByTitle Query", error)
throw error
}
}

export const getResourcesForSession = async (
  session:any,
  ) => {

const query = loader('../graphql/getResourcesForSession.gql');
console.log('getResourcesForSession query: ', query)
try {
const result: any = await API.graphql({ query: query, 
variables: {session} });
const resources = result["data"]["getResourcesForSession"];
console.log("getResourcesForSession result:", resources);
return resources

} catch (error) {
console.log("Error in getResourcesForSession Query", error)
throw error
}
}

export const bookRemoteSession = async (organization: String, id: String) => {

  const query = loader('../graphql/bookRemoteSession.gql');
  console.log('bookRemoteSession query: ', query)
  try {
  const result: any = await API.graphql({ query: query, 
  variables: {organization, id }});
  return result

  } catch (error) {
  console.log("Error in bookRemoteSession Query", error)
  throw error
  }
}

export const unbookRemoteSession = async (organization: String, id: String) => {

  const query = loader('../graphql/unbookRemoteSession.gql');
  console.log('unbookRemoteSession query: ', query)
  try {
  const result: any = await API.graphql({ query: query, 
  variables: {organization, id }});
  return result

  } catch (error) {
  console.log("Error in unbookRemoteSession Query", error)
  throw error
  }
}

export const getUser = async (id: String) => {

  const query = loader('../graphql/getUser.gql');
  console.log('getUser query: ', query)
  try {
  const result: any = await API.graphql({ query: query, 
  variables: {id}});
  return result

  } catch (error) {
  console.log("Error in getUser Query", error)
  throw error
  }
}
