import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const currentSlice = createSlice({
  name: 'current',
  initialState: {
    title: "Design di prova",
    description: "Descrizione del design di prova",
    tlas: [
      {
        title: "TLA di prova 1",
        tla: "id1",
        actions:[
          {description: "descrizione 1.1", action: "action1_1"},
          {description: "descrizione 1.2", action: "action1_2"},
        ]
      },
      {
        title: "TLA di prova 2",
        tla: "id2",
        actions:[
          {description: "descrizione 2.1", action: "action2_1"},
          {description: "descrizione 2.2", action: "action2_2"},
        ]

      }

    ]
  },
  reducers: {
    increment: (state, action: PayloadAction<number>) => state
  }
})

// Extract the action creators object and the reducer
export const { actions, reducer }: any = currentSlice
console.log('with actions: ', actions);
// Extract and export each action creator by name
export const { increment } = actions
// Export the reducer, either as a default or named export

export const selectors = {
  getCurrentDesign: (state: any) => state.current,
}
