import { Formik, Form, Field, ErrorMessage, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import { CardHeader, CardBody, Button, Input, Label, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, NavItem, TabContent, TabPane } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as currentDesignSelectors } from '../store/slices/current'
import { selectors as DesignSelectors, actions as DesignActions } from '../store/slices/design'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import React from 'react';
import * as _ from 'lodash';

export const DetailForm = ({readOnly}: any) => {

  const dispatch = useDispatch();
  const currentDesign = useSelector(DesignSelectors.getCurrentDesign);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())

  const updateRaw = _.debounce((editorState: any) => {
    const rawDescription = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    dispatch(DesignActions.setDesignMetadata({ name: 'description', value: rawDescription }))
  }, 1000, { maxWait: 4000 });

  const onEditorStateChange = (editorState: any) => {

    updateRaw(editorState);
    setEditorState(editorState);
  }
  console.log('readonly: ', readOnly);
  const readOnlyGroup = readOnly ?  {disabled: true} : {disabled: false}
  return (
    <>
      <Formik
        initialValues={{
          title: currentDesign.title,
          description: currentDesign.description
        }}
        // validationSchema={ }
        validateOnBlur={true}
        onSubmit={values => { }}
      >
        {({ errors, touched, setFieldValue, values }) => (
          <Form name="detail" method="post">
            <FormGroup {...readOnlyGroup} tag="fieldset">
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Titolo del Design</Label>
                    <Input name="title" type="text" value={currentDesign.title} onChange={(e: any) => dispatch(DesignActions.setDesignMetadata({ name: 'title', value: e.target.value }))} placeholder={t('Titolo del Design')} tag={Field} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Tematica</Label>
                    <Input name="tematica" type="text" placeholder={t('Tematica')} tag={Field} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Dimensione Della Classe</Label>
                    <InputGroup>
                      <Input name="dimesions" type="number" placeholder={t('Dimensione della classe')} tag={Field} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-users"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Tempo Stimato</Label>
                    <InputGroup>
                      <Input name="estimatedTime" type="number" placeholder={t('Tempo Stimato')} tag={Field} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-clock-o"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Tempo Effettivo</Label>
                    <InputGroup>
                      <Input name="effectiveTime" type="number" placeholder={t('Tempo Effettivo')} tag={Field} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-clock-o"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Descrizione</Label>
                    <Editor
                      wrapperStyle={{ border: "1px solid #ced4da", borderRadius: '0.25rem' }}
                      toolbarStyle={{ display: readOnly ? 'none' : '', borderTop: 0, borderLeft: 0, borderRight: 0 }}
                      editorStyle={{ padding: "0.375rem 0.75rem", height: 200, backgroundColor: readOnly ? 'rgba(249, 249, 249, 255)' : "#fff" }}
                      editorState={editorState}
                      readOnly={readOnly}
                      onEditorStateChange={(editorState: any) => onEditorStateChange(editorState)}
                    />
                    {/* <Input name="description" type="textarea" placeholder={t('Descrizione')} tag={Field} /> */}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Obiettivi Generali</Label>
                    <Input name="aims" type="textarea" placeholder={t('Obiettivi Generali')} tag={Field} />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <Card fluid>
                    <CardHeader>
                      <div style={{ float: "left" }}>Risultati attesi - Outcomes</div>
                      <div style={{ float: "right" }}>{ !readOnly && <Button size="md" onClick={() => { }} color="primary"><FontAwesomeIcon icon={faPlus} onClick={()=>{}} /></Button>}</div>
                    </CardHeader>
                    <CardBody>
                      <Row><Col md={2}>Conoscenza</Col><Col>Descrizione</Col></Row>
                      <Row>
                        <Col md={2}><strong>Conoscenza</strong></Col>
                        <Col><strong>Descrizione</strong></Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </>
  )
}