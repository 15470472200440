import { call, put, takeLatest, select } from 'redux-saga/effects'
import { actions as UsersActions, selectors as UsersSelector } from '../slices/users'
import { getUser} from '../../api/sessions'

export function* sagas() {
    yield takeLatest(UsersActions.willGetUser.type, willGetUser);
}


function* willGetUser(action: any) {
    const userId = action.payload;
    console.log("SAGA: willGetUser on:", userId);
    
    try{

        if (userId==null || userId=="")
        {   
            console.log("SAGA: willGetUser imposto a null setCurrentUserData");
            yield put(UsersActions.setCurrentUserData(null)); 
            return;
        }
        // Verifico che le informazioni dell'utente non siano già
        // disponibili nello store. In tal caso mi limito ad aggiornare
        // il currentUserData
        const userDataFromStore = yield select(UsersSelector.getUser(userId));
        if (userDataFromStore!=null) {
            console.log("SAGA graphql: willGetUser Utente trovato:", userDataFromStore);
            yield put(UsersActions.setCurrentUserData(userDataFromStore)); 
            return;
        }
      
        const result = yield call(getUser,
                                    userId
                                   );
            const userData = result["data"]["getUser"];
            console.log("SAGA graphql: willGetUser:", userData );
            
            yield put(UsersActions.didGetUser(userData)); 
            yield put(UsersActions.setCurrentUserData(userData));
           
     } catch (error) {
       
        console.log("SAGA: willGetUser fails error ", error)
        throw error
      }
}