import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
      users : {},
      currentUserData : null
    }
  ,
    reducers: {
        willGetUser: (state, action: PayloadAction<any>) => state,

        setCurrentUserData: (state, action: PayloadAction<any>) => { 
          state.currentUserData = action.payload;
        },

        didGetUser: (state, action: PayloadAction<any>) =>
         { (state.users as any)[action.payload.id] = action.payload;}
    }
});


export const { actions, reducer }: any = usersSlice

export const selectors = {
  getUser: (id:any) => (state: any) => {
    const userdata = state.users.users[id];
    return userdata;
  },
  getCurrentUserData : (state:any) => {
     return state.users.currentUserData;
  }
}