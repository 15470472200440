import * as React from 'react'
import { Formik, Form, Field, ErrorMessage, setNestedObjectValues } from 'formik';
import * as Yup from 'yup';
import { ButtonToolbar, ButtonGroup, Button, CardBody, CardHeader, CardTitle, FormGroup, Table, Modal, ModalHeader, ModalBody, ModalFooter, Navbar, Nav, NavLink, CardSubtitle, NavbarBrand, Container, Col, Row, InputGroup, InputGroupAddon, InputGroupText, CardImg, Card, CardFooter, NavItem, TabContent, TabPane } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as ContainerActions from '../store/actions/container';
import * as UiActions from '../store/actions/ui';

import { Header } from '../header'
import { SideBar } from '../components/SideBar'
import { Content } from '../components/Content';
import InfiniteScroll from "react-infinite-scroll-component";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
 
//import { Header } from "../components/Header"
//import { Footer } from "../components/Footer"

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as ContainerSelectors from '../store/selectors/container';
//import * as ProfileSelectors from '../store/selectors/profile'; // old
import { selectors as ProfileSelectors, belongsToOrganization } from '../store/slices/profile'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from "react-tooltip";
import { FaTrashAlt, FaClone, FaEdit } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import {
  StyledLabel as Label,
  StyledInput as Input,
  StyledInlineErrorMessage,
  StyledButton,
} from "../styles/styles";
import moment from 'moment';

var _ = require('lodash');

export const TextAreaField: React.FC = (props) => {
  return (
    <Field component="textarea" rows={3} {...props} />
  )
}
const ExperimentsSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, 'Titolo troppo corto')
    .max(50, 'Titolo troppo lungo')
    .required('Titolo obbligatorio'),
  description: Yup.string()
    .required('Descrizione obbligatoria'),
  start_date: Yup.date()
   // .default(new Date())
    .required('Data iniziale obbligatoria'),
  end_date: Yup.date()
    .when(
      "start_date",
      (start_date: any, schema: { min: (arg0: any, arg1: string) => any; }) =>
       start_date && schema.min(start_date, 'La data finale deve essere successiva a quella iniziale'))
    .required('Data finale obbligatoria'),
});
export const TimelineHome: React.FC = () => {
  const experiments = useSelector(ExperimentsSelector.getExperiments)
 
  const {t} = useTranslation('frontend', { useSuspense: false });

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(UiActions.saveButtonAction("NO CHANGES"))
    dispatch(ExperimentsActions.willLoadExperiments());
  }, [])
  //const userID = useSelector(ProfileSelectors.getProfileData).profile.sub
  const userProfile = useSelector(ProfileSelectors.getProfile)
  const isEditor =  useSelector(ProfileSelectors.isEditor)
  const userID = userProfile.sub
  
  console.log("Timeline Container userID:", userID);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalParams, setModalParams] = React.useState<any>({})
  const toggleEdit = (value: any) => {
    setModalEdit(!modalEdit);
    setModalParams(value);
    console.log('in modal edit', value);
  };
  const [modalDelete, setModalDelete] = React.useState(false);
  const [modalDeleteExperiment, setModalDeleteExperiment] = React.useState<any>({})
  
  const toggleModalDelete = (value: any) => {
    setModalDelete(!modalDelete);
    setModalDeleteExperiment(value);
  }


  const [modalClone, setModalClone] = React.useState(false);
  const [modalCloneExperiment, setModalCloneExperiment] = React.useState<any>({})
  
  const toggleModalClone = (value: any) => {
    setModalClone(!modalClone);
    setModalCloneExperiment(value);
  }

const paginationOptions = { 
  sizePerPageList: [ {
    text: '5th', value: 5
  }, {
    text: '8th', value: 8
  }
],pageStartIndex: 1}

const actionsFormatter = (cell:any,value:any) =>{
  console.log("Passo value:", value);
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  }}>
    
  <ButtonToolbar>
    <ButtonGroup>
    <FaEdit data-for="editExperiment"  data-tip="Modifica" cursor="pointer"
    style={{margin:"10px"}} color="#007bff" onClick={() => { toggleEdit(value.experiment) }}>{t('modifica')}</FaEdit>
    
    <FaClone  data-for="cloneExperiment"  data-tip="Clona"  cursor="pointer"
    style={{margin:"10px"}} color="#007bff"  
    onClick={() => {toggleModalClone(value); }}>{t('clona')}</FaClone>
   
    <FaTrashAlt  data-for="deleteExperiment"  data-tip="Elimina"  cursor="pointer"
    style={{margin:"10px"}} color='red' 
    onClick={() => { toggleModalDelete(value.experiment.experiment) }} />
      
    
    </ButtonGroup>
  
    </ButtonToolbar>
    <ReactTooltip id="editExperiment"/>
    <ReactTooltip id="cloneExperiment"/>
    <ReactTooltip id="deleteExperiment"/>
    </div>
    
  )
}



 const linkFormatter  = (cell:any,row:any) =>{
   console.log("Cliccato su esperimento con id:", row.id);
  return (
  <>
  <a data-for="linkExperiment" data-tip={row.experiment.description}  href={`/timeline/${row.id}`} target={"_blank"}>{cell} </a>
  <ReactTooltip id="linkExperiment"/>
  </>)
}

const lastChangeFormatter = (cell:any,row:any) =>
{
  return moment(cell).format('MMMM Do YYYY, h:mm:ss a');
}

const startEndFormatter = (cell:any,row:any) =>
{
  return moment(cell).format("LL")
}

  const experimentColumns = [
    {
      dataField: 'experiment',
      text: '',
      hidden: true
    },

    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
    dataField: 'title',
    text: t('titolo'),
    formatter : linkFormatter,
    sort: true
  }, 

  {
    dataField: 'startDate',
    text: t('inizio'),
    formatter: startEndFormatter,
    headerStyle: () => {
      return { width: '15%'};
    },
    sort: true
  },

  {
    dataField: 'endDate',
    text: t('fine'),
    formatter: startEndFormatter,
    headerStyle: () => {
      return { width: '15%'};
    },
    sort: true
  },
  
  {
    dataField: 'lastChange',
    text: 'Ultima modifica',
    formatter: lastChangeFormatter,
    sort: true
  },
  {
    dataField: 'actions',
    text: 'azioni',
    formatter: actionsFormatter,
    headerStyle: () => {
      return { width: '10%'};
    },
    headerAlign: 'center'
  },
];

  const orderedExperiments = _.orderBy(experiments, [(experiment: any) => experiment.title.toUpperCase()], ["asc"]);
//belongsToOrganization(userProfile, value.owner) && 
  const experimentRows = orderedExperiments.filter((value:any,i:number) =>{
    return value.status!=="DELETED"
  }).map((value:any,index:number) =>{

    const lastChange = value.updateDate !=null ? value.updateDate : value.creationDate;
    return { "experiment" : value, "id" : value.experiment, "title" : value.title, 
            "startDate" :value.experimentStartTime,
            "endDate" :value.experimentStopTime,
    "lastChange" : lastChange }
  });

  const [modal, setModal] = React.useState(false);

  const toggle = () => setModal(!modal);


  return (
    // <BlockUi tag="div" loader={<div><h1>Loading...</h1></div>} blocking={(uiStatus === true)}>
    <>
      <Header className="mb-0 text-white" section="Timeline" showMenu={false} />
      <SideBar active="timeline" />
      <Content className="pt-3">

        <Row className="m-0" style={{ height: 'calc(100% - 22px)' }}>
          <Col xs="12" style={{ height: '100%' }}>
            <Card className="mb-4" style={{ height: '100%', borderColor: "#007bff" }}>
              <CardHeader style={{ padding: 0, backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white', display: 'flex' }}>
                <CardTitle tag="h5" className="text-center" style={{ flexGrow: 1, marginTop: 'auto', marginBottom: 'auto' }}>Esperimenti</CardTitle>
                
                <ButtonToolbar>
                  <ButtonGroup >
                  {isEditor &&
                    <Button onClick={toggle} style={{ height: 34, marginRight: 12, marginTop: 6, marginBottom: 6, borderWidth: 1, borderColor: 'white', borderStyle: 'solid', borderRadius: 4 }} color="primary"><FontAwesomeIcon icon={faPlus} /> AGGIUNGI</Button>
                  }
                    </ButtonGroup>
                </ButtonToolbar>
            
              </CardHeader>
              <CardBody>

                <div className="App">
                  {/*<Header /> */}
                  {/* <div className="App-header"> */}
                  <Container fluid>
                   
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm={12}>
                          <BootstrapTable bootstrap4 keyField='id' data={ experimentRows } 
                            columns={ experimentColumns } pagination={ paginationFactory(paginationOptions)}/>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                  <Modal isOpen={modal} toggle={toggle} >
                    <ModalHeader toggle={toggle}>{t('aggiungi_esperimenti')}</ModalHeader>
                    <ModalBody>
                      <Formik
                        initialValues={{
                          title: '',
                          description: '',
                          start_date: moment(new Date()).format("YYYY-MM-DD"),
                          end_date:  moment(new Date()).format("YYYY-MM-DD")
                        }}
                        validationSchema={ExperimentsSchema}
                        validateOnBlur={true}
                        onSubmit={values => {
                          console.log("willAddExperiment values: ", values);
                          dispatch(ExperimentsActions.willAddExperiment({
                           title : values.title,
                           description : values.description,
                           experimentStartTime : values.start_date,
                           experimentStopTime : values.end_date,
                           sections : [{"title": values.title, "description" : "", "items":[]}]
                          }
                            
                          ));
                          setModal(!modal)
                        }}
                      >
                        {({ errors, touched, setFieldValue, values }) => (
                          <Container>
                            <Form name="create_quote" method="post">
                              <FormGroup row>
                                <Label sm={3}>{t('titolo')}</Label>
                                <Col sm={9}>
                                  <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                                  {errors.title && touched.title ?
                                    <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={3}>{t('descrizione')}</Label>
                                <Col sm={9}>
                                  <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                  {errors.description && touched.description ?
                                    <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={3}>{t('inizio')}</Label>
                                <Col sm={9}>
                                  <Input name="start_date" type="date" placeholder={t('inizio')} tag={Field} />
                                </Col>
                                {errors.start_date && touched.start_date ?
                                  <StyledInlineErrorMessage>{errors.start_date}</StyledInlineErrorMessage> : null}
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={3}>{t('fine')}</Label>
                                <Col sm={9}>
                                  <Input name="end_date" type="date" placeholder={t('fine')} tag={Field} />
                                  {errors.end_date && touched.end_date ?
                                  <StyledInlineErrorMessage>{errors.end_date}</StyledInlineErrorMessage> : null}
                                </Col>
                                
                              </FormGroup>
                              <FormGroup row>
                                <Col sm={9}>
                                  <StyledButton block color="primary" type="submit" >{t('conferma_aggiungi_esperimenti')}</StyledButton>{' '}
                                </Col>
                                <Col sm={3}>
                                  <Button color="secondary" onClick={toggle}>{t('cancel')}</Button>
                                </Col>
                              </FormGroup>
                            </Form>
                          </Container>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modalEdit} toggle={toggleEdit} >
                    <ModalHeader toggle={toggleEdit}>{t('modifica_gruppo_esperimenti')}</ModalHeader>
                    <ModalBody>
                      <Formik
                        initialValues={{
                          title: modalParams.title,
                          description: modalParams.description,
                          start_date: modalParams.experimentStartTime,
                          end_date: modalParams.experimentStopTime
                        }}
                        validationSchema={ExperimentsSchema}
                        validateOnBlur={true}
                        onSubmit={values => {
                          console.log("experiment to update values: ", values);
                          console.log("experiment to update values (MP): ", modalParams);
                          dispatch(ExperimentsActions.willUpdateExperiment(
                            {
                              owner : modalParams.owner,
                              experiment: modalParams.experiment,
                              title: values.title,
                              description: values.description,
                              experimentStartTime: values.start_date,
                              experimentStopTime: values.end_date
                            }
                            
                          ));
                          setModalEdit(!modalEdit)
                        }}
                      >
                        {({ errors, touched, setFieldValue, values }) => (
                          <Container>
                            <Form name="create_quote" method="post">
                              <FormGroup row>
                                <Label sm={3}>{t('titolo')}</Label>
                                <Col sm={9}>
                                  <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                                  {errors.title && touched.title ?
                                    <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={3}>{t('descrizione')}</Label>
                                <Col sm={9}>
                                  <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                  {errors.description && touched.description ?
                                    <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={3}>{t('inizio')}</Label>
                                <Col sm={9}>
                                  <Input name="start_date" type="date" placeholder={t('inizio')} tag={Field} />
                                  {errors.start_date && touched.start_date ?
                                    <StyledInlineErrorMessage>{errors.start_date}</StyledInlineErrorMessage> : null}
                                </Col>
                                {errors.start_date && touched.start_date ?
                                  <StyledInlineErrorMessage>{errors.start_date}</StyledInlineErrorMessage> : null}
                              </FormGroup>
                              <FormGroup row>
                                <Label sm={3}>{t('fine')}</Label>
                                <Col sm={9}>
                                  <Input name="end_date" type="date" placeholder={t('fine')} tag={Field} />
                                  {errors.end_date && touched.end_date ?
                                  <StyledInlineErrorMessage>{errors.end_date}</StyledInlineErrorMessage> : null}
                                </Col>
                               
                              </FormGroup>
                              <FormGroup row>
                                <Col sm={9}>
                                  <StyledButton block color="primary" type="submit" >{t('confirm_edit_container')}</StyledButton>{' '}
                                </Col>
                                <Col sm={3}>
                                  <Button color="secondary" onClick={toggleEdit}>{t('cancel')}</Button>
                                </Col>
                              </FormGroup>
                            </Form>
                          </Container>
                        )}
                      </Formik>
                    </ModalBody>
                  </Modal>

                  <Modal isOpen={modalDelete} toggle={toggleModalDelete} >
                    <ModalHeader toggle={toggleModalDelete}>{t('elimina_gruppo_esperimenti')}</ModalHeader>
                    <ModalBody>{t('confermare_elimina_gruppo_esperimenti')}</ModalBody>
                    <ModalFooter>
                      <Button color="danger" style={{ float: "right" }} 
                      onClick={() => { 
                        dispatch(ExperimentsActions.willDeleteExperiment(
                          { experiment: modalDeleteExperiment})); 
                          setModalDelete(!modalDelete); }}>{t('elimina')}</Button>{' '}
                      <Button color="info" onClick={toggleModalDelete} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={modalClone} toggle={toggleModalClone} >
                    <ModalHeader toggle={toggleModalClone}>{t('clona_esperimento')}</ModalHeader>
                    <ModalBody>{t('confermare_clona_esperimento')}</ModalBody>
                    <ModalFooter>
                      <Button color="danger" style={{ float: "right" }} 
                      onClick={() => { 
                          console.log("PASSATO OGGETTO PER CLONE:::",modalCloneExperiment)
                          dispatch(ExperimentsActions.willCloneExperiment(
                            {experiment:modalCloneExperiment.experiment.experiment, owner: modalCloneExperiment.experiment.owner,
                            title : `Copia di ${modalCloneExperiment.experiment.title}`, description: `${modalCloneExperiment.experiment.description}`}))

                          setModalClone(!modalClone); }}>{t('clona')}</Button>{' '}
                      <Button color="info" onClick={toggleModalClone} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                    </ModalFooter>
                  </Modal>



                </div>
                {/*<Footer />*/}
                {/* </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Content>
    </>
    // </BlockUi>

  );
}
