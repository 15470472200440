import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ButtonToolbar, ButtonGroup, Button, Navbar, NavItem, Nav, Container, Breadcrumb, BreadcrumbItem, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { DetailForm } from '../components/DetailForm'
import { TLAList } from '../components/TLAList'
import { selectors as DesignSelectors, actions as DesignActions } from '../store/slices/design'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faBackspace, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { read } from 'fs'

require('./detail.css')

export const DetailPage = () => {

  let { design, action }: any = useParams();

  const currentDesign = useSelector(DesignSelectors.getCurrentDesign);
  const edited = useSelector(DesignSelectors.isDesignEdited);
  const dispatch = useDispatch();
  const history = useHistory();
  const [readOnly, setReadOnly] = useState(action == "edit" ? false : true)
  const [resetModalShow, setResetModalShow] = useState(false);
  console.log('with action: ', action);
  React.useEffect(() => {

    dispatch(DesignActions.willGetDesign(design))
    return () => { }
  }, [])

  React.useEffect(() => {

    if (action == 'edit') {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [action])

  console.log('readonly parent: ', readOnly);

  return (
    <Container fluid>
      <div>
        <Navbar color="breadcrumb" light expand="md">
          <Nav className="mr-auto" navbar>
            <Breadcrumb listClassName="mb-0">
              <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
              {action === "edit" ? (
                <>
                  <BreadcrumbItem>
                    <a href={`/design/${design}`} onClick={(e) => {
                      e.preventDefault();
                      if(edited){
                        setResetModalShow(true)
                      }else{
                        dispatch(DesignActions.resetEdit());
                        history.push(`/design/${design}`);
                      }
                    }}>{currentDesign && currentDesign.id}</a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>edit</BreadcrumbItem>
                </>
              ) : (
                  <BreadcrumbItem>{currentDesign && currentDesign.id}</BreadcrumbItem>
                )}
            </Breadcrumb>
          </Nav>
          <Nav>
            {readOnly ? (
              <ButtonToolbar>
                <ButtonGroup className="mr-1">
                  <Button color="primary" onClick={(e) => {
                    e.preventDefault();
                    dispatch(DesignActions.setEdit());
                    history.push(`/design/${design}/edit`);
                  }}><FontAwesomeIcon icon={faEdit} /></Button>
                  {/* onClick={() => setReadOnly(false)} */}
                </ButtonGroup>
              </ButtonToolbar>

            ) : (
                <ButtonToolbar>
                  <ButtonGroup className="mr-1">
                    <Button disabled color="primary">PUBLISH</Button>
                  </ButtonGroup>
                  <ButtonGroup className="mr-1">
                    <Button disabled={!edited} color="primary">SAVE</Button>
                  </ButtonGroup>
                </ButtonToolbar>
              )}
          </Nav>
        </Navbar>

      </div>
      <Card className="mb-2">
        <CardBody>
          <DetailForm readOnly={readOnly} />
          <TLAList readOnly={readOnly} />
        </CardBody>
      </Card>
      <Modal isOpen={resetModalShow} >
        <ModalHeader>Close and reset data?</ModalHeader>
        <ModalBody>Al unsaved data will lost</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => {
            dispatch(DesignActions.resetEdit());
            history.push(`/design/${design}`);
            setResetModalShow(false)
          }}>Reset</Button>
          <Button color="primary" onClick={() => setResetModalShow(false)}>Keep</Button>
        </ModalFooter>
      </Modal>
    </Container>
  )
}