export const convertActionColor = (prefix: any) => {
  switch(prefix){
      case "pro":
          return "#BDEA75";
          break;
      case "pra":
          return "#BB98DC";
          break;
      case "inv":
          return "#F8807F";
          break;
      case "dis":
          return "#7AAEEA";
          break;
      case "col":
          return "#FFCD00";
          break;
      case "rwl":
      default:
          return "#67DBF2";
          break;
  }
}

export const convertActionType = (prefix: any) => {
  switch(prefix){
      case "pro":
          return {value: "pro", label: "Produci"}
          break;
      case "pra":
          return {value: "pra", label: "Pratica"}
          break;
      case "inv":
          return {value: "inv", label: "Investiga"}
          break;
      case "dis":
          return {value: "dis", label: "Discuti"}
          break;
      case "col":
          return {value: "col", label: "Collabora"}
          break;
      case "rwl":
      default:
          return {value: "rwl", label: "Leggi/Guarda/Ascolta"}
          break;
  }
}