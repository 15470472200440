import { NavItem, NavLink, Nav } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FcTimeline } from "react-icons/fc";
import {
  faHome,
  faBriefcase,
  faTimes,
  faQuestion,
  faImage,
  faBook,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const SideBar = ({active}: any) => {

  console.log('active: ', active)
  return (
    <div style={{
      border: '0px solid #000',
      width: '240px',
      position: 'absolute',
      backgroundColor: '#f1f1f1',
      bottom: 0,
      top: 56
    }}>
      <Nav vertical className="list-unstyled pb-3">
        <NavItem active={active === "dashboard" ? true : false} className="mb-1">
          <NavLink className={active === "dashboard" ? "text-primary" : "text-secondary"} style={{ textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/dashboard"}>
            <FontAwesomeIcon icon={faBriefcase} className="mr-2" /> Dashboard
          </NavLink>
        </NavItem>
        <NavItem active={active === "catalog" ? true : false} className="mb-1">
          <NavLink className={active === "catalog" ? "text-primary" : "text-secondary"} style={{ textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/catalog"}>
            <FontAwesomeIcon icon={faBook} className="mr-2" /> Catalogo
          </NavLink>
        </NavItem>
        <NavItem active={active === "calendar" ? true : false} className="mb-1">
          <NavLink className={active === "calendar" ? "text-primary" : "text-secondary"} style={{ color: 'white', textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/calendar"}>
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" /> Calendario
          </NavLink>
        </NavItem>
        <NavItem active={active === "timeline" ? true : false} className="mb-1">
          <NavLink className={active === "timeline" ? "text-primary" : "text-secondary"} style={{ color: 'white', textDecoration: 'none', fontWeight: 'bolder' }} tag={Link} to={"/timeline"}>
            <FcTimeline className="mr-2" /> Timeline
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}