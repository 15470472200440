import React from 'react';
import {
  Container, Row, Col, Card, CardText, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge, Input
} from 'reactstrap';

import { Header } from '../header'
import { SideBar } from '../components/SideBar'
import { Content } from '../components/Content';
import { Catalog } from '../components/Catalog';
import AppointmentsViewer from '../components/appointmentsViewer';
import AppointmentEditor from '../components/appointmentsEditor';

export const CalendarPage = () => {
  return (
    <>
      <Header className="mb-0 text-white" section="Calendario" showMenu={false} />
      <SideBar active="calendar" />
      <Content className="pt-3">
        <Row className="m-0">
          <Col xs="12">
            <Card className="mb-4" style={{ borderColor: "#007bff" }}>
              <CardHeader style={{ backgroundColor: "#007bff", borderColor: "#007bff", paddingBottom: 0, color: 'white' }}>
                <CardTitle tag="h5" className="text-center">Calendario</CardTitle>
              </CardHeader>
              <CardBody>
                <AppointmentsViewer />
                <AppointmentEditor /> 
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  )
}